<template>
  <el-dialog
      :title="chioceName+'标识注册详情'"
      :visible.sync="DialogVisible"
      :before-close="() => {this.$parent.DialogVisible = false}"
      :destroy-on-close="true"
      width="50%"
      top="10%"
      center>
      <div class="dialogMain">
        <el-table
          ref="multipleTable"
          v-if="detailsData!=[]"
          :data="detailsData"
          tooltip-effect="dark"
          height="100%"
          style="width: 100%">
          <el-table-column
            prop="products_type_name"
            align="center"
            label="产品名称">
          </el-table-column>
          <el-table-column
            prop="products_type_id"
            align="center"
            label="产品ID">
          </el-table-column>
          <el-table-column
            prop="batch"
            align="center"
            label="批次">
          </el-table-column>
          <el-table-column
            prop="products_num"
            align="center"
            label="标识数量">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
</template>

<script>
export default {
  props:['DialogVisible','chioceName','detailsData'],
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.el-dialog__wrapper >>>.el-dialog__title {
  font-weight: bold;
  font-size: 2.5rem;
}
.dialogMain {
  height: 55vh;
}
</style>