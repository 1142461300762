<template>
    <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item  v-for="(item,index) in wb_mianBaoXieArray" :key="index" >{{item}}</el-breadcrumb-item>
    </el-breadcrumb>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
    computed:{
        ...mapGetters(["wb_mianBaoXieArray"])
    }
}
</script>

<style scoped>
.el-breadcrumb >>> .el-breadcrumb__inner{
  font-size: 1.5rem;
  cursor: pointer;
}

</style>