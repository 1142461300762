<template>
  <!-- 政府监管 -->
  <div class="Regulated">
    <div class="header">
      <div class="headerLeft">
        <el-input class="complaintSearch" prefix-icon="el-icon-search" placeholder="请输入产品名" v-model="searchVal" @keyup.native.enter="toSearch"></el-input>
      </div>
      <div class="headerCenter">
        <span>状态：</span>
        <el-select v-model="selectStatus" @change="changeStatus">
          <el-option
            v-for="item in selectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- 表格信息 -->
    <div class="bodyer">
      <regulated-table ref="regulatedTable">
        <div slot="products_type_id">
          <el-table-column
            prop="products_type_id"
            label="产品ID">
          </el-table-column>
        </div>
        <div slot="products_type_name">
          <el-table-column
            prop="products_type_name"
            label="产品名">
          </el-table-column>
        </div>
        <div slot="batch">
          <el-table-column
            prop="batch"
            label="批次">
          </el-table-column>
        </div>
        <div slot="products_num">
          <el-table-column
            prop="products_num"
            label="标识数量">
          </el-table-column>
        </div>
      </regulated-table>
    </div>
    <div class="footer">
        <el-button v-if="selectStatus === 2" @click="toGenerate">生成防伪码</el-button>
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="number"
          :total="total"
          :current-page="page"
          class="pageS"
          @current-change="pageChioce">
        </el-pagination>
    </div>
  </div>
</template>
<script>
import RegulatedTable from './modules/pc-regulated-table'

export default {
  components: {
    RegulatedTable
  },
  data() {
    return {
      searchVal: '',
      selectOptions: [{
        value: 0,
        label: '未审核'
      }, {
        value: 1,
        label: '待审核'
      }, {
        value: 2,
        label: '审核通过'
      }, {
        value: 3,
        label: '审核不通过'
      }, {
        value: 4,
        label: '产品冻结'
      }],
      selectStatus: 2,
      page: 1,
      number: 10,
      total: 0,
      pages: 1,
    }
  },
  methods: {

    /**
     * 换页
     */
    pageChioce(page) {
      this.$refs.regulatedTable.queryData(page,this.selectStatus,this.searchVal)
    },

    /**
     * 根据标题筛选
     */
    toSearch () {
      this.$refs.regulatedTable.queryData(1,this.selectStatus,this.searchVal)
    },

    /**
     * 状态筛选
     */
    changeStatus() {
      this.$refs.regulatedTable.queryData(1,this.selectStatus,this.searchVal)
    },

    /**
     * 生成防伪码
     */
    toGenerate () {
      this.$refs.regulatedTable.toGenerate()
    }
  }
}
</script>


<style scoped>
.Regulated {
  width: 100%;
  height: 100%;
  position: relative;
}
.bodyer {
  height: calc(100% - 18rem);
}
.header {
  height: 8rem;
  width: 100%;
  border-bottom: 2px solid #555;
}
.header .headerLeft {
  width: 30%;
  height: 100%;
  line-height: 8rem;
  float: left;
  font-size: 2.5rem;
  padding-left: 2.5rem;
}
.header .headerLeft .complaintSearch {
  width:70%;
}
.header .headerCenter {
  width: 50%;
  height: 100%;
  line-height: 8rem;
  float: left;
  font-size: 2rem;
  padding-left: 2.5rem;
}
.byTemplate {
  display:table-cell;
  vertical-align:middle;
  text-align: right;
  width: 10rem;
}
.byTemplate span, .byIdentification span {
  font-size: 2rem;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}
.byTemplate span {
  padding-left: 25px;
  background-color: white;
}
.footer {
  position: absolute;
  bottom: 0;
  height: 10rem;
  width: 100%;
  text-align: center;
}
.footer .pageS {
  /*设置分页的完全居中布局*/
  position: absolute;
  left: 50%;
  top:50%;
  transform:translate(-50%,-50%);
}
.footer button {
  position: absolute;
  bottom: 3rem;
  border: 1px solid #009966;
  color: #009966;
  margin: 0;
}
.footer button:hover {
  background-color: #e6e9ee;
}
.footer button:nth-child(1) {
  padding-left: 50px;
  padding-right: 50px;
  right: 2rem;
}
.el-dialog__wrapper >>> .el-dialog__header {
  padding: 0 !important;
}
</style>