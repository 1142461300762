<template>
  <!-- 图片库 -->
  <div class="RecycleBin">
    <div class="RecycleBinTop">
      <recycle-bin-top></recycle-bin-top>
    </div>
    
    <div class="RecycleBinBottom">
      <recycle-bin-bottom></recycle-bin-bottom>
    </div>
  </div>
</template>
<script>
import RecycleBinTop from './modules/pc-recyclebin-top'
import RecycleBinBottom from './modules/pc-recyclebin-bottom'

export default {
  components: {
    RecycleBinTop,
    RecycleBinBottom
  },
  data() {
    return {
    }
  },
  methods: {
   
  }
}
</script>


<style scoped>
.RecycleBin {
  width: 100%;
  height: 100%;
  position: relative;
}
.RecycleBinBottom {
  width: 15vw;
  height: 100%;
  float: left;
  border-right: 2px solid #555;
}
.RecycleBinTop {
  width: calc(100% - 15vw - 2px);
  height: 100%;
  float: left;
}
</style>
