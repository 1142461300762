<template>
  <!--审核未通过--信息视图 -->
  <div id="MessageFailBu">
    <wb-header></wb-header>

    <!-- 提示语标题 -->
    <ttitle><span>您的节点审核中，暂时无法使用其他功能。</span></ttitle>

    <!-- 功能按钮 -->
    <el-button class="buttonBack" @click="toBack">返回</el-button>
    <!-- <el-button class="buttonBusiness" @click="toBusiness">跳转商家</el-button> -->
  </div>
</template>

<script>
import Header from '@/components/Wb-Pc-Header/wb-pc-header-title'
import Title from './modules/pc-title'

export default {
  name: 'MessageFailBu',
  components: {
    wbHeader: Header,
    ttitle: Title
  },
  methods:{
    /**
     * 返回按钮
     */
    toBack () {
      this.$router.go(-1)
    },

    /**
     * 跳转商家
     */
    toBusiness () {
      this.$router.push({path: '/pc/bu/suc'})
    }
  }
}
</script>

<style scoped>
#MessageFailBu {
  width: 100%;
  margin: 0 auto;
}
.buttonBack {
  position: absolute;
  right: 15rem;
  top: 12rem;
}
.buttonBusiness {
  position: absolute;
  right: 12rem;
  bottom: 12rem;
}
</style>