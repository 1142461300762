<template>
  <transition>
    <router-view/>
  </transition>
</template>
<script>
export default {
  name: 'App',
  created () {
    let token = sessionStorage.getItem("token");
    if(token != null){
      this.$http.defaults.headers.common['Authorization'] = token;
    }
  }
}
</script>
