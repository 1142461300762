import Scanning from './Scanning'







export default{
    modules:{
        Scanning
    }
}