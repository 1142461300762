<template>
  <!-- 通知管理 -->
  <div id="Notification">
    <!-- 内部导航栏 -->
    <div class="le-menu">
      <left-menu></left-menu>
    </div>
    
    <!-- 子内容 -->
      <div class="admin-children">
        <transition>
          <router-view/>
        </transition>
      </div>
  </div>
</template>

<script>
import LeftMenu from './modules/pc-notification-menu'

export default {
  name: 'Notification',
  components: {
    LeftMenu
  },
  methods:{
  }
}
</script>

<style scoped>
#Notification {
  width: 100%;
  margin: 0 auto;
}
.le-menu{
  height: 75vh;
  width: 120px;
  float: left;
}
.admin-children {
  float: left;
  height: calc(75vh - 8rem);
  width:  calc(100% - 12rem - 120px );
  margin: 4rem 6rem 0rem 6rem ;
  border: 1px solid #555;;
}
</style>