import axios from 'axios'
export function request(config){
    return new Promise((resolve,reject)=>{
        const instance =axios.create({  //创建axios的实例instance
            baseURL:""
        })
        // 发送网络请求
        resolve(instance(config)) 
        reject("error")
        return instance(config)
    })
}