<template>
  <div class="pc-register-input-box">
    <!-- 用户输入框 -->
    <el-input class="wh-input input-username" prefix-icon="el-icon-s-custom"
    placeholder="请输入用戶名"
    v-model="formdata.nickname"
    clearable
    maxlength="12">
    </el-input>

<!-- 密码输入框 -->

  <el-input class="wh-input notusername-input-all" prefix-icon="el-icon-lock" placeholder="请输入密码" v-model="password" @change.native="passwordChange" show-password></el-input>
  <el-input class="wh-input notusername-input-all" prefix-icon="el-icon-lock" placeholder="再次输入密码" v-model="formdata.password" @change.native="passwordChange" show-password></el-input>
  <!-- 手机号码 -->
    <el-input class="wh-input notusername-input-all" prefix-icon="el-icon-mobile-phone" placeholder="请输入手机号码" v-model="formdata.phone" maxlength="11"></el-input>
  <!-- 邮箱 -->
    <el-input class="wh-input notusername-input-all" prefix-icon="el-icon-message" placeholder="请输入邮箱" v-model="formdata.mail" maxlength="168"></el-input>
  </div>
</template>

<script>
import {sha256} from 'js-sha256'

export default {
 data() {
    return {
      password: '',
      formdata:{
        nickname: '',
        password: '',
        phone: '',
        mail: ''
      }
    }
  },
  methods: {
    /**
     * 判断重复的密码是否一致
     */
    passwordChange () {
      if(this.password != '' && this.formdata.password != ''){
        if(this.password === this.formdata.password){
          this.isOk = true;
        }else{
          this.isOk = false;
          this.$message.error('两次密码输入不一致！');
        }
      }
    },
    /**
     * 注册
     */
    toRegister () {
      //发送请求
      this.$http({
        url: '/api/v1/user/register',
        method: 'POST',
        data: {
          nickname: this.formdata.nickname,
          password: sha256(this.formdata.password),
          phone: this.formdata.phone,
          mail: this.formdata.mail,
          role: 2
        }
      }).then(res => {
        if(res.data.code === 200){
          this.$message.success(res.data.msg);
          this.$parent.$parent.currentName = 'first'
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.$message.error('网络错误');
      })
    }
  }
}
</script>

<style scoped>
.pc-register-input-box{
  width: 100%;
  height: 100%;
}
.el-input{
    width: 40rem;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    
  }
  .input-username{
    margin-top: 5rem;
    
  }
  .notusername-input-all{
    margin-top: 2rem;
  }
  .el-input >>> .el-input__inner{
    height: 6rem !important;
    background-color: #f2f2f2;
  }
  .el-input >>> .el-input__icon{
    line-height: 6rem;
    font-size: 2.5rem;
    width: 5.5rem;
  }
  .el-input >>> .el-input__inner{
    padding-left: 6.5rem;
    font-size: 2rem;
  }
  .el-input >>> .el-input__inner::placeholder{
    color: #999;
  }
</style>