<template>
  <div class="content">
      <my-table :headArray="headArray" :tableData="tableData">
      <template #title-label>
      商家账号
    </template>
    <!-- 操作 -->
    <!-- <template #cao-zuo>
        <el-table-column
    class-name="col-style"
      label="操作">
      <template slot-scope="scope">
        <el-button @click="dongjie(scope.row)" type="text" size="small">冻结</el-button>
        <el-button @click="jiedong(scope.row)" type="text" size="small">解冻</el-button>
      </template>
    </el-table-column>
    </template> -->
    </my-table>
  </div>
</template>

<script>
import myTable from '../../modules/my-table'
export default {
  components: { myTable },
  data(){
    return{
       headArray:[{
        prop:"bus_name",
        label:"企业名称"
      },{
        prop:"bus_prefix",
        label:"企业前缀"
      },{
        prop:"status",
        label:"申请状态"
      },{
        prop:"time",
        label:"申请时间"
      }],
      tableData:[]
    }
    },
  methods:{
     async getApiData(){
      try {
       let params = {
         page:1,
         number:100000
       }
     let {data:{data:{data}=[],code,msg}} = await this.$http({url:"/api/v1/business/prefix/list",method:"GET",params});
    if(code==200){
      data.forEach(item => {
        if(item.status==0){
          item.status="正在审核中";
        }else if(item.status==1){
          item.status="审核未通过";
        }else if(item.status==2){
          item.status="审核已通过";
        }
        });
     this.tableData=data;
    }else{
       this.$message({
            type:"error",
            message:msg
          })
      return;
    }}
     catch (error) {
       console.error(error)
        throw error;
      }
    },
  
  },
  async beforeMount(){
    await this.getApiData();
  }
  }
</script>

<style scoped>

</style>