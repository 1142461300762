<template>
  <!-- 底部导航栏 -->
  <div :style="footer">
    <el-row class="footerBj">
      <el-col :span="3"><div>1</div></el-col>
      <el-col :span="4">
        <el-card shadow="hover">
          <h2>联系我们</h2>
          <p><i class="el-icon-phone" size="20" />0570-3358817</p>
          <p><i class="el-icon-message" size="20" />official@zjid.net</p>
          <p><i class="el-icon-eleme" size="20" />www.zjid.net</p>
          <p><i class="el-icon-location" size="20" />浙江省衢州市柯城区翡翠滨江小区</p>
        </el-card>
      </el-col>
      <el-col :span="3"><div>1</div></el-col>
      <el-col :span="4">
        <el-card shadow="hover">
          <h2>友情链接</h2>
          <p><a href="https://www.zjid.net/">浙江网标物联有限公司</a></p>
          <p><a href="http://citln.zjid.net/">工业互联网标识解析二级节点平台</a></p>
          <p><a href="http://www.caict.ac.cn/">中国信通院</a></p>
          <p><a href="https://www.miit.gov.cn/">中华人民共和国工业和信息化部</a></p>
        </el-card>
      </el-col>
      <el-col :span="3"><div>1</div></el-col>
      <el-col :span="4">
        <el-card shadow="hover">
          <h2>加入我们</h2>
          <p><i class="el-icon-phone" size="20" />18562632357</p>
          <p><i class="el-icon-message" size="20" />official@zjid.net</p>
        </el-card>
      </el-col>
    </el-row>
    <el-col :span="3"><div>1</div></el-col>
    <div class="recordInfo">
      <p><a href="https://www.zjid.net/">浙江ID网 ©2020-2021 浙江网标物联有限公司</a></p>
      <p><a href="https://beian.miit.gov.cn/">浙ICP备19050062号-2</a></p>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    footerHeight:{
      type: Number
    },
  },
  data(){
    return{
      footer: {
        width: '100%',
        height: '280px',
        backgroundColor: '#2d3e50',
        position: 'absolute',
        left:0,
        right:0,
        bottom: "-"+this.footerHeight+"px",
      }
    }
  },
  //监听高度变化
  watch: {
      footerHeight(newfooterHeight) {
        this.$data.footer.bottom = "-"+newfooterHeight+"px"
      }
  },
}
</script>

<style lang='scss' scoped>
.footerBj {
  height: 220px;
  padding: 20px;
  color: #2d3e50;
}
.recordInfo {
  position: relative;
  height: 60px;
  width: 100%;
  background-color: #86BC42;
  color: #FFF;
  p {
    position: absolute;
    top:30%;
    left: 70%;
    &:first-child {
      left: 20%;
    }
    a {
      color: #FFF;
    }
  }
}
.el-card{
  background-color: #2d3e50;
  border: 0;
  color: white;
  font-size: 0.75vmax;
  h2{
    text-align: center;
    font-size: 1.5vmax;
    padding-bottom: 1vh;
    border-bottom: 2px solid #FFF;
  }
  p{
    margin-top: 0.5vh;
    a{
      color: white;
      text-decoration: none;
      &:hover{
      color: #009966;
      }
    }
  }
}
.el-col >>> .el-card.is-hover-shadow:hover{
  box-shadow: 0 1px 6px rgba(0, 0, 0,.2);
  border-color: #eee;
}
a {
  text-decoration: none;
}
</style>
