<template>
  <!-- 个人信息 -->
  <div id="Information">
    <!-- 内部导航栏 -->
    <div class="le-menu">
      <left-menu @isWitch="isWitch"></left-menu>
    </div>
    
    <!-- 子内容 -->
      <div class="right-children">
        <right-basics v-if="isShowRight"></right-basics>
        <right-security v-if="!isShowRight"></right-security>
      </div>
  </div>
</template>

<script>
import LeftMenu from './modules/pc-information-menu'
import RightBasics from './modules/pc-information-basics'
import RightSecurity from './modules/pc-information-security'

export default {
  name: 'Information',
  components: {
    LeftMenu,
    RightBasics,
    RightSecurity
  },
  data () {
    return{
      isShowRight: true
    }
  },
  methods:{
    /**
     * 切换选中的子功能---基础信息与安全问题切换
     */
    isWitch (num) {
      if(num == 1){
        this.isShowRight = true;
      }else if(num == 2) {
        this.isShowRight = false;
      }
    }
  }
}
</script>

<style scoped>
#Information {
  width: 100%;
  margin: 0 auto;
}
.le-menu{
  height: 75vh;
  width: 200px;
  float: left;
}
.right-children {
  float: left;
  height: calc(75vh - 8rem);
  width:  calc(100% - 12rem - 200px );
  margin: 4rem 6rem 0rem 6rem ;
}
</style>