<template>
  <div id="enterHome">
    <div class="main-two">
      <!-- 头部标题 -->
      <home-header></home-header>
      <!-- 尾部标签页 -->
      <div class="home-footer">
        <el-tabs v-model="activeName" type="border-card"  class="el-tabs" :before-leave="changeTabs">
          <el-tab-pane label="合作伙伴" name="first">
            <home-partner/>
          </el-tab-pane>
          <el-tab-pane label="平台介绍" name="second">
            <home-introduction/>
          </el-tab-pane>
          <el-tab-pane label="入驻商家" name="third">
            <settled-merchants/>
          </el-tab-pane>
          <el-tab-pane label="服务与支持" name="fourth">
            <home-joinus/>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 底部标题 -->
    <home-true-footer :footerHeight="footerHeight"></home-true-footer>
    <!-- 回到顶部 -->
    <div class="goTop" @click="gotoTop" ref="goTop" v-show="isShow">
      <img src="../../../assect/enterHomeImg/top.png" alt="">
    </div>
  </div>
</template>

<script>
import homeHeader from './modules/home-header.vue'
import homeTrueFooter from './modules/home-footer.vue'
import HomeIntroduction from './modules/home-introduction.vue';
import HomeJoinus from './modules/home-joinus.vue';
import HomePartner from './modules/home-partner.vue';
import SettledMerchants from './modules/settled-merchants.vue';
export default {
  name:'enterHome',
  components:{
    homeHeader,
    homeTrueFooter,
    SettledMerchants,
    HomePartner,
    HomeJoinus,
    HomeIntroduction
  },
  data() {
    return {
      activeName: 'second',
      isShow: false,
      footerHeight: 200
    };
  },
  mounted(){
    //计算初始高度
    this.footerHeight = window.innerHeight +200
    // 计算滚动高度
    window.addEventListener("scroll",this.handleScroll);
  },
  methods: {
    //返回顶部
    gotoTop(){
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    //获取页面滚动时的高度
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 350) {
        this.isShow = true
      }else {
        this.isShow = false
      }
    },
    //切换标签时计算高度
    changeTabs(){
      setTimeout(() => {
        //解决兼容性问题
        this.footerHeight = (document.body.scrollHeight - document.body.clientHeight) || 
        (document.documentElement.scrollHeight - document.documentElement.clientHeight)
      }, 50);
    }
  }
  
  
}
</script>

<style lang='scss' scoped>
$home_base_background: #e3e3e3;
%other-styles {
  display: flex;
  justify-content: center;
  align-items: center;
}
#enterHome{
  width:calc(100vw - 20px);
  background-color: $home_base_background;
  margin: 0 auto;
  box-sizing: border-box;
}
.main-two{
  position: relative;
  width: 90%;
  margin: 0 auto;
}
.home-footer{
  width: 100%;
  background-color: $home_base_background;
}
.el-tabs--border-card{
  box-shadow: none;
}
/*
*标签页样式
 */
.el-tabs{
  width: 100%;
  border: none;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  /deep/ .el-tabs__header{
    width: 100%;
    background-color: $home_base_background;
    border: none !important;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    .el-tabs__item{
      margin: 0;
      color: rgb(85, 85, 85);
    }
    .el-tabs__item.is-active{
      color: #009966;
    }
    .el-tabs__item:not(.is-disabled):hover{
      color: #009966;
    }
  }
  /deep/ .el-tabs__nav-wrap{
    width: 100%;
    border: none !important;
    
  }
  /deep/ .el-tabs__nav{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 0;
    padding: 0;
  }
  /deep/ .el-tabs__item{
    width: 13.333%;
    height: 5.5vh;
    text-align: center;
    margin-left: 0 !important;
    margin-right: 12rem;
    line-height: 5.5vh;
    overflow: hidden;
    background-color: $home_base_background;
    color: #999;
    vertical-align:middle;
    font: {
      weight: bold;
      size: 2rem;
    }
  }
  /deep/ .el-tabs__content{
    padding: 0;
    left: 0;
    right: 0;
    width: 90%;
    margin: 0 auto;
    position: absolute;
  }
}
.el-tab-pane{
  @extend %other-styles;
  flex: 1;
  padding: 40px 0;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
}
/*
*回到顶部
 */
.goTop{
  height: 40px;
  width: 40px;
  position: fixed;
  right: 15px;
  top: 70%;
  border-radius: 20px;
  border: 1px solid #eee;
  text-align: center;
  padding: 7px 4px 4px 4px ;
  img{
    width: 80%;
  }
}
</style>