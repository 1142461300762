<!--
 * @Description: 
 * @Version: 1.0
 * @Author: Mattxy
 * @LastEditors: Mattxy
-->
<template>
  <div class="company-introduction">
    <div class="company-introduction-main">
      <div class="company-introduction-main-header">
        <div class="company-introduction-main-header-icon">
          <i class="iconfont">&#xe634;</i>
        </div>
        <div class="company-introduction-main-header-title">
          <h2>企业信息</h2>
        </div>
      </div>
      <div class="company-introduction-main-body">
        <img class="company-introduction-main-body-img" :src="companyIntroduction.companyIntroductionPhoto">
        <el-row v-for="(item, index) in companyIntroduction.companyIntroductionActivity" :key="index">
          <el-col :span="6"><span class="traceability-information-main-body-spanleft">【{{item.name}}】</span></el-col>
          <el-col :span="18"><span class="traceability-information-main-body-spanright">{{item.content}}</span></el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"company-introduction",
    props:["companyIntroduction"]
}
</script>

<style scoped >
@import "../../assect/icon/iconfont.css";



.company-introduction {
  width: 100%;
  height: 100%;
  padding-top: 1.5vh;
  background-color: #f7f7f7;
}

.company-introduction-main {
  margin: 0 5vw 0 5vw;
  padding-bottom: 2.5vh;
  border-radius: 5px;
  background-color: #FFFFFF;
}

.company-introduction-main-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 4vh;
  line-height: 4vh;
  background: #009966;
  font-size: 3vw;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.iconfont{
  font-family:"iconfont" !important;
  font-size:3vw;font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  color: #DDFFEA;
}

.company-introduction-main-header-icon{
  margin-left: 15px;
  margin-right: 10px;
  color: #DDFFEA;
}

.company-introduction-main-header-title {
  color: #DDFFEA;
}

.company-introduction-main-body {
  margin: 1.5vh 5vw 0 5vw;
}

.company-introduction-main-body-img {
  width: 100%;
}

.traceability-information-main-body-spanleft {
  color: #191B1A;
  font-size: 3vw;
}

.traceability-information-main-body-spanright {
  color: #8F8F8F;
  font-size: 2.5vw;
}
</style>
