<template>
  <div class="title-msg-button-box">
    <pc-biao-ti-nie-rong-send>
      <template #send-btn>
        <el-button @click="sendMsgBtn" type="success wh-button">发送</el-button>
      </template>
    </pc-biao-ti-nie-rong-send>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import pcBiaoTiNieRongSend from '../../modules/pc-biao-ti-nie-rong-send.vue'
export default {
  components: { pcBiaoTiNieRongSend },
  computed:{
   ...mapGetters(["ad_sendNoticeTitle","ad_sendNoticeMsg"])
  },
   methods:{
     async sendMsgBtn(){
      try {
        let {data:{code,msg}} = await this.$http({
        url:"/api/v1/notice/role/group/send",
        method:"POST",
        data:{
          title:this.ad_sendNoticeTitle,
          message:this.ad_sendNoticeMsg,
          role_list:[2]
        }
      })
      if(code==200){
        this.$message({
        type:"success",
        message:msg
      })
      setTimeout(()=>{
        location.reload();
      },200);
      }else{
        this.$message({
        type:"error",
        message:msg
      })
      }
      } catch (error) {
         this.$message({
        type:"error",
        message:"网络错误"
      })
      console.error(error)
      }
     
  }
   }
}
</script>

<style src="../../css/title-msg-button-box.css" scoped>

</style>