<template>
  <div id="MBChange">
      <wb-mb-tab-bar>
        <div slot="left" @click="backClick">返回</div>
        <div slot="center">修改密码</div>
      </wb-mb-tab-bar>
      <div class="changContent">
         <div class="changinput">
            <div>
                <span>原密码</span>
                <input type="password" v-model="fullPass.oldPassword">
            </div>
            <div>
                <span>新密码</span>
                <input type="password" v-model="fullPass.newPassword">
            </div>
            <div>
                <span>再输一次</span>
                <input type="password" v-model="fullPass.passwordAgain">
            </div>
            <div class="changeButton" @click="changeButton">
                <button>确认</button>
            </div>
         </div>
      </div>
  </div>
</template>

<script>
//组件
import wbMbTabBar from '@/components/WB-Mb-Tabbar/wb-mb-tabBar.vue'
//插件
import {sha256} from 'js-sha256'
export default {
  name:'MBChange',
  components: { wbMbTabBar },
  data(){
      return {
          fullPass:{
              oldPassword:'',
              newPassword:'',
              passwordAgain:''
          }
      }
  },
  props:{
      newNumber:{
          type:Object,
          default(){
              return {}
          }
      }
  },
  methods:{
    // 一次验证
    verification(){
        // 密码
        if(this.fullPass.oldPassword==''||this.fullPass.oldPassword==undefined||this.fullPass.oldPassword==null){
         this.$toast.show('原密码不能为空');
         return false
        }else{
          if(this.fullPass.oldPassword.length <5){
            this.$toast.show('原密码长度不能小于5')
            return false
          }
        }
        if(this.fullPass.newPassword==''||this.fullPass.newPassword==undefined||this.fullPass.newPassword==null){
         this.$toast.show('新密码不能为空');
         return false
        }else{
          if(this.fullPass.newPassword.length < 5){
            this.$toast.show('新密码长度不能小于5')
            return false
          }
        }
        //确认密码
        if(this.fullPass.passwordAgain==''||this.fullPass.passwordAgain==undefined||this.fullPass.passwordAgain==null){
            this.$toast.show('确认密码不能为空');
            return false
        }else{
            if (this.fullPass.passwordAgain !== this.fullPass.newPassword){
                this.$toast.show('密码与确认密码不同');
                return false
            }
        }
        return true
    },
    changeButton(){
        let bool = this.verification()
        if(bool){
            //发送请求
            this.$http({
                url: '/api/v1/user/single/password',
                method: 'PUT',
                data:{
                    password: sha256(this.fullPass.passwordAgain)
                }
            }).then(res=>{
                if(res.data.code===200){
                    this.$toast.show(res.data.msg)
                }else{
                    this.$toast.show(res.data.msg)
                }
            }).catch(()=>{
                this.$toast.show('网络错误')
            })
        }
    },

    backClick(){
        this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.changContent{
    width: 100%;
    height: calc(100vh - 44rem);
    margin-top: 20rem;
}
.changinput{
    width: 80%;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%);
    font-size: 6.4rem;
}
.changinput>div{
    display: flex;
    width: 100%;
    height: 14.4rem;
    margin-top: 5%;
    margin-left: 50%;
    transform: translate(-50%);
}
.changinput>div>span{
    display: block;
    width: 20%;
    height: 14.4rem;
    line-height: 14.4rem;
}
.changinput>div>input{
    flex: 1;
    outline: none;
    border: 1px solid #000;
}
.changinput>div>button{
    width: 30%;
    height: 100%;
    margin-left: 50%;
    transform: translate(-50%);
    border-radius: 2.8rem;
    outline: none;
    background-color: #009966;
    color: #fff;
    font-size: 6.4rem;
}
.changinput .changeButton{
    margin-top: 20%;
}
</style>