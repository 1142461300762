<template>

  <div class="nav-bar">
      <div class="left"><slot name="left"></slot></div>
      <div class="center"><slot name="center"></slot></div>
      <div class="right"><slot name="right"></slot></div>     

</div>
</template>

<script>

export default {
  name: 'navBar',
  
}
</script>

<style>

.nav-bar{
    display: flex;
    width: 100%;
    height: 6%;
    text-align: center;
    box-shadow: 0px 1px 1px rgba(100,100,100,.1);
    background-color: #009966;
    color: #fff;
    flex-direction: row;
    align-content: center;
    position: fixed;
    z-index: 999;
    top: 0;
}
.left,.right{
    width: 10%;
    font-size: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center
}
/* 用flex布局将剩余部分全部占据 */
.center{
    flex: 1;
    font-size: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center
}
</style>