<template>
<!-- 商家管理员登录 -->
  <div class="content">
      <div class="le">
          <div class="le-top">
                <div class="logo">
                    <img src="../../../assect/login-icon/logo.png" alt="">
                </div>
                <div class="title">
                        <p class="title-CN">农产品质量追溯管理平台</p>
                        <p class="title-EN">Agricultural Product Quality Traceabillity Management Platform</p>
                </div>
          </div>
      </div>
      <div class="rg">
          <div class="login-box">
              <pc-login-register-tab>
                  <!-- 登录插槽 -->
                  <template #ad-login-content>
                      <!-- 登录输入框 -->
                     <pc-login-input  ref="loginInput"/>
                     <!-- 选择管理员登陆和忘记密码 -->
                    <div class="choice-find">
                        <!-- 选择登录 -->
                    <div class="choice-login">
                    <el-checkbox size="medium" v-model="checked">管理员登录</el-checkbox>
                     </div>
                     <!-- 忘记密码 -->
                     <div class="find-password">
                         <a href="javascript:;">忘记密码?</a>
                     </div>
                    </div>
                    <!-- 登录按钮 -->
                    <div class="login-btn">
                        <el-button class="color-btn" size="medium" type="primary" @click="toLogin">登&nbsp;录</el-button>
                    </div>
                  </template>
                  <!-- 注册插槽 -->
                  <template #ad-register-content>
                      <!-- 注册输入框 -->
                      <pc-register-input  ref="registerInput"/>
                      <!-- 注册按钮 -->
                      <div class="login-btn">
                        <el-button class="color-btn" size="medium" type="primary" @click="toRegister">注&nbsp;册</el-button>
                      </div>
                  </template>
              </pc-login-register-tab>
          </div>
      </div>
  </div>
</template>

<script>
import PcLoginRegisterTab from './modules/pc-login-register-tab';

// 插槽的内容
import PcLoginInput from './modules/pc-login-input';
import PcRegisterInput from './modules/pc-register-input';
export default {
     data() {
      return {
        checked: false
      };
    },
    components:{PcLoginRegisterTab,PcLoginInput,PcRegisterInput},
    beforeMount () {
        //判断是否已登录
        this.$http({
            url: '/api/v1/user/islogin',
            method: 'GET'
        }).then(res => {
            if(res.data.data === 200){
                switch(res.data.data.role){
                    case 2:
                        this.$router.push('/pc/bu/fail');
                        break;
                    case 0:
                        this.$router.push('/pc/adminhome');
                        break;
                    case 1:
                        this.$router.push('/pc/adminhome');
                        break;
                    default:
                        this.$message.success('其他页面');
                }
            }
        }).catch(() => {
          this.$message.error('网络错误');
      })
    },
    methods: {
        /**
         * 登录
         */
        toLogin () {
            this.$refs.loginInput.toLogin();
        },

        /**
         * 注册
         */
        toRegister () {
            this.$refs.registerInput.toRegister();
        }
    }
}
</script>

<style scoped>
.content{
    position: absolute;
    width: 100%;
    height: 100vh;
    background-image: url("../../../assect/bg-image/pc-login.png");
    z-index: 99;
    background-size: 100% 100%;
}
.le{
    width: 50%;
    height: 100%;
    left: 0;
    z-index: 99;
    position: absolute;
}

.le-top{
    width: 100%;
    height: 10vh;
    margin-top: 15px;
    margin-left: 15px;
    box-sizing: border-box;
    position: absolute;
    vertical-align: middle;
}
.le > .le-top > .logo{
   width: 12%;
   height: 100%;
   position: absolute;
   left: 0;
   vertical-align: middle;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
}
.le > .le-top > .logo > img{
   width: 63%;
   height: 63%;
}
.title{
    display: flex;
    width: 88%;
    height: 100%;
    float: right;
    right: 0;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
}
.title-CN{
    font-size: 2.02vw;
    color: white;
}
.title-EN{
    font-size: 0.721vw;
    color: white;
}

.rg{
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
}

.rg>.login-box{
    width: 24.25vw;
    position: absolute;
    right: 22rem;
    top: 20rem;
}

.choice-find{
     display: inline-block;
    margin-top: 3.2rem;
     width: 40rem;
}
a{
    text-decoration: none;
    color: #999;
}
.choice-login{
    width: 15rem;
    height: 3rem;
    line-height: 3rem;
    float: left;
    text-align: left;
}
/* 管理员登录的选择框的样式 */
.el-checkbox{
    color: #999;
}
/* 管理员登录的文字颜色 */
.el-checkbox >>> .el-checkbox__input.is-checked+.el-checkbox__label{
    color: #009966;
}
/* 管理员登录的选择框的颜色 */
.el-checkbox >>> .el-checkbox__input.is-checked .el-checkbox__inner,.el-checkbox >>> .el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background-color: #009966;
}
/* 管理员登录的选择框的hover颜色 */
.el-checkbox >>> .el-checkbox__inner:hover{
    border-color: #009966;
}
.el-checkbox >>> .el-checkbox__input.is-focus .el-checkbox__inner{
    border-color: #009966;
}
.el-checkbox >>> .el-checkbox:last-of-type{
    margin: 0;
}
.el-checkbox >>> .el-checkbox__label{
    font-size: 1.8rem;
}
.find-password{
    float: right;
    width: 15rem;
    text-align: right;
    line-height: 3rem;
    height: 3rem;
    font-size: 1.8rem;
}
.login-btn{
    display: inline-block;
    width: 40rem;
    margin-top: 5rem;
    padding-bottom: 5rem;
    text-align: center;
}
.el-button--medium{
    width: 100%;
    height: 5rem;
    vertical-align: middle;
    font-size: 2.5rem;
    padding: 0;
    margin: 0;
    line-height: 5rem;
    text-align: center;
}

.color-btn{
    background-color: green !important;
    border-color: green !important;
}
.color-btn:hover{
    background-color: #009966 !important;
    border-color: #009966 !important;
}

</style>