<template>
  <el-table
    :show-header="false"
    :data="listData"
    height="100%"
    highlight-current-row
    @cell-click="checkOne"
    class="lsitTable">
    <el-table-column
      prop="value"
      label="value">
    </el-table-column>
  </el-table>
</template>

<script>
  export default {
    props: {
      listData:{
        type:Array
      }
    },
    methods: {
      checkOne (val) {
        this.$emit('getInfo',val.value)
      }
    }
  }
</script>

<style scoped>
</style>