<template>
  <div class="crad" @click="() => {this.isCheck = !this.isCheck}">
    <div v-if="isCheck" class="isCheck">
      <div class="fill"></div>
      <i class="el-icon-check"></i>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isCheck: false
    }
  }
}
</script>

<style scoped>
.crad {
  margin: 10px;
  width: 20rem;
  height: 15rem;
  position: relative;
  background-color: #eee;
  cursor: pointer;
}
.fill{
  border-width: 0 20px 20px;
  border-style: solid;
  border-color: transparent transparent #13ce66;
  position: absolute;
  right: -13px;
  top: -3px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
i {
  position: absolute;
  color: white;
  right: 3px;
  top: 3px;
}
</style>
