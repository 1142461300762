<template>
  <div class="pc-login-input-box">
    <!-- 用户输入框 -->
    <el-input class="wh-input input-username" prefix-icon="el-icon-s-custom"
    placeholder="手机/邮箱/用户名/昵称"
    v-model="username"
    clearable>
    </el-input>

<!-- 密码输入框 -->

  <el-input class="wh-input notusername-input-all" prefix-icon="el-icon-lock" placeholder="密码" @keyup.native.enter="toLogin" v-model="password" show-password></el-input>
  
  </div>
</template>
<script>
import {sha256} from 'js-sha256'

export default {
 data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    toLogin () {
      // 获取 yyyy-mm-dd 格式的日期参数
      let date = new Date()
      let nowMonth = (date.getMonth()+1) >= 10 ? (date.getMonth()+1) : '0' + (date.getMonth()+1)
      let nowDay = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
      let nowDate = date.getFullYear() + '-' + nowMonth + '-' + nowDay
      
      //发送请求
      this.$http({
        url: '/api/v1/user/login',
        method: 'POST',
        data: {
          user_id: this.username,
          password: sha256(sha256(this.password) + nowDate)
        }
      }).then(res => {
        if(res.data.code === 200){
          this.$message.success(res.data.msg);
          //设置token
          sessionStorage.setItem('token', res.data.data.token);
          sessionStorage.setItem('role', res.data.data.role);
          this.$http.defaults.headers.common['Authorization'] = res.data.data.token;
          //页面跳转
          setTimeout(() => {
            switch(res.data.data.role){
              case 2:
                this.$router.push('/pc/bu/fail');
                break;
              case 0:
                this.$router.push('/pc/adminhome');
                break;
              case 1:
                this.$router.push('/pc/adminhome');
                break;
              default:
                this.$message.success('其他页面');
            }
          },200);
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.$message.error('网络错误');
      })
    }
  }
}
</script>
<style scoped>
/* 输入框的样式 */
.pc-login-input-box{
  width: 100%;
  height: 100%;
}
.el-input{
    width: 40rem;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    
  }
  .input-username{
    margin-top: 5rem;
    
  }
  .notusername-input-all{
    margin-top: 2rem;
  }
  .el-input >>> .el-input__inner{
    height: 6rem !important;
    background-color: #f2f2f2;
  }
  .el-input >>> .el-input__icon{
    line-height: 6rem;
    font-size: 2.5rem;
    width: 5.5rem;
  }
  .el-input >>> .el-input__inner{
    padding-left: 6.5rem;
    font-size: 2rem;
  }
  .el-input >>> .el-input__inner::placeholder{
    color: #999;
  }
</style>