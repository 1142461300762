/*
 * @Description: 
 * @Version: 1.0
 * @Author: AndBridge
 * @LastEditors: Mattxy
 */
import mbHome from '@/views/mobile/MBHome'
import mbIndex from'@/views/mobile/MBIndex/Index'
import mbLogo from '@/views/mobile/MBLogo/Index'
import mbProfit from '@/views/mobile/MBProfit/Index'
import mbLogin from '@/views/mobile/MBLogin'
import mbRegister from '@/views/mobile/MBRegister'
import SweepCodeResults from "@/views/mobile/SweepCodeResults"
import mbProfitMessage from '@/views/mobile/MBProfit/MBProfitChild/MBProfitMessage'
import mbProfitAdvice from '@/views/mobile/MBProfit/MBProfitChild/MBProfitAdvice/Index'
import mbPm from '@/views/mobile/MBProfit/MBProfitChild/MBPm'
import mbChange from '@/views/mobile/MBProfit/MBProfitChild/MBChange'
import mbSuggest from '@/views/mobile/MBProfit/MBProfitChild/MBProfitAdvice/MBProfitAdviceChild/MBSuggest'
import mbSaoSao from '@/components/Wb-Mb-qrcode/qrcode'

// import ProductInformation from "../views/mobile/SweepCodeResults/modules/ProductInformation"
// import TraceabilityInformation from "../views/mobile/SweepCodeResults/modules/TraceabilityInformation"
// import CompanyIntroduction from "../views/mobile/SweepCodeResults/modules/CompanyIntroduction"
const mobileRoutes=[
    {
        path:'/mb',
        redirect: "/mb/index",
        component: mbHome,
        children:[
            {
                path:'/mb/index',
                component: mbIndex
            },
            {
                path:'/mb/logo',
                component:mbLogo,
                meta:{
                    requiresAuth:true
                }
            },
            {
                path:'/mb/profit',
                component:mbProfit,
                name:'mbProfit',
                children:[
                    {
                        path:'/mb/profit/suggest',
                        name:'mbSuggest',
                        component:mbSuggest
                    }
                ],
                meta:{
                    requiresAuth:true
                }
            },
            {
                path:'/mb/profit/message',
                component:mbProfitMessage
            },
            {
                path:'/mb/profit/advice',
                component:mbProfitAdvice
            },
            {
                path:'/mb/profit/pm',
                component:mbPm
            },
            {
                path:'/mb/profit/change',
                component:mbChange
            },
        ]
    },
    {
        path:'/mb/login',
        component:mbLogin
    },
    {
        path:'/mb/register',
        component:mbRegister,
    },
    {
        path:'/mb/result',
        // path:'/mb/sweep/code/results',
        component:SweepCodeResults
    },
    {
        path:'/mb/saosao',
        component:mbSaoSao
    }
]

export default mobileRoutes