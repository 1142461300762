<template>
  <!-- 数据展示表格 -->
  <div style="height: 100%">
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      height="calc(100% - 28px - 1rem)"
      style="width: 100%">
      <!-- <el-table-column
        type="selection">
      </el-table-column> -->
      <el-table-column
        prop="products_type_id"
        label="产品id">
      </el-table-column>
      <el-table-column
        prop="products_type_name"
        label="产品名">
      </el-table-column>
      <el-table-column
        label="批次">
        <template slot-scope="scope">{{ Number(new Date(scope.row.batch)) }}</template>
      </el-table-column>
      <el-table-column
        prop="template_id"
        label="模板">
      </el-table-column>
      <el-table-column
        prop="products_num"
        label="标识数量">
      </el-table-column>
      <el-table-column
        label="注册时间">
        <template slot-scope="scope">{{ moment(scope.row.batch).format('YYYY年MM月DD日 HH:mm') }}</template>
      </el-table-column>
      <el-table-column
        label="操作">
        <template slot-scope="scope">
          <el-button @click="queryOne(scope.row)" type="text" size="small">查看</el-button>
          <el-button @click="generateOne(scope.row)" type="text" size="small">生成</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="number"
      :total="total"
      :current-page="page"
      style="text-align: center; margin-top: 1rem"
      @current-change="queryRegistered">
    </el-pagination>
  </div>
</template>

<script>
import moment from "moment"

export default {
  data() {
    return {
      tableData: [],
      page: 1,
      number: 10,
      total: 0,
      moment: moment
    }
  },
  beforeMount() {
    this.queryRegistered(1)
  },
  methods: {

    /**
     * 查询已注册信息
     */
    queryRegistered(page) {
      this.$http({
        url: this.Configs.adminUrl1 + '/api/v1/products/find/allownproducts',
        method: 'GET',
        params: {
          page: page,
          number: this.number
        }
      }).then(res => {
        if(res.data.code === 200){
          this.tableData =res.data.data.result
          this.total = res.data.data.total
          this.tableData.forEach(element => {
            element.time = element.batch
          });
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误');
      })
    },
    
    /**
     * 查询单个详情
     */
    queryOne (val) {
      this.$parent.DialogVisible = true
      this.$parent.products_type_id = val.products_type_id
      this.$parent.batch = val.batch
      this.$parent.queryOnePage(1)
    },

    /**
     * 生成单个二维码
     */
    // generateOne (val) {

    //   this.$http({
    //     url: this.Configs.adminUrl1 + '/api/v1/products/generate/qrcode',
    //     method: 'GET',
    //     params:{
    //       products_type_id: val.products_type_id,
    //       batch: val.batch
    //     }
    //   }).then(res => {
    //     if(res.data.code === 200){
    //       let a = document.createElement('a');
    //       this.$http.get(this.Configs.adminUrl1 + '/api/v1?downloadUrl=' + res.data.data,{
    //         responseType: 'blob',// 不加type值下载文件后会出现乱码
    //       }).then(res=>{
    //         let blob = new Blob([res.data])
    //         let objectUrl = URL.createObjectURL(blob) // 创建URL
    //         a.href = objectUrl;
    //         a.download = 'data.zip'; // 自定义文件名
    //         a.click() // 下载文件
    //         URL.revokeObjectURL(objectUrl); // 释放内存
    //       })
    //     }else {
    //       this.$message.error(res.data.msg)
    //     }
    //   }).catch(() => {
    //     this.$message.error('网络错误');
    //   })
    // },
    generateOne(val){
      let a = document.createElement('a');
          this.$http.get(this.Configs.adminUrl1 + '/api/v1/products/download/qrcode?downloadUrl=' + val.products_type_id + val.batch+'&products_type_id=' + val.products_type_id+'&batch=' + val.batch).then(res=>{
            if("application/octet-stream" == res.headers["content-type"]){
              this.$http.get(this.Configs.adminUrl1 + '/api/v1/products/download/qrcode?downloadUrl=' + val.products_type_id + val.batch+'&products_type_id=' + val.products_type_id+'&batch=' + val.batch,{
                responseType: 'blob',// 不加type值下载文件后会出现乱码
              }).then(res=>{
                let blob = new Blob([res.data])
                let objectUrl = URL.createObjectURL(blob) // 创建URL
                a.href = objectUrl;
                a.download = 'data.zip'; // 自定义文件名
                a.click() // 下载文件
                URL.revokeObjectURL(objectUrl); // 释放内存
              })
            }else if("application/json; charset=utf-8" == res.headers["content-type"]){
              alert(res.data.msg)
            }
          })
    },

    /**
     * 全选
     */
    allSelect () {
      this.$refs.multipleTable.toggleAllSelection();
    },

    /**
     * 删除选择项
     */
    delSelect () {
      let isSelect = document.getElementsByClassName('el-checkbox__original')
      for(let index = isSelect.length - 1; index >= 0; index--){
        if(isSelect[index].checked == true){
          this.tableData.splice(index,1)
        }
      }
    }
  }
}
</script>

<style scoped>
.el-table >>> .cell {
  text-align: center;
}
.el-table >>> .el-table__header-wrapper .cell {
  font-size: 2rem;
  color: black;
}
</style>