import { render, staticRenderFns } from "./pc-registered-addview-check-dialog-list.vue?vue&type=template&id=a50f0638&scoped=true&"
import script from "./pc-registered-addview-check-dialog-list.vue?vue&type=script&lang=js&"
export * from "./pc-registered-addview-check-dialog-list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a50f0638",
  null
  
)

export default component.exports