<template>
  <!-- 已发送消息 -->
  <div class="Registered">
    <div class="header">
      <div class="headerLeft">
        <span>已发送消息</span>
      </div>
      <div class="headerRight">
        <el-button @click="addOne">发送新消息</el-button>
      </div>
    </div>
    <div class="bodyer">
      <registered-table ref="registeredTable"></registered-table>
    </div>
    <div class="deleteButton">
      <el-button @click="delSelect">删除</el-button>
    </div>
  </div>
</template>
<script>
import RegisteredTable from './modules/pc-registered-table'

export default {
  components: {
    RegisteredTable,
  },
  data() {
    return {
      isbyTemplate: true,
      DialogVisible: false,
      products_type_id: null,
      batch: null
      // query: ''
    }
  },
  methods: {
    
    /**
     * 添加弹窗显示
     */
    addOne () {
      this.$router.push('/pc/adminhome/sendnotice/xiaofeizhe');
    },

    /**
     * 删除选中
     */
    delSelect () {
      this.$refs.registeredTable.delSelect();
    }
  }
}
</script>


<style lang='scss' scoped>
.Registered {
  width: 100%;
  height: 100%;
  position: relative;
  .header {
    height: 8rem;
    width: 100%;
    border-bottom: 2px solid #555;
    .headerLeft {
      width: 50%;
      height: 100%;
      line-height: 8rem;
      float: left;
      font-size: 2.5rem;
      padding-left: 2.5rem;
    }
    .headerRight {
      width: 50%;
      height: 100%;
      line-height: 8rem;
      float: left;
      text-align: right;
      padding-right: 2rem;
      button {
        border: 1px solid #009966;
        color: #009966;
        &:hover{
           background-color: #e6e9ee;
        }
      }
    }
  }
  .bodyer {
    height: calc(100% - 14rem);
  }
}
// .byTemplate {
//   display:table-cell;
//   vertical-align:middle;
//   text-align: right;
//   width: 20%;
// }
// .byTemplate span, .byIdentification span {
//   font-size: 2rem;
//   padding-top: 8px;
//   padding-bottom: 8px;
//   cursor: pointer;
// }
// .byTemplate span {
//   padding-left: 25%;
//   background-color: white;
// }
.deleteButton {
  margin-left: 2rem;
  button {
    border: 1px solid #009966;
    background-color: #fff;
    color: #009966;
    &:hover{
      background-color: #e6e9ee;
    }
  }
}
</style>