<template>
  <div>
    <slot></slot>
    <div>
      <div class="mphone">
        <span v-if="!isPreview">*请先点击预览</span>
        <iframe v-if="isPreview" class="wbtem" src="/#/mb/result?id_code=0000" frameborder="0"></iframe>
      </div>
      <div class="mnumber">
        <span>标识生成数量：</span>
        <el-input style="margin-top: 1rem" v-model="products_num" placeholder="请输入数字（0-?）" @keyup.native.enter="toGenerate"></el-input>
        <el-button class="generate" @click="toGenerate">生成</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  computed:{
      ...mapGetters(['templateData','isPreview'])
  },
  data () {
    return {
      products_num: null,
      clickfirst: null
    }
  },
  beforeMount () {
    this.clickfirst = (new Date()).getTime() - 10000
  },
  methods: {
    /**
     * 生成标识
     */
    toGenerate () {
      // 判断是否有进行过预览
      if(this.isPreview == true){
        // 判断生成数量有无
        if(this.products_num != null){
          // 操作点击限制
          let nowTime = (new Date()).getTime()
          if(nowTime - this.clickfirst < 10000){
            this.$message.error('10秒内只能进行一次生成操作！')
          }else{
            // 重置操作时间
            this.clickfirst = nowTime

            // 数据格式
            // var data = {
            //   template_id: "新模板",
            //   template_filed: this.templateData,
            // }

            var data = {
              template_id: "模板xxx",
              template_filed: {
                productInformation:'产品信息',
                traceabilityInformation:'溯源信息',
                companyIntroduction:'企业信息'
              },
            }
            
            // 获取产品名与产品ID
            var productsTypeName = sessionStorage.getItem("productsTypeName") ? sessionStorage.getItem("productsTypeName") : ''
            var productsTypeID = sessionStorage.getItem("productsTypeID") ? sessionStorage.getItem("productsTypeID") : ''

            //注册模板
            this.$http({
              url: this.Configs.adminUrl2 + '/api/v2/products/have/template',
              method: 'POST',
              data: JSON.stringify(data),
            }).then(res => {
              if (res.data.code == 200){
                this.$message.success(res.data.msg)

                var newData = {
                  template_id: "模板xxx",
                  data: this.templateData,
                  products_num: parseInt(this.products_num),
                  products_type_name: productsTypeName,
                  products_type_id: productsTypeID,
                }

                // 生成标识请求
                this.$http({
                  url: '/api/v1/business/register/handle',
                  method: 'POST',
                  data: JSON.stringify(newData),
                }).then(res => {
                  if(res.data.code === 200){
                    this.$message.success(res.data.msg)
                    // var that = this
                    setTimeout(() => {
                      // that.$router.push('/pc/bu/suc/registered');
                      window.location.reload()
                    },500)
                  }else{
                    this.$message.error(res.data.msg)
                  }
                }).catch(() => {
                  this.$message.error('网络错误');
                })
              }else{
                this.$message.error('res.data.msg')
              }

            }).catch(()=>{
              this.$message.error('网络错误')
            })
          }
        }else{
          this.$message.error('请输入生成数量')
        }
      }else{
        this.$message.error('请先进行预览确定模板样式')
      }
    }
  }
}
</script>

<style scoped>
.mphone {
  width: 400px;
  height: 65vh;
  float: left;
  text-align: center;
  border: 1px solid #555;
}
.mphone span {
  height: 100%;
  line-height: 65vh;
}
.mnumber {
  width: calc(100% - 400px);
  height: 65vh;
  position: relative;
  float: left;
  padding: 2rem;
  padding-right: 5rem;
  font-size: 1.5rem;
}
.generate {
  position: absolute;
  bottom: 0;
  right: 5rem;
  background-color: #009966;
  color: white;
  border-radius: 10px;
  font-size: 1.5rem;
}
.generate:hover {
  background-color: #e6e9ee;
  color: #555;
  border-color: #e6e9ee;
}
.el-input >>> .el-input__inner {
  background-color: rgb(248, 248, 248);
  border: 0px;
  border-radius: 10px;
  color: black;
}
.wbtem {
  height: 100%;
  width: 100%;
}
.wbtem .cal_bottom::-webkit-scrollbar {width:4px;}
</style>