<template>
  <div class="LocDistribution">
    <div class="LocDistributionLeft">
      <span>产品选择</span>
      <check-pro></check-pro>
    </div>
    <div class="LocDistributionRight">
      <loc-address></loc-address>
    </div>
  </div>
</template>

<script>
import LocAddress from './modules/pc-locdistribution-address'
import CheckPro from './modules/pc-locdistribution-checkpro'

export default {
  components: {
    LocAddress,
    CheckPro
  }
}
</script>

<style scoped>
.LocDistribution {
  width: 100%;
  height: 100%;
}
.LocDistributionLeft {
  position: relative;
  padding: 6rem 4rem;
  height: 100%;
  width: 15vw;
  float: left;
}
.LocDistributionLeft span {
  position: absolute;
  font-size: 2.5rem;
  top: 1.5rem;
  left: 5rem;
}
.LocDistributionRight {
  padding: 6rem;
  padding-left: 3rem;
  height: 100%;
  width: calc(100% - 15vw);
  float: left;
}
</style>
