<template>
  <!-- 已注册二级节点-审核入口 -->
  <div>
    <div class="buttonLeft" @click="DialogVisible = true">
      <div class="words">
        <slot></slot>
      </div>
    </div>
    <el-dialog
      :visible.sync="DialogVisible"
      width="30%"
      top="30rem">
      <el-input v-model="bus_name" placeholder="请输入内容" maxlength="25" @blur="rules(1)" style="margin-bottom: 10px"><template slot="prepend">企业名称：</template></el-input>
      <el-input v-model="bus_prefix" placeholder="请输入内容" @blur="rules(2)"><template slot="prepend">企业前缀：</template></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toSubmit">提交审核</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return{
      DialogVisible: false,
      bus_name: '',
      bus_prefix: ''
    }
  },
  methods: {
    /**
     * 填写的规则
     */
    rules (index) {
      if(index === 1){
        if(this.bus_name.length < 3 || this.bus_name.length > 25){
          this.$message.error('企业名称不合法（3-25个字符）')
          return false
        }
      }else{
        if(this.bus_prefix.slice(0,6) != "88.177"){
          this.$message.error('企业前缀不合法(‘88.177’开头的工业互联网前缀)')
          return false
        }
      }
      return true
    },

    /**
     *提交审核
     */
    toSubmit () {
      if(this.rules(1) && this.rules(2)){
        this.$http({
          url: '/api/v1/business/register/prefix/system',
          method: 'POST',
          data:{
            "bus_name": this.bus_name,
            "bus_prefix": this.bus_prefix
          }
        }).then(res => {
          if(res.data.code === 200){
            this.$message.success(res.data.msg)
            this.$router.push({path:'/pc/bu/failMessage'})
          }else{
            this.$message.error(res.data.msg)
          }
        }).catch(() => {
          this.$message.error('网络错误')
        })
        this.DialogVisible = false
      }
    }
  }
}
</script>

<style scoped>
.buttonLeft {
  width: 25%;
  height: 30rem;
  position: absolute;
  left: 15%;
  top: calc(25% + 30rem);
  /* border:1px solid; */
  color: white;
  font-weight: bold;
  border-radius:10px;
  text-align: center;
  line-height: 30rem;
  font-size: 3rem;
  display:table;
  background-image: linear-gradient(135deg, #90F7EC 10%, #32CCBC 100%);
}
.buttonLeft:hover {
  cursor: pointer;
  animation: example 0.3s;
  font-size: 3.7rem;
}
/* 动画代码 */
@keyframes example {
  0% {
    font-size: 3rem;
  }
  100% {
    font-size: 3.7rem;
  }
}
.buttonLeft .words {
  display:table-cell;
  vertical-align:middle;
  line-height: 5rem;
}
</style>