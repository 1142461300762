/* 引入组件 */

// 审核未通过
import FailMain from '@/views/business/FailMain/Index.vue'
import FailMessage from '@/views/business/FailMessage/Index.vue'
import FailRegister from '@/views/business/FailRegister/Index.vue'

// 商家菜单路由
import BusinessHome from '@/views/business/BusinessHome/Index.vue'

// 个人中心
//通知管理
import Notification from '@/views/business/PerCenter/Notification/Index.vue'
import UnreadNot from '@/views/business/PerCenter/Notification/modules/pc-notification-unread.vue'
import ReadNot from '@/views/business/PerCenter/Notification/modules/pc-notification-read.vue'
import CollectionNot from '@/views/business/PerCenter/Notification/modules/pc-notification-collection.vue'

// 投诉建议
import Complaints from '@/views/business/PerCenter/Complaints/Index.vue'

// 个人信息
import Information from '@/views/business/PerCenter/Information/Index.vue'

// 产品管理
// 注册信息
import Registered from '@/views/business/ProManagement/Registered/Index.vue'
import AddRegistered from '@/views/business/ProManagement/Registered/AddView/Index.vue'

// 政府监管
import Regulated from '@/views/business/ProManagement/Regulated/Index.vue'

// 客户管理
// 位置发布
import LocDistribution from '@/views/business/CusManagement/LocDistribution/Index.vue'
import WarningPosition from '@/views/business/CusManagement/WarningPosition/Index.vue'
import AntiCounterfeitWarningPosition from '@/views/business/CusManagement/AntiCounterfeitWarningPosition/Index.vue'

// 扫码数据
import ScanData from '@/views/business/CusManagement/ScanData/Index.vue'

// 图片管理
// 图片库
import PhotoGallery from '@/views/business/PhoManagement/PhotoGallery/Index.vue'

// 图片库
import RecycleBin from '@/views/business/PhoManagement/RecycleBin/Index.vue'

/* 放入组件 */
const routesMx=[
  {
    path: "/pc/bu/fail",
    name: 'FailMain',
    component: FailMain
  },
  {
    path: "/pc/bu/failMessage",
    name: 'FailMessage',
    component: FailMessage
  },
  {
    path: "/pc/bu/failRegister",
    name: 'FailRegister',
    component: FailRegister
  },//未通过审核
  {
    path: "/pc/bu/suc",
    name: 'BusinessHome',
    redirect: "/pc/bu/suc/notification",
    component: BusinessHome,
    children: [
      {
        path: "/pc/bu/suc/notification",
        name: 'Notification',
        redirect: "/pc/bu/suc/notification/unread",
        component: Notification,
        children: [
          {
            path: "/pc/bu/suc/notification/unread",
            name: 'UnreadNot',
            component: UnreadNot,
          },
          {
            path: "/pc/bu/suc/notification/read",
            name: 'ReadNot',
            component: ReadNot,
          },
          {
            path: "/pc/bu/suc/notification/collection",
            name: 'CollectionNot',
            component: CollectionNot,
          }
        ]
      },
      {
        path: "/pc/bu/suc/complaints",
        name: 'Complaints',
        component: Complaints,
      },
      {
        path: "/pc/bu/suc/information",
        name: 'Information',
        component: Information,
      },// 个人中心
      {
        path: "/pc/bu/suc/registered",
        name: 'Registered',
        component: Registered,
      },
      {
        path: "/pc/bu/suc/registered/add",
        name: 'AddRegistered',
        component: AddRegistered,
      },
      {
        path: "/pc/bu/suc/regulated",
        name: 'Regulated',
        component: Regulated,
      },// 产品管理
      {
        path: "/pc/bu/suc/distribution",
        name: 'LocDistribution',
        component: LocDistribution,
      },
      {
        path: "/pc/bu/suc/warning",
        name: 'WarningPosition',
        component: WarningPosition,
      },
      {
        path: "/pc/bu/suc/antiCounterfeit",
        name: 'AntiCounterfeitWarningPosition',
        component: AntiCounterfeitWarningPosition,
      },
      {
        path: "/pc/bu/suc/scanData",
        name: 'ScanData',
        component: ScanData,
      },// 客户管理
      {
        path: "/pc/bu/suc/gallery",
        name: 'PhotoGallery',
        component: PhotoGallery,
      },
      {
        path: "/pc/bu/suc/recycle",
        name: 'RecycleBin',
        component: RecycleBin,
      },// 图片管理
    ]
  }
];

export default routesMx;