<template>
  <!-- 子菜单 -->
  <el-menu
  @select="handleSelect"
  :default-active="defaultActive"
  class="el-menu-demo"
  >
    <el-menu-item :class="!isShowA ? 'menuItemB' : 'menuItemA'" index="1">
      <i :class="!isShowA ? 'iconfont icongeren_011' : 'iconfont icongeren_021'"></i>
      <h1 style="margin-top:5rem">基础信息</h1>
    </el-menu-item>
  <el-menu-item :class="!isShowB ? 'menuItemB' : 'menuItemA'" index="2">
    <i :class="!isShowB ? 'iconfont iconanquan_01' : 'iconfont iconanquan_02'"></i>
    <h1 style="margin-top:5rem">安全问题</h1>
  </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  data () {
    return {
      isShowA: true,
      isShowB: false,
      defaultActive: '1'
    }
  },
  methods: {
    /**
     * 菜单功能切换
     */
    handleSelect(key) {
      switch (key) {
        case "1":
          this.isShowFunc();
          this.isShowA=true;
          this.$emit('isWitch',1);
          break;
        case "2":
          this.isShowFunc();
          this.isShowB=true;
          this.$emit('isWitch',2);
          break;
        default:
          this.isShowFunc();
          this.isShowA=true;
          break;
      }
    },
    /**
     * 清空选中
     */
    isShowFunc(){
        this.isShowA=false;
        this.isShowB=false;
    }
  }
}
</script>


<style scoped>
/* 图标大小 */
.iconfont{
  font-size: 14rem;
}
/* 鼠标悬浮效果 */
.el-menu-demo li:first-child{
  border-bottom: 1px solid #555;
}
.el-menu-demo li:last-child{
  border-top: 1px solid #555;
}
.el-menu-demo li:hover {
  background-color: white;
}
.el-menu-demo li:hover i {
  color: #009966;
}
.el-menu-demo li:hover h1 {
  color: #009966;
}
/* 选中效果 */
.menuItemA {
  padding-top: 8vh;
  height: 37.5vh;
  text-align: center;
  border-right: 0px;
}
.menuItemB {
  padding-top: 8vh;
  height: 37.5vh;
  text-align: center;
  border-right: 1px solid #555;
  background-color: #F3F3F3;
}
.menuItemA h1 {
  color: #009966;
}
.menuItemB h1 {
  color: #909399;
}
/* 去默认边框 */
.el-menu {
  border-right: 0px;
}
/* 更改默认颜色 */
.el-menu >>> .is-active {
  color: #009966 !important;
  background-color: white;
}
</style>