<template>
  <!-- 详情展示功能弹窗 -->
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="500px"
      :show-close="false"
      top="20vh"
      :before-close="() => {this.$parent.dialogVisible = false}"
      center>
      <div class="mainBody">
        <div class="mphone">
          <iframe v-if="dialogVisible ===true" src="/#/mb/result?id_code=0000" class="wbtem" scrolling="yes" frameborder="0"></iframe>
        </div>
        <div class="mainBodyButton">
          <el-button @click="toCheck(3)">拒绝</el-button>
          <el-button @click="toCheck(2)">通过</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  props: ['dialogVisible', 'detailsData'],
  data () {
    return {
    }
  },
  methods: {
    /**
     * 审核商家提交的产品
     */
    toCheck(products_status) {
      this.$http({
        url: this.Configs.adminUrl2 + '/api/v2/admins/pass/products/check',
        method: 'POST',
        data:{
          products_type_id: this.detailsData.products_type_id,
          batch: this.detailsData.batch,
          merchant_user_id: this.detailsData.user_id,
          products_status:products_status,
        }
      }).then(res => {
        if(res.data.code === 200){
          this.$message.success(res.data.msg)
          this.$parent.dialogVisible = false
          this.$parent.queryData(1,null,null)
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误')
      })
    }
  }
}
</script>

<style scoped>
/*弹框样式*/
.el-dialog__wrapper >>> .el-dialog__header{
  padding: 0;
}
.el-dialog__wrapper >>> .el-dialog__body{
  padding: 0;
}
.mainBody {
  height: 65vh;
}
.mainBodyButton {
  width: 100px;
  height: 65vh;
  float: left;
  position: relative;
}
.mainBodyButton button {
  position: absolute;
  width: 80px;
  bottom: 5vh;
  margin: 0;
  left: 50%;
  transform:translate(-50%,0);
  background-color: #009966;
  color: white;
  border-radius: 15px;
}
.mainBodyButton button:nth-child(2) {
  bottom: 10vh;
}
.mainBodyButton button:hover {
  background-color: #e6e9ee;
  color: #555;
  border-color: #e6e9ee;
}
.mphone {
  width: 400px;
  height: 65vh;
  float: left;
  text-align: center;
  border: 1px solid #555;
}
.wbtem {
  height: 100%;
  width: 100%;
}
.wbtem .cal_bottom::-webkit-scrollbar {width:1px;}
</style>
