<template>
  <div class="tab-bar-item" @click="itemClick">
    <!-- 在插槽封装的时候尽量用div包装，用来写属性方便 -->
    <div v-if="!isActive"><slot name="item-img"></slot></div>
    <div v-else><slot name="item-img-active"></slot></div>
    <div :style="activeStyle"><slot name="item-text"></slot></div>
  </div>
</template>

<script>
export default {
  name: "TabBarItem",
  props: {
    path: String,
    activeColor: {
      type: String,
      default: "green",
    },
  },
  computed: {
    isActive() {
      return this.$route.path.indexOf(this.path) !== -1;
    },
    activeStyle() {
      return this.isActive ? { color: this.activeColor } : {};
    },
  },
  methods: {
    itemClick() {
      this.$router.replace(this.path);
    },
  },
};
</script>

<style>
.tab-bar-item {
  flex: 1;
  text-align: center;
  height: 22rem;
  font-size: 8rem;
}
.tab-bar-item img {
  height: 10rem;
  width: 10rem;
  margin-top: 1.5rem;
  vertical-align: middle;
}
.active {
  color: #f00;
}
</style>
