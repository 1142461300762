<template>
  <div class="homeSweep">
    <qrcode-stream @decode="onDecode" @init="onInit" class="qrcode">
    </qrcode-stream>
  </div>
</template>

<script>
//引入
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  //注册
  components: { QrcodeStream },

  data() {
    return {
      result: "", //扫码结果信息
      error: "", //错误信息
    };
  },

  methods: {
    onDecode(result) {
      this.result = result;
      if (this.result != "" && this.$route.query.iid == "false") {
        this.$store.commit("changRes", this.result);
        this.$router.push("/mb/index");
      }

      if (this.$route.query.iid == "true" && this.result != "") {
        this.$store.commit("addRes", this.result);
        this.$router.push("/mb/logo");
      }
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: 您需要授予相机访问权限";
          this.$toast.show(this.error);
          setTimeout(()=>{
            this.$router.go(-1)
          },1000)
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: 这个设备上没有摄像头";
          this.$toast.show(this.error);
          setTimeout(()=>{
            this.$router.go(-1)
          },1000)
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: 所需的安全上下文(HTTPS、本地主机)";
          this.$toast.show(this.error);
          setTimeout(()=>{
            this.$router.go(-1)
          },1000)
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: 相机被占用";
          this.$toast.show(this.error);
          setTimeout(()=>{
            this.$router.go(-1)
          },1000)
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: 安装摄像头不合适";
          this.$toast.show(this.error);
          setTimeout(()=>{
            this.$router.go(-1)
          },1000)
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: 此浏览器不支持流API";
          this.$toast.show(this.error);
          setTimeout(()=>{
            this.$router.go(-1)
          },1000)
        }
      }
    },
  },
};
</script>

<style scoped>
.qrcode {
  width: 100vw;
  height: 100vh;
}
.qrcode-stream-overlay {
  position: relative;
}
</style>