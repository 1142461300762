<template>
  <div class="selectAll" :class="{activeStyle:isActive}" >
     <div class="selsectContent">
        <div class="select" @click="selectClick">
          <div :class="{bgcStyle:isSelectAll}"></div>
         <span>全选</span>
      </div>
      <div class="delect" @click="delectClick">
          <span>删除</span>
      </div>
     </div>
  </div>
</template>

<script>
export default {
    name:'selectAll',
    props:{
        isActive:{
            type:Boolean
        },
        goods:{
            type:Array
        }
    },
    computed:{
        isSelectAll(){
            if(this.goods.length===0){
                return false
            }
            return !this.goods.find(item=> !item.check)
        }
    },
    methods:{
        selectClick(){
            if(this.isSelectAll){//全部选中
                this.goods.forEach(item => item.check=false);
            }else{//部分选中
                this.goods.forEach(item => item.check=true)
            }
        },
        delectClick(){
            this.$emit('newGoods')
        }
    }
}
</script>

<style scoped>
.selectAll{
    position: fixed;
    width: 100%;
    height: 20rem;
    bottom: 22rem;
    left: 0;
    right: 0;
    border: 1px solid #999;
    display: none;
    z-index: 9999;
}
.activeStyle{
    display: block;
}
.bgcStyle{
    background-color: #339966;
}
.selsectContent{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 7rem;
    margin-top: 10rem;
    transform: translateY(-50%);
}
.select{
    width: 20%;
    display: flex;
    margin-left: 4rem;
    align-items: center;
}
.select div{
    width: 6rem;
    height: 6rem;
    z-index: 9;
    border: 1px solid #000;
    margin-right: 2rem;
}
.delect{
    margin-right: 4rem;
}
</style>