<!--
 * @Description: {

 }
 * @Version: 1.0
 * @Author: AndBridge
 * @LastEditors: AndBridge
-->
<template>
    <div id="home-header">
      <div class="home-header-content">
        <div class="home-header-content-left">
          <div class="home-left-title">农产品质量追溯管理平台</div>
          <div class="title-introduction-first">公司介绍：一个给生产者追溯问题解决问题的平台，一个让消费者了解农产品生命周期的平台。</div>
          <div class="header-button">
            <el-button type="primary" size="medium" @click="loadClick">平台入口</el-button>
            <el-button @click="locadDate" size="medium">数据展示</el-button>
          </div>
        </div>
        <div class="home-header-content-right">
          <img src="../../../../assect/enterHomeImg/header-right.png" alt="商家功能">
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name:'home-header',
  methods:{
    loadClick(){
      this.$router.push('/pc/loading')
    },
    locadDate(){
      this.$router.push('/ncpsjzs')
    }
  }
}
</script>

<style lang='scss' scoped>
%other-style{
  display: flex;
  justify-content: center;
}
$base_color: #e3e3e3;
$base_color_two: #009966;
#home-header{
  @extend %other-style;
  background-color: $base_color;
  width: 100%;
  .home-header-content{
    display: flex;
    width: 80%;
    margin-top: 5rem;
    .home-header-content-left{
      @extend %other-style;
      flex-direction: column;
      align-items: center;
      width: 50%;
      .home-left-title{
        font: {
          size: 5rem;
          weight: bold;
        }
        color: $base_color_two;
        text-align: center;
      }
      .title-introduction-first{
        width: 80%;
        margin-top: 2rem;
        font-size: 2rem;
      }
      .header-button{
        @extend %other-style;
        width: 80%;
        padding-top: 5rem;
        box-sizing: border-box;
      }
    }
    .home-header-content-right{
      flex: 1;
      width: 50%;
      margin-top: 3rem;
      margin-bottom: 4rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

/* 按钮样式更改 */
.el-button{
  width: 24rem;
  height: 8rem;
  font: {
    size: 2.7rem;
    weight: bold;
  }
  font-size: 2.7rem;
  font-weight: bold;
  border-radius: 1.3rem;
  color: $base_color_two;
  border: 2px solid $base_color_two;
  background-color: transparent;
}
.el-button--primary{
  background-color: $base_color_two;
  color: #fff;
  font-size: 2.6rem;
  margin-right: 3rem;
}
</style>