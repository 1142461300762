<template>
  <!-- 个人信息 -->
  <div class="basics">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="用户ID：" prop="user_id">
        <el-input v-model="ruleForm.user_id" disabled></el-input>
      </el-form-item>
      <el-form-item label="真实姓名：" prop="realname">
        <el-input v-model="ruleForm.realname" :disabled="ruleForm.role == 2"></el-input>
      </el-form-item>
      <el-form-item label="年龄：" prop="age">
        <el-input type="number" v-model="ruleForm.age"></el-input>
      </el-form-item>
      <el-form-item label="电话：" prop="phone">
        <el-input v-model="ruleForm.phone" maxlength="11"></el-input>
      </el-form-item>
      <el-form-item label="邮箱：" prop="mail">
        <el-input v-model="ruleForm.mail"></el-input>
      </el-form-item>
    </el-form>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="昵称：" prop="nickname">
        <el-input v-model="ruleForm.nickname" :disabled="ruleForm.role == 2"></el-input>
      </el-form-item>
      <el-form-item label="性别：" prop="sex">
        <el-select v-model="ruleForm.sex" placeholder="请选择" class="width100">
          <el-option label="保密" value="保密"></el-option>
          <el-option label="男" value="男"></el-option>
          <el-option label="女" value="女"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="注册时间：" prop="time">
        <el-input v-model="ruleForm.time" disabled></el-input>
      </el-form-item>
      <el-form-item label="账户角色：" prop="role">
        <el-input v-model="role" disabled></el-input>
      </el-form-item>
      <el-form-item label="账户备注：" prop="remarks">
        <el-input v-model="ruleForm.remarks" disabled></el-input>
      </el-form-item>
    </el-form>
    <div class="button-click">
        <el-button class="buttonSub" @click="submitForm">确定</el-button>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        role: '未知角色',
        ruleForm: {
          user_id: '',
          realname: '',
          nickname: '',
          age: 0,
          sex: '',
          phone: '',
          mail: '',
          time: '',
          role: 0,
          remarks: ''
        },
        rules: {
          age: [
            { required: true, message: '请输入年龄', trigger: 'blur' },
            { pattern: /^1[6-9]|[2-9]\d|100$/, message: '范围在16-100',trigger: 'blur'}
          ],
          phone:[
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { pattern: /^\d{11}$/, message: '请输入正确的11位手机号',trigger: 'blur'}
          ],
          mail: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
            { pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/, message: '请输入正确的邮箱格式',trigger: 'blur'}
          ]
        }
      };
    },
    beforeMount () {
      // 获取个人信息
      this.$http({
        url: '/api/v1/user/single/info',
        method: 'GET'
      }).then( res => {
        if(res.data.code === 200){
          this.ruleForm = res.data.data
          switch (this.ruleForm.role) {
            case 0:
              this.role = '超级管理员'
              break
            case 1:
              this.role = '农业局账户'
              break
            case 2:
              this.role = '商家账户'
              break
            case 3:
              this.role = '消费者账户'
              break
            default:
              this.role = '未知角色'
          }
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误')
      })
    },
   methods: {
      /**
       * 提交修改信息
       */
      submitForm () {
        this.$http({
          url: '/api/v1/user/single/info',
          method: 'PUT',
          data:{
            "nickname": this.ruleForm.nickname,
            "phone": this.ruleForm.phone,
            "mail": this.ruleForm.mail,
            "realname": this.ruleForm.realname,
            "age": Number(this.ruleForm.age),
            "sex": this.ruleForm.sex
          }
        }).then( res => {
          if(res.data.code === 200){
            this.$message.success(res.data.msg)
          }else{
            this.$message.error(res.data.msg)
          }
        }).catch(() => {
          this.$message.error('网络错误')
        })
      }
    }
  }
</script>
<style scoped>
.basics {
  margin: 0 auto;
  display: flex;
  width: 80%;
  height: 100%;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
}
.demo-ruleForm {
  width: 45%;
}
.el-form-item >>>.el-form-item__label {
  font-size: 2rem !important;
  font-weight: bold;
}
.width100 {
  width: 100%;
}
.buttonb {
  position: absolute;
  bottom: 25vh;
}
.button-click {
  width: 100%;
  margin-top: 80px;
  height: 40px;
  text-align: center;
}
.buttonSub {
  width: 50%;
  border: 1px solid #009966;
  color: #009966;
}
.buttonSub:hover {
  border: 1px solid #009966;
  color: #009966;
  background-color: #e6e9ee !important;
}
</style>