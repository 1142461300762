<template>
  <div id="BusinessHome">
    <!-- 头部标题 -->
    <div class="header">
      <wb-pc-header-title/>
    </div>
    
    <!-- 左部菜单栏 -->
    <div class="le-menu">
      <pc-left-menu/>
    </div>
    
    <div class="rg-content">
      <!-- 面包屑 -->
      <div class="mian-bao-xie">
        <wb-pc-mian-bao-xie @click.native="btnClick"/>
      </div>

      <!-- 子内容 -->
      <div :style="isAdd ? 'border: 0px': ''" class="admin-children">
        <transition>
          <router-view/>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import WbPcHeaderTitle from '@/components/Wb-Pc-Header/wb-pc-header-title'
import PcLeftMenu from './modules/pc-businesshome-menu'
import WbPcMianBaoXie from '../../../components/Wb-Pc-Rg-Content/wb-pc-mian-bao-xie.vue';
//导入vueX
import {mapGetters} from 'vuex';
export default {
  name: 'BusinessHome',
  components: {
    WbPcHeaderTitle,
    PcLeftMenu,
    WbPcMianBaoXie
  },
  computed:{
    ...mapGetters(["wb_mianBaoXieArray"])
  },
  data () {
    return {
      isAdd: false
    }
  },
  beforeMount () {
    if('/pc/bu/suc/registered/add' === this.$route.path){
      this.isAdd = true
    }else {
      this.isAdd = false
    }
  },
  methods:{
    btnClick(){
      let fullPath = this.wb_mianBaoXieArray[0]
      switch (fullPath) {
        case '个人中心':
          this.$router.push('/pc/bu/suc/notification')
          break;
        case '产品管理':
          this.$router.push('/pc/bu/suc/registered')
          break;
        case '客户管理':
          this.$router.push('/pc/bu/suc/distribution')
          break;
        case '图片管理':
          this.$router.push('/pc/bu/suc/gallery')
          break;
        default:
          this.$router.push('/pc/bu/suc/notification')
          break;
      }
    }
  }
}
</script>

<style scoped>
#BusinessHome {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: absolute;
}
.le-menu{
  width: 12.1vw;
  height: 92.5vh;
  float: left;
  background-color: #009966;
}
.rg-content{
  width: 87.9vw;
  height: 92.5vh;
  background-color: rgb(248, 248, 248);
  position: absolute;
  right: 0;
  bottom: 0;
}
.rg-content .mian-bao-xie{
  width: 100%;
  height: 5rem;
  padding: 1.8rem 0;
  border-bottom: 1px solid slategrey;
  padding-left: 1.15rem;
  font-weight: bold;
}
.admin-children {
  margin: 5rem 10rem 0rem 10rem;
  height: calc(75vh + 2px);
  background-color: white;
  border: 1px solid #555;
}
</style>