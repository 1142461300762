// 全局初始化
import Vuex from 'vuex';
import Vue from 'vue';
Vue.use(Vuex);
// 引入模块
import admin from './admin';

import mobil from './mobil'

// 全局
import state from './state';
import getters from './getters';
import mutations from './mutations';
const Store=new Vuex.Store({
    strict:true,
    namespaced:true,
    state,
    getters,
    mutations,
    modules:{
        admin,
        mobil
    }
})

export default Store;