<template>
  <!--审核未通过--注册视图 -->
  <div id="RegisterFailBu">
    <wb-header></wb-header>
    <el-button class="buttonBack" @click="toBack">返回</el-button>

    <!-- 注册表单信息填写区域 -->
    <fform></fform>
  </div>
</template>

<script>
import Header from '@/components/Wb-Pc-Header/wb-pc-header-title'
import Form from './modules/pc-form'

export default {
  name: 'RegisterFailBu',
  components: {
    wbHeader: Header,
    fform: Form
  },
  methods:{
    /**
     * 返回按钮
     */
    toBack () {
      this.$router.go(-1)
    },
  }
}
</script>

<style scoped>
#RegisterFailBu {
  width: 100%;
  margin: 0 auto;
}
.buttonBack {
  position: absolute;
  right: 15rem;
  top: 9vh;
}
</style>