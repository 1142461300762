<!--
 * @Description: 
 * @Version: 1.0
 * @Author: Mattxy
 * @LastEditors: AndBridge
-->
<template>
  <div class="traceability-information">
    <div class="traceability-information-main">
      <div class="traceability-information-main-header">
        <div class="traceability-information-main-header-icon">
          <i class="iconfont">&#xe635;</i>
        </div>
        <div class="traceability-information-main-header-title">
          <h2>溯源信息</h2>
        </div>
      </div>
      <div class="traceability-information-main-body">
        <div class="traceability-information-main-body-block">
          <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in traceabilityInformation.traceabilityInformationActivities"
              :key="index"
              :color="activitiescolor">
              <span class="traceability-information-main-body-block-title">{{activity.title}}</span>
              <div v-for="(item, index) in activity.content" :key="index">
                <div v-if="item.type == 'text'">
                  <span class="traceability-information-main-body-block-content-spanleft">{{item.name}}</span>
                  <span class="traceability-information-main-body-block-content-spanright">{{item.content}}</span>
                </div>
                <div v-else-if="item.type == 'photo'">
                  <img class="traceability-information-main-body-block-content-img" :src="item.content">
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"traceability-information",
    props:["traceabilityInformation"],
    data() {
      return {
        activitiescolor: '#0bbd87'
      };
    },
}
</script>

<style scoped >
@import "../../assect/icon/iconfont.css";

.traceability-information {
  width: 100%;
  height: 100%;
  padding-top: 1.5vh;
  background-color: #f7f7f7;
}

.traceability-information-main {
  margin: 0 5vw 0 5vw;
  padding-bottom: 2.5vh;
  border-radius: 5px;
  background-color: #FFFFFF;
}

.traceability-information-main-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 4vh;
  line-height: 4vh;
  background: #009966;
  font-size: 3vw;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.iconfont{
  font-family:"iconfont" !important;
  font-size:3vw;font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  color: #DDFFEA;
}

.traceability-information-main-header-icon{
  margin-left: 15px;
  margin-right: 10px;
  color: #DDFFEA;
}

.traceability-information-main-header-title {
  color: #DDFFEA;
}

.traceability-information-main-body {
  margin: 1.5vh 5vw 0 5vw;
}

.traceability-information-main-body-block-title {
  color: #009966;
  font-size: 3.5vw;
}

.traceability-information-main-body-block-content-spanleft {
  display:inline-block;
  width: 20vw;
  color: #191B1A;
  font-size: 3vw;
}

.traceability-information-main-body-block-content-spanright {
  color: #8F8F8F;
  font-size: 2.5vw;
}

.el-timeline-item {
  padding-bottom: 0;
}

.traceability-information-main-body-block-content-img {
  width: 70vw;
}
</style>
