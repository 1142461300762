<template>
  <!-- 详情展示功能弹窗 -->
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="60%"
      :show-close="false"
      top="20vh"
      :before-close="() => {this.$parent.dialogVisible = false}"
      center>
      <div class="mainBody">
        <div class="mainBodyLeft">
          <div class="mainBodyLeftHeader">
            <span>详情</span>
          </div>
          <div class="mainBodyLeftBodyer">
            <div class="comeFrom">
              <el-row>
                <el-col :span="4"><span>标题：</span></el-col>
                <el-col :span="20">
                  <div class="inputCss">
                    <slot name="title"></slot>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="time">
              <el-row>
                <el-col :span="4"><span>时间：</span></el-col>
                <el-col :span="20">
                  <div class="inputCss">
                    <slot name="send_time"></slot>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="contentb">
              <el-row>
                <el-col :span="4"><span>内容：</span></el-col>
                <el-col :span="20">
                  <div class="article">
                    <slot name="content"></slot>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="mainBodyRight">
          <div class="mainBodyRightHeader">
            <span>反馈</span>
          </div>
          <div class="mainBodyRightBodyer">
            <div>
              <el-row>
                <el-col :span="3"><span>内容：</span></el-col>
                <el-col :span="20">
                  <el-input type="textarea" v-model="handle_msg"></el-input>
                </el-col>
                <el-col :span="1"></el-col>
              </el-row>
            </div>
            <el-button @click="toSend">发 送</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  props: ['dialogVisible','id'],
  components:{
  },
  data () {
    return {
      handle_msg: ''
    }
  },
  methods: {
    /**
     * 发送投诉建议
     */
    toSend() {
      this.$http({
        url: '/api/v1/sugges',
        method: 'PUT',
        data: {
          id: this.id,
          handle_msg: this.handle_msg
        }
      }).then(res => {
        if(res.data.code === 200){
          this.$message.success(res.data.msg)
          this.handle_msg = ''
          this.$parent.id = ''
          this.$parent.dialogVisible = false
          this.$parent.$parent.pageChioce(-1)
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误1')
      })
    }
  }
}
</script>

<style scoped>
/*弹框样式*/
.el-dialog__wrapper >>> .el-dialog__header{
  padding: 0;
}
.el-dialog__wrapper >>> .el-dialog__body{
  padding: 0;
}
.grid-content {
  text-align: center;
}
.mainBody {
  height: 65vh;
  font-size: 2rem;
}
.mainBody span{
  width: 80px;
}
.mainBody .mainBodyLeft{
  width: 50%;
  height: 100%;
  float: left;
  padding-left: 5rem;
  border-right: 2px dashed #999;
}
.mainBody .mainBodyLeft .mainBodyLeftHeader{
  width: 100%;
  height: 10vh;
  line-height: 10vh;
}
.mainBody .mainBodyLeft .mainBodyLeftHeader span {
  color: #555;
  font-weight: bold;
  font-size: 3rem;
}
.mainBody .mainBodyLeft .mainBodyLeftBodyer div{
  margin-bottom: 0.5vh;
}
.mainBody .mainBodyRight{
  width: 50%;
  height: 100%;
  float: left;
  padding-left: 5rem;
}
.mainBody .mainBodyRight .mainBodyRightHeader{
  width: 100%;
  height: 10vh;
  line-height: 10vh;
}
.mainBody .mainBodyRight .mainBodyRightHeader span {
  color: #555;
  font-weight: bold;
  font-size: 3rem;
}
.mainBody .mainBodyRight .mainBodyRightBodyer{
  width: 100%;
}
.mainBody .mainBodyRight .mainBodyRightBodyer div{
  margin-bottom: 0.5vh;
}
.contentb span,.comeFrom span,.time span {
  height: 4.5vh;
  line-height: 4.5vh;
}
.inputCss {
  float: left;
  height: 4.5vh;
  width: 90%;
  padding: 1.5rem 3rem 0 3rem;
  font-size: 1.5rem;
  border-radius: 15px;
  background-color: #eee;
}
.contentb .article {
  float: left;
  height: calc(75vh - 42rem);
  width: 90%;
  padding: 1.5rem 3rem 0 3rem;
  font-size: 1.5rem;
  border-radius: 15px;
  background-color: #eee;
  overflow: scroll;
}
button {
  position: absolute;
  bottom: 5vh;
  right: 2rem;
  background-color: #009966;
  color: white;
  border-radius: 15px;
}
button:hover {
  background-color: #e6e9ee;
  color: #555;
  border-color: #e6e9ee;
}
.el-textarea >>> .el-textarea__inner{
  height: 40vh !important;
}
.el-input >>> .el-input__inner{
  border-radius: 15px;
  background-color: #eee;
}
.el-textarea >>> .el-textarea__inner{
  border-radius: 15px;
  background-color: #eee;
}
</style>
