<template>
  <!-- 投诉建议 -->
  <div class="Complaint">
    <div class="header">
      <div class="headerLeft">
        <el-input class="complaintSearch" prefix-icon="el-icon-search" placeholder="请输入标题" v-model="searchVal" @keyup.native.enter="toSearch"></el-input>
      </div>
      <div class="headerCenter">
        <span>状态：</span>
        <el-select v-model="selectStatus" @change="changeStatus">
          <el-option
            v-for="item in selectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="headerRight">
        <el-button @click="addOne">新增</el-button>
      </div>
    </div>
    <!-- 表格信息 -->
    <div class="bodyer">
      <complaint-table ref="complaintTable"></complaint-table>
    </div>
    <div class="footer">
        <el-button @click="deleteSelect">删除</el-button>
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="number"
          :total="total"
          :current-page="page"
          style="text-align: center; margin-top: 3rem;"
          @current-change="pageChioce">
        </el-pagination>
        <span class="footerSpan">每页显示{{number}}条数据共{{pages}}页</span>
    </div>
    <div>
      <complaint-dialog :dialogVisible="dialogVisible" ref="complaintDialog"></complaint-dialog>
    </div>
  </div>
</template>
<script>
import ComplaintTable from './modules/pc-complaint-table'
import ComplaintDialog from './modules/pc-complaint-dialog'

export default {
  components: {
    ComplaintTable,
    ComplaintDialog
  },
  data() {
    return {
      searchVal: '',
      selectOptions: [{
        value: '2',
        label: '全选'
      }, {
        value: '0',
        label: '未处理'
      }, {
        value: '1',
        label: '已处理'
      }],
      selectStatus: '2',
      page: 1,
      number: 10,
      total: 0,
      pages: 1,
      dialogVisible: false,
    }
  },
  methods: {

    /**
     * 换页
     */
    pageChioce(page) {
      this.$refs.complaintTable.queryData(page,this.selectStatus,this.searchVal)
    },

    /**
     * 根据标题筛选
     */
    toSearch () {
      this.$refs.complaintTable.queryData(1,this.selectStatus,this.searchVal)
    },

    /**
     * 状态筛选
     */
    changeStatus() {
      this.$refs.complaintTable.queryData(1,this.selectStatus,this.searchVal)
    },

    /**
     * 删除选中
     */
    deleteSelect() {
      this.$refs.complaintTable.deleteSelect()
    },

    /**
     * 新增弹窗显示
     */
    addOne() {
      this.dialogVisible = true
    },
  }
}
</script>


<style scoped>
.Complaint {
  width: 100%;
  height: 100%;
  position: relative;
}
.bodyer {
  height: calc(100% - 18rem);
}
.header {
  height: 8rem;
  width: 100%;
  border-bottom: 2px solid #555;
}
.header .headerLeft {
  width: 30%;
  height: 100%;
  line-height: 8rem;
  float: left;
  font-size: 2.5rem;
  padding-left: 2.5rem;
}
.header .headerLeft .complaintSearch {
  width:70%;
}
.header .headerCenter {
  width: 50%;
  height: 100%;
  line-height: 8rem;
  float: left;
  font-size: 2rem;
  padding-left: 2.5rem;
}
.header .headerRight {
  width: 20%;
  height: 100%;
  line-height: 8rem;
  float: left;
  text-align: right;
  padding-right: 2rem;
}
.byTemplate {
  display:table-cell;
  vertical-align:middle;
  text-align: right;
  width: 10rem;
}
.byTemplate span, .byIdentification span {
  font-size: 2rem;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}
.byTemplate span {
  padding-left: 25px;
  background-color: white;
}
.footer {
  position: absolute;
  bottom: 0;
  height: 10rem;
  width: 100%;
}
.footer button {
  position: absolute;
  top: 3rem;
  left: 3rem;
  margin: 0;
  background-color: #009966;
  color: white;
  border-radius: 15px;
}
.footer button:hover {
  background-color: #e6e9ee;
  color: #555;
  border-color: #e6e9ee;
}
.footer .footerSpan {
  position: absolute;
  top: 3.5rem;
  right: 3rem;
  color: #777;
  font-size: 1.6rem;
  letter-spacing: 2px;
}
.headerRight button {
  background-color: #009966;
  color: white;
  border-radius: 15px;
}
.headerRight button:hover {
  background-color: #e6e9ee;
  color: #555;
  border-color: #e6e9ee;
}
.el-dialog__wrapper >>> .el-dialog__header {
  padding: 0 !important;
}
</style>