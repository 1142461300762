<!--
 * @Description: 
 * @Version: 1.0
 * @Author: AndBridge
 * @LastEditors: AndBridge
-->
<template>
  <div id="product-information">
    <!-- 轮播图 -->
    <div class="carousel">
      <el-carousel type="card" trigger="click" height="20vh">
        <el-carousel-item v-for="(item,key) in productInformation.carouselImages" :key="key">
          <img :src="item.content" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 扫码次数及验证 -->
    <div class="product-title-verify-code">
      <div class="verify">
        <div class="scan-num">
          <span>扫码次数:{{productInformation.scanNum}}</span>
        </div>
        <div class="approval-status">
          <span>{{productInformation.isAudit}}</span>
        </div>
      </div>
      <div class="product-title">
        <h3>{{productInformation.productTitle}}</h3>
      </div>
      <div class="product-code overflow">
        <span>{{productInformation.name}}</span>
        <span>{{productInformation.productCode}}</span>
      </div>
    </div>
    <div class="product-parameter">
    <!-- 产品基本信息 -->
      <div class="basic-product-info">
        <div class="product-basic-information-header">
          <div class="product-basic-information-icon">
            <i class="iconfont">&#xe631;</i>
          </div>
          <div class="product-basic-information-title">
            <h2>基本信息</h2>
          </div>
        </div>
        <div class="product-basic-information-table-content">
          <div v-for="(item,key) in productInformation.productBasicInformation" :key="key+100" >
            <div v-if="item.type=='text'" class="product-basic-information-table-column">
            <span class="name">{{item.name}}</span>
            <span>{{item.content}}</span>
          </div>
          <div v-if="item.type=='photo'" class="product-basic-information-table-column certification-information">
            <span class="name certification-name">{{item.name}}</span>
            <div class="certification-content">
              <img :src="item.content" alt="" srcset="">
          </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合并企业溯源 -->
    <div class="merge">
      <div class="traceability-information">
        <TraceabilityInformation
          :traceabilityInformation="traceabilityInformation"/>
      </div>
      <div class="company-introduction">
        <CompanyIntroduction
          :companyIntroduction="companyIntroduction"/>
      </div>
    </div>
    <!-- 问题反馈 -->
    <div class="feedback">
      <FeedBack/>
    </div>
  </div>
</template>

<script>
import TraceabilityInformation from "../TraceabilityInformation";
import CompanyIntroduction from "../CompanyIntroduction";
import FeedBack from "../FeedBack";
export default {
    name:"product-information",
    components:{
      TraceabilityInformation,
      CompanyIntroduction,
      FeedBack
    },
    props:["productInformation","traceabilityInformation","companyIntroduction"],
    methods:{
      async feedback(){
        setTimeout(()=>{
          alert("提交成功")
        },500)
      }
    }
}
</script>
<style scoped>
#product-information{
  width: 100%;
  height: 100%;
  background: #f7f7f7;
}
.carousel{
  width: 100%;
  height: 23vh;
  overflow: hidden;
  background-color: rgb(250, 250, 250);
  margin-top: 1.2vh;
}
.carousel img{
  width: 100%;
  height: 100%;
  object-fit:contain
}
.product-title-verify-code{
  width: 100%;
  height: 10.2vh;
  padding: 0 5vw;
  padding-top: 0.5vh;
  background: white;
}
.verify{
  display: flex;
  width: 100%;
  vertical-align: middle;
  align-items: flex-start;
  line-height: 3vh;
  margin-top: 0.2vh;
}
.scan-num{
  width: 23vw;
}
.scan-num span{
  color: #F24E1D;
  background: #FFD9CD;
  padding: 0 1vw;
  font-size: 1.6vw;
  font-weight: bold;
  border-radius: 1px;
}
.approval-status{
  flex: 4;
}
.approval-status span{
  padding: 0 1vw;
  font-size: 1.6vw;
  font-weight: bold;
  color: green;
  background: #DDFFEA;
  border-radius: 1px;
}
.product-title{
  margin-top: 0.5vh;
}
.product-code{
  margin-top: 3px;
  font-size: 2.3vw;
  font-weight: 400;
  padding-top: 0.2vh;
  margin-top: 1vh;
}
.product-code > span:nth-child(2){
  color: green;
}
/* 产品参数 */
.product-parameter{
  width: 100%;
  height: 100%;
  padding: 0 5vw;
}

/* 产品基础信息 */
.basic-product-info{
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  margin-top: 2vh;
}
.product-basic-information-header{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 4vh;
  line-height: 4vh;
  background: #009966;
  font-size: 3vw;
  margin-top: 1.5vh;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.product-basic-information-icon{
  margin-left: 15px;
  margin-right: 10px;
}
.iconfont{
  font-family:"iconfont" !important;
  font-size:3vw;font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  color: #DDFFEA;
}
.product-basic-information-title{
  color: #DDFFEA;
}
.product-basic-information-table-content{
  background: white;
  padding: 0 5vw;
}

.product-basic-information-table-column{
  margin-top: 10px;
  font-size: 3vw;
  line-height: 3vh;
}
.product-basic-information-table-column:nth-child(1){
  margin-top: 0;
  padding-top: 10px;
}
.product-basic-information-table-column > .name{
  margin-right: 10px;
  font-weight: 400;
  font-size: 3.8vw;
}
.certification-information{
  width: 100%;
  display: flex;
}
.certification-name{
  flex: 1;
}
.certification-content{
  flex: 3;
}
.certification-content > img{
  width: 100%;
}
.merge{
  width: 100%;
  height: 100%;
}
.feedback{
  width: 100%;
  height: 25vh;
  padding: 0 5vw;
  padding-bottom: 8vh;
  margin-top: 2vh;
}
.overflow{
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
</style>
