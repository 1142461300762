<template>
  <!-- 数据展示表格 -->
  <div class="complaintTable">
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      height="100%"
      style="width: 100%"
      @row-click="checkOne">
      <el-table-column
        prop="user_id"
        label="用户ID">
      </el-table-column>
      <el-table-column
        prop="products_type_name"
        label="产品名称">
      </el-table-column>
      <el-table-column
        prop="products_type_id"
        label="产品名称ID">
      </el-table-column>
      <el-table-column
        prop="batch"
        label="产品批次">
      </el-table-column>
      <el-table-column
        prop="products_num"
        label="注册数量">
      </el-table-column>
    </el-table>
    <div>
      <complaint-dialog :dialogVisible="dialogVisible" :detailsData="detailsData" ref="complaintDialog" v-if="detailsData != {}">
      </complaint-dialog>
    </div>
  </div>
</template>

<script>
import ComplaintDialog from './pc-complaint-table-dialog'

export default {
  components: {
    ComplaintDialog
  },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      detailsData: {}
    }
  },
  beforeMount() {
    this.queryData(1,null,null)
  },
  beforeDestroy () {
    sessionStorage.removeItem("temDataObject");
    sessionStorage.removeItem("temNumber");
  },
  methods: {
    /**
     * 查询数据
     */
    queryData(page,products_type_name,user_id) {
      this.$http({
        url: this.Configs.adminUrl2 + '/api/v2/admins/wait/check/products',
        methods: 'GET',
        params:{
          page: page,
          number: 10,
          products_status: 1,
          products_type_name: products_type_name,
          user_id: user_id
        }
      }).then(res => {
        if(res.data.code === 200){
          this.$parent.page = res.data.data.currentpage
          this.$parent.total = res.data.data.total
          this.$parent.pages = res.data.data.totalpage
          this.tableData = res.data.data.data
        }else{
          this.$message.error(res.data.msg)
          this.tableData = []
          this.$parent.page = 0
          this.$parent.pages = 0
        }
      }).catch(() => {
        this.$message.error('网络错误')
      })
    },

    /**
     * 查看单项详情
     */
    checkOne(val) {
      this.$http({
        url: this.Configs.adminUrl2 + '/api/v2/admins/see/check/products',
        method: 'POST',
        data:{
          merchant_user_id: val.user_id,
          products_type_id: val.products_type_id,
          batch: val.batch
        }
      }).then(res => {
        if(res.data.code === 200){
          this.detailsData = val
          // 将数据存储到预览界面可以接收到的位置
          sessionStorage.removeItem("templateData");
          sessionStorage.setItem('templateData', JSON.stringify(res.data.data.data));
          // 弹窗的显示状态
          this.dialogVisible = true
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误')
      })
    }
  }
}
</script>

<style scoped>
.complaintTable {
  width: 100%;
  height: 100%;
}
.el-table >>> .cell {
  text-align: center;
  height: 3vh;
}
.el-table >>> .el-table__header-wrapper .cell {
  color: black;
}
</style>