<!--
 * @Description: 
 * @Version: 1.0
 * @Author: AndBridge
 * @LastEditors: AndBridge
-->
<template>
      <div id="feedback">
      <div class="feedback-header">
        <div class="feedback-icon">
          <i class="iconfont">&#xe62f;</i>
        </div>
        <div class="feedback-title">
          <h2>问题反馈</h2>
        </div>
        <div  class="feedback-icon-button">
          <i @click="feedback" class="iconfont confirm-feedback-icon">&#xe63b;</i>
        </div>
      </div>
      <!-- 反馈内容 -->
      <div class="feedback-content">
          <div class="feedback-textarea">
            <textarea></textarea>
          </div>
      </div>
    </div>
</template>

<script>
export default {
  methods:{
    feedback(){
      alert("提交成功")
    }
  }
}
</script>

<style scoped>
#feedback{
  width: 100%;
  height: 100%;
}
.feedback-header{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 4vh;
  line-height: 4vh;
  background: #F24E1D;
  font-size: 3vw;
  margin-top: 1.5vh;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.feedback-icon{
  margin-left: 15px;
  margin-right: 10px;
}
.feedback-icon-button{
  position: absolute;
  width: 10vw;
  height: 100%;
  text-align: center;
  right: 0;
}
.confirm-feedback-icon{
  font-size: 4vw;
}
.feedback-icon-button:active{
  background: #009966;
  border-top-right-radius: 5px;
}
.feedback-title{
  color: #DDFFEA;
}
.feedback-content{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.feedback-content{
  display: flex;
}
.feedback-textarea{
  flex: 5;
}
.feedback-textarea > textarea{
    width: 100%;
    height: 100%;
    border-top: 0;
}

</style>
