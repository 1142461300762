export default{
    wb_changeMianBaoXie(state,data){
        state.wb_mianBaoXieArray=data
    },

    // 修改模板内容
    changeTemplateData(state,data){
        state.templateData=data
    },

    // 重置模板内容
    resetTemplateData(state,data){
        state.templateData=data
    },

    // 修改预览状态
    changeIsPreview(state,data){
        state.isPreview=data
    },
}