<template>
  <!-- 投诉建议 -->
  <div class="Complaint">
    <div class="header">
      <div class="headerLeft">
        <el-input 
        class="idSearch" 
        prefix-icon="el-icon-search" 
        placeholder="请输入用户ID"
        v-model="searchID" 
        @keyup.native.enter="toSearch">
        </el-input>
        <el-input 
        class="titleSearch" 
        prefix-icon="el-icon-search" 
        placeholder="请输入产品名称" 
        v-model="searchTitle"
        @keyup.native.enter="toSearch">
        </el-input>
      </div>
      <div class="headerCenter">
        <span>状态：</span>
        <el-select v-model="selectStatus" @change="changeStatus">
          <el-option
            v-for="item in selectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- 表格信息 -->
    <div class="bodyer">
      <complaint-table ref="complaintTable"></complaint-table>
    </div>
    <div class="footer">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="number"
          :total="total"
          :current-page="page"
          style="text-align: center; margin-top: 3rem;"
          @current-change="pageChioce">
        </el-pagination>
        <span class="footerSpan">每页显示{{number}}条数据共{{pages}}页</span>
    </div>
  </div>
</template>
<script>
import ComplaintTable from './modules/pc-complaint-table'

export default {
  components: {
    ComplaintTable
  },
  data() {
    return {
      searchTitle: '',
      searchID: '',
      selectOptions: [{
        value: 99,
        label: '全部'
      }, {
        value: 2,
        label: '审核通过'
      }, {
        value: 3,
        label: '审核不通过'
      }, {
        value: 4,
        label: '产品冻结'
      }],
      selectStatus: 99,
      page: 1,
      number: 10,
      total: 0,
      pages: 1,
      dialogVisible: false,
    }
  },
  methods: {

    /**
     * 换页
     */
    pageChioce(page) {
      if(page != -1){
        this.page = page
      }
      this.$refs.complaintTable.queryData(this.page,this.selectStatus,this.searchTitle,this.searchID)
    },

    /**
     * 根据标题筛选
     */
    toSearch () {
      this.$refs.complaintTable.queryData(1,this.selectStatus,this.searchTitle,this.searchID)
    },

    /**
     * 状态筛选
     */
    changeStatus() {
      this.$refs.complaintTable.queryData(1,this.selectStatus,this.searchTitle,this.searchID)
    },
  }
}
</script>


<style scoped>
.Complaint {
  width: 100%;
  height: 100%;
  position: relative;
}
.bodyer {
  height: calc(100% - 18rem);
}
.header {
  height: 8rem;
  width: 100%;
  border-bottom: 2px solid #555;
}
.header .headerLeft {
  width: 30%;
  height: 100%;
  line-height: 8rem;
  float: left;
  font-size: 2.5rem;
  padding-left: 2.5rem;
}
.header .headerLeft .titleSearch {
  width: 45%;
  margin-left: 2rem;
}
.header .headerLeft .idSearch {
  width: 45%;
}
.header .headerCenter {
  width: 50%;
  height: 100%;
  line-height: 8rem;
  float: left;
  font-size: 2rem;
  padding-left: 2.5rem;
}
.byTemplate {
  display:table-cell;
  vertical-align:middle;
  text-align: right;
  width: 10rem;
}
.byTemplate span, .byIdentification span {
  font-size: 2rem;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}
.byTemplate span {
  padding-left: 25px;
  background-color: white;
}
.footer {
  position: absolute;
  bottom: 0;
  height: 10rem;
  width: 100%;
}
.footer .footerSpan {
  position: absolute;
  top: 3.5rem;
  right: 3rem;
  color: #777;
  font-size: 1.6rem;
  letter-spacing: 2px;
}
.el-dialog__wrapper >>> .el-dialog__header {
  padding: 0 !important;
}
</style>