<template>
  <!-- 子菜单 -->
  <el-menu
  @select="handleSelect"
  :default-active="defaultActive"
  class="el-menu-demo"
  :router="true"
  >
    <el-menu-item :class="!isShowA ? 'menuItemB' : 'menuItemA'" index="/pc/bu/suc/notification/unread">
      <i :class="!isShowA ? 'iconfont iconweidu_01' : 'iconfont iconweidu_02'"></i>
      <h1>未读</h1>
    </el-menu-item>
  <el-menu-item :class="!isShowB ? 'menuItemB' : 'menuItemA'" index="/pc/bu/suc/notification/read">
    <i :class="!isShowB ? 'iconfont iconyidu_01' : 'iconfont iconyidu_02'"></i>
    <h1>已读</h1>
  </el-menu-item>
  <el-menu-item :class="!isShowC ? 'menuItemB' : 'menuItemA'" index="/pc/bu/suc/notification/collection">
    <i :class="!isShowC ? 'iconfont iconshoucang_01' : 'iconfont iconshoucang_02'"></i>
    <h1>收藏</h1>
  </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  data () {
    return {
      isShowA: false,
      isShowB: false,
      isShowC: false,
      defaultActive: '/pc/bu/suc/notification/unread'
    }
  },
  methods: {
    /**
     * 菜单栏选中事件
     */
    handleSelect(key) {
      switch (key) {
        case "/pc/bu/suc/notification/unread":
          this.isShowFunc();
          this.isShowA=true;
          break;
        case "/pc/bu/suc/notification/read":
          this.isShowFunc();
          this.isShowB=true;
          break;
        case "/pc/bu/suc/notification/collection":
          this.isShowFunc();
          this.isShowC=true;
          break;
        default:
          this.isShowFunc();
          this.isShowA=true;
          break;
      }
    },

    /**
     * 清空选中
     */
    isShowFunc(){
        this.isShowA=false;
        this.isShowB=false;
        this.isShowC=false;
    }
  },
  beforeMount(){
    // 用于刷新后保持菜单选中状态不变
    // 获取当前路由地址
    this.defaultActive=this.$route.path;
    this.handleSelect(this.defaultActive);
  }
}
</script>


<style scoped>
/* 图标大小 */
.iconfont{
  font-size: 8rem;
}
/* 鼠标悬浮效果 */
.el-menu-demo li:first-child{
  border-bottom: 1px solid #555;
}
.el-menu-demo li:last-child{
  border-top: 1px solid #555;
}
.el-menu-demo li:hover {
  background-color: white;
}
.el-menu-demo li:hover i {
  color: #009966;
}
.el-menu-demo li:hover h1 {
  color: #009966;
}
/* 选中效果 */
.menuItemA {
  padding-top: 8vh;
  height: 25vh;
  text-align: center;
  border-right: 0px;
}
.menuItemB {
  padding-top: 8vh;
  height: 25vh;
  text-align: center;
  border-right: 1px solid #555;
  background-color: #F3F3F3;
}
.menuItemA h1 {
  color: #009966;
}
.menuItemB h1 {
  color: #909399;
}
/* 去默认边框 */
.el-menu {
  border-right: 0px;
}
/* 更改默认颜色 */
.el-menu >>> .is-active {
  color: #009966 !important;
  background-color: white;
}
</style>