<template>
  <!-- 数据展示表格 -->
  <div class="complaintTable">
    <el-table
      ref="multipleTable"
      v-if="tableData"
      :data="tableData"
      tooltip-effect="dark"
      height="100%"
      style="width: 100%">
      <el-table-column
        fixed
        type="selection">
      </el-table-column>
      
      <slot name="products_type_id"></slot>
      <slot name="products_type_name"></slot>
      <slot name="batch"></slot>
      <slot name="products_num"></slot>
      
      <el-table-column
        label="状态"
        width="200px">
        <template slot-scope="scope">
            <el-tag
            v-if="scope.row.products_status === 0"
            type="success"
            disable-transitions>未审核</el-tag>
            <el-tag
            v-if="scope.row.products_status === 1"
            type="warning"
            disable-transitions>待审核</el-tag>
            <el-tag
            v-if="scope.row.products_status === 2"
            type="primary"
            disable-transitions>审核通过</el-tag>
            <el-tag
            v-if="scope.row.products_status === 3"
            type="danger"
            disable-transitions>审核不通过</el-tag>
            <el-tag
            v-if="scope.row.products_status === 4"
            type="info"
            disable-transitions>产品冻结</el-tag>
        </template>
      </el-table-column>

      <el-table-column
        v-if="products_status === 0"
        label="操作">
        <template slot-scope="scope">
          <el-button @click="toSubmit(scope.row)" type="text" size="small">提交审核</el-button>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      tableData: [],
      detailsData: {},
      products_status: 2,
      products_type_name: ''
    }
  },
  beforeMount() {
    this.queryData(1,this.products_status,this.products_type_name)
  },
  methods: {
    /**
     * 查询数据
     */
    queryData(page,products_status,products_type_name) {
      this.$http({
        url: this.Configs.adminUrl2 + '/api/v2/products/my/check/products',
        methods: 'GET',
        params:{
          page: page,
          number: 10,
          products_status: products_status,
          products_type_name: products_type_name
        }
      }).then(res => {
        if(res.data.code === 200){
          this.products_status = products_status
          this.products_type_name = products_type_name
          this.$parent.page = res.data.data.currentpage
          this.$parent.total = res.data.data.total
          this.$parent.pages = res.data.data.totalpage
          this.tableData = res.data.data.data
        }else{
          this.$message.error(res.data.msg)
          this.tableData = []
          this.$parent.page = 0
          this.$parent.pages = 0
        }
      }).catch(() => {
        this.$message.error('网络错误')
      })
    },

    /**
     * 提交审核
     */
    toSubmit (val) {
      this.$http({
        url: this.Configs.adminUrl2 + '/api/v2/products/submit/products/check',
        method: 'GET',
        params: {
          products_type_id: val.products_type_id,
          batch: val.batch
        }
      }).then(res => {
        if(res.data.code === 200){
          this.$message.success(res.data.msg)
          this.queryData(1,this.products_status,this.products_type_name)
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
          this.$message.error('网络错误')
      })
    },

    
    
    /**
     * 生成防伪码
     */
    async toGenerate() {
      //获取已选中的数据
      let isSelect = document.getElementsByClassName('el-checkbox__original')
      let id_list = []
      let batch_list = []
      await isSelect.forEach((element,index) => {
        if(index != 0 && index < isSelect.length/2){
          if(element.checked == true){
            id_list.push(this.tableData[index-1].products_type_id);
            batch_list.push(this.tableData[index-1].batch);
          }
        }
      });
      // 生成请求
      if(id_list.length === 1){
        this.$http({
          url: this.Configs.adminUrl1 + '/api/v1/products/generate/qrcode',
          method: 'GET',
          params:{
            products_type_id: id_list[0],
            batch: batch_list[0]
          }
        }).then(res => {
          if(res.data.code === 200){
            let a = document.createElement('a');
            this.$http.get(this.Configs.adminUrl1 + '/api/v1?downloadUrl=' + res.data.data,{
              responseType: 'blob',// 不加type值下载文件后会出现乱码
            }).then(res=>{
              let blob = new Blob([res.data])
              let objectUrl = URL.createObjectURL(blob) // 创建URL
              a.href = objectUrl;
              a.download = 'data.zip'; // 自定义文件名
              a.click() // 下载文件
              URL.revokeObjectURL(objectUrl); // 释放内存
            })
          }else{
            this.$message.error(res.data.msg)
          }
        }).catch(() => {
          this.$message.error('网络错误');
        })
      }else if(id_list.length === 0){
        this.$message.error('请先选择后生成')
      }else{
        this.$message.error('每次只能选择一项生成')
      }
      
    },
  }
}
</script>

<style scoped>
.complaintTable {
  width: 100%;
  height: 100%;
}
.el-table >>> .cell {
  text-align: center;
  height: 3vh;
}
.el-table >>> .el-table__header-wrapper .cell {
  color: black;
}
</style>