<template>
  <div id="MBLogin">
    <!-- 背景图片 -->
    <img src="../../assect/bg-image/mb-login.png" id="background">
    <!-- 返回按钮 -->
    <div class="logo-back" @click="backClick">
      返回
    </div>
    <!-- 标题 -->
      <div class="logoTest">
        <div class="home-text">
          <div>农产品</div>
          <div>质量追溯</div>
          <div>管理平台</div>
        </div>
      </div>
      <!-- 登录 -->
      <div class="login-input">
        <div class="login-user-input">
          <input type="text" placeholder="用户名" v-model="username">
        </div>
        <div class="login-password-input">
          <input type="password" placeholder="密码" v-model="password">
        </div>
        <div class="login-forget">
          <div class="forget-password">
            ？忘记密码
          </div>
        </div>
        <div class="login-icon" @click="toLogin">
          <div>登录</div>
        </div>
        <div class="login-register">
          还没有账号？
        </div>
        <div class="register" @click="registerClick">
          注册
        </div>
      </div>
      
  </div>
</template>

<script>
import { sha256 } from 'js-sha256'
export default {
  name:'MBlogin',
  data(){
    return {
      username:'',
      password:''
    }
  },
  methods:{
    toLogin () {
      // 获取 yyyy-mm-dd 格式的日期参数
      let date = new Date()
      let nowMonth = (date.getMonth()+1) >= 10 ? (date.getMonth()+1) : '0' + (date.getMonth()+1)
      let nowDay = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
      let nowDate = date.getFullYear() + '-' + nowMonth + '-' + nowDay
      //发送请求
      this.$http({
        url: '/api/v1/user/login',
        method: 'POST',
        data: {
          user_id: this.username,
          password: sha256(sha256(this.password) + nowDate)
        }
      }).then(res => {
        if(res.data.code === 200){
          this.$toast.show(res.data.msg);
          //设置token
          sessionStorage.setItem('token', res.data.data.token);
          sessionStorage.setItem('role', res.data.data.role);
          this.$http.defaults.headers.common['Authorization'] = res.data.data.token;
          //页面跳转
          setTimeout(() => {
            if(res.data.data.role == '2'){
              this.$router.push('/pc/bu/suc');
            }else if(res.data.data.role == '0'){
              this.$router.push('/pc/adminhome');
            }else if(res.data.data.role == '3'){
              this.$router.push('/mb/index');
            }else{
              this.$toast.show('其他页面');
            }
          },200);
        }else{
          this.$toast.show(res.data.msg);
        }
      }).catch(() => {
        this.$toast.show('网络错误');
      })
    },
    registerClick(){
      this.$router.push({path:'/mb/register'})
    },
    backClick(){
      this.$router.push('/mb/index')
    }
  }
}
</script>

<style scoped>
#MBLogin{
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
#background {
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
}
.logo-back{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  font-size: 8rem;
  font-weight: bold;
  color: #fff;
}
.logoTest{
    flex: 1;
    display: flex;
    flex-direction:column;
    align-items: center;
    width: 50%;
    margin-left: 50%;
    margin-top: 20%;
    transform: translate(-50%);
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #fff;
}
.login-input{
  flex: 2;
  position: relative;
  width: 80%;
  height: 80%;
  margin-left: 50%;
  margin-top: 20%;
  transform: translate(-50%);
  z-index: 9;
}
.login-user-input{
  width: 100%;
  height: 8%;
  background-color: rgba(255, 255, 255, .3);
  border-radius: 8rem;
  border: 1px solid #fff;
}
.login-user-input input{
  width: 95%;
  height: 100%;
  margin-left: 5%;
  outline: none;
  font-size: 8rem;
  background-color: transparent;
}
input::-webkit-input-placeholder{
  color: #fff;
}
.login-password-input{
  width: 100%;
  height: 8%;
  border-radius: 8rem;
  margin-top: 3%;
  background-color: rgba(255, 255, 255, .3);
  border: 1px solid #fff;
}
.login-password-input input{
  width: 95%;
  height: 100%;
  margin-left: 5%;
  outline: none;
  font-size: 8rem;
  background-color: transparent;
}
.login-forget{
  position: relative;
  margin-top: 4%;
}
.forget-password{
  position: absolute;
  right: 0;
  font-size: 7rem;
  color: #fff;
}
.login-icon{
  position: relative;
  width: 100%;
  height: 8%;
  margin-top: 16%;
  font-size: 8rem;
  color: #fff;
  border: 1px solid #009966;
  border-radius: 8rem;
  background-color: #009966;
}
.login-icon div{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% ,-50%);
}
.login-register{
  text-align: center;
  margin-top: 5%;
  color: #fff;
  font-size: 7rem;
}
.register{
  text-align: center;
  color: #fff;
   font-size: 8rem;
   margin-top: 2%;
   font-weight: bold;
}
</style>