/* 引入组件 */
import AdminHome from '../views/admin/AdminHome/Index.vue';
// App
import SendNotice from '../views/admin/SendNotice/Index.vue';
import TouSuJianYi from '@/views/admin/TouSuJianYi/Index.vue';
import ShangJiaGuanLi from '../views/admin/ShangJiaGuanLi/Index.vue';
import KeHuGuanLi from '../views/admin/KeHuGuanLi/Index.vue';
import MoBanKu from '../views/admin/MoBanKu/Index.vue';
import YuSheMoBan from '../views/admin/YuSheMoBan/Index.vue';
import DaiShenHe from '../views/admin/DaiShenHe/Index.vue';
import YiShenHe from '../views/admin/YiShenHe/Index.vue';

// SentNotice
import SentNotice from '../views/admin/SentNotice/Index.vue';
// SendNotice
import XiaoFeiZhe from '../views/admin/SendNotice/views/XiaoFeiZhe/Index.vue';
import ShangJiaQunFa from '../views/admin/SendNotice/views/ShangJiaQunFa/Index.vue';
import ZhiDingDuiXiang from '../views/admin/SendNotice/views/ZhiDingDuiXiang/Index.vue';

// ShangJiaGuanLi
import ZiLiaoShengCha from '../views/admin/ShangJiaGuanLi/views/ZiLiaoShengCha/Index.vue'; 
// import QianZhuiMing from '../views/admin/ShangJiaGuanLi/views/QianZhuiMing/Index.vue'; 前缀名暂时注释
import ShangJiaZhangHao from '../views/admin/ShangJiaGuanLi/views/ShangJiaZhangHao/Index.vue';
import RealShangJiaGuanLi from '../views/admin/RealShangJiaGuanLi/Index.vue';
/* 放入组件 */
const routesJq=[
    {
        path:"/pc/adminhome",component:AdminHome,
        redirect:"/pc/adminhome/sendnotice",
        children:[
            {path:"/pc/adminhome/sendnotice/sentnotice",component:SentNotice},
            {path:"/pc/adminhome/sendnotice",
            component:SendNotice,
            redirect:"/pc/adminhome/sendnotice/sentnotice",
            // sendnotice
            children:[
                {path:"/pc/adminhome/sendnotice/xiaofeizhe",component:XiaoFeiZhe},
                {path:"/pc/adminhome/sendnotice/shangjiaqunfa",component:ShangJiaQunFa},
                {path:"/pc/adminhome/sendnotice/zhidingduixiang",component:ZhiDingDuiXiang}
            ]
            },
            {path:"/pc/adminhome/tousujianyi",component:TouSuJianYi},
            {path:"/pc/adminhome/shangjiaguanli",
            component:ShangJiaGuanLi,
            redirect: "/pc/adminhome/shangjiaguanli/ziliaoshengcha",

            // shangjiaguanli
            children:[
                {path:"/pc/adminhome/shangjiaguanli/ziliaoshengcha",component:ZiLiaoShengCha},
                // {path:"/pc/adminhome/shangjiaguanli/qianzhuiming",component:QianZhuiMing}, 暂时注释 
                {path:"/pc/adminhome/shangjiaguanli/shangjiazhanghao",component:ShangJiaZhangHao}
            ]
            },
            {path:"/pc/adminhome/kehuguanli",component:KeHuGuanLi},
            {path:"/pc/adminhome/realshangjiaguanli",component:RealShangJiaGuanLi},
            {path:"/pc/adminhome/mobanku",component:MoBanKu},
            {path:"/pc/adminhome/yushemoban",component:YuSheMoBan},
            {path:"/pc/adminhome/daishenhe",component:DaiShenHe},
            {path:"/pc/adminhome/yishenhe",component:YiShenHe}
        ]
    }
    
];

export default routesJq;