<template>
  <div id="tab-bar">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TabBar",
};
</script>

<style>
#tab-bar {
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0px -.1rem .1rem rgba(100, 100, 100, 0.8);
  background-color: #f6f6f6;
  z-index: 99;
}
</style>