<template>
  <div class="three-choice">
       <el-menu
       :router="true"
      :default-active="defaultActive"
      class="el-menu-vertical-demo">
        <el-menu-item v-for="(item,i) in choiceArray" :key="i" :index="item.url">
        <slot :name="item.slotName">
        </slot>
        </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  props:["choiceArray"],
    data(){
        return{
            defaultActive:this.$route.path
        }
    },
}
</script>

<style scoped>
.three-choice{
    width: 100%;
    height: 100%;
}
.el-menu{
  width: 100%;
  height: 100%;
  border: 0;
}
.el-menu >>> .el-menu-item{
  width: 100%;
  /* height: 33.33333333333%; */
  height: 46%;
  text-align: center;
  color: #d7d7d7;
  /* padding-top: 7vh; */
  padding-top: 12vh;
  border: 1px solid #d7d7dd;
  margin-top: 3rem;
}

.el-menu >>> .el-menu-item.is-active{
  color: #009966;
  background-color: white;
  border-right: none;
}
.el-menu >>> .el-menu-item:hover{
  color: #009966;
  background-color: white;
}
.el-menu >>> .el-menu-item:hover i{
  color: #009966;
  background-color: white;
}
</style>