/*
 * @Description: 
 * @Version: 1.0
 * @Author: AndBridge
 * @LastEditors: AndBridge
 */
/* 引入vue-router */
import VueRouter from 'vue-router';

/* 引入路由模块 */
// 1.引入Jq模块

import routesJq from './routesJq';
// 2.引入Fjh模块
import mobileRoutes from './mobileRoutes';
// 3.引入Mx模块
import routesMx from './routesMx';



/* 分发引入模块 */

// both模块路由
import PcLoginRegister from '../views/both/PcLoginRegister/Index.vue';
// 数据展示
import Ncpsjzs from '../views/both/NcpSjzs/Index.vue';
//新增页面
import EnterHome from '../views/both/PcEmterHome/Index'
const routes=[
    {
        path:"/pc/loading",
        component:PcLoginRegister
    },
    {
        path:"/",
        component:EnterHome,
    },
    {
        path:"/ncpsjzs",
        component:Ncpsjzs
    }
];
// Jq的路由
    routesJq.forEach((element)=>{
        routes.push(element
    )});
// Mx的路由
routesMx.forEach((element)=>{
    routes.push(element)
});
// 移动端
mobileRoutes.forEach((element)=>{
    routes.push(element)
});

/* 创建路由对象 */
const router=new VueRouter({
    routes
})

export default router;
