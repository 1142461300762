<template>
  <div class="content me">
    <div class="title-label-btn">
      <div class="label"><label>客户信息</label></div>
      
      <!-- 按钮 -->
      <div class="btn"><el-button>添加</el-button></div>
      
    </div>
    <!-- 列表 -->
    <div class="this-table">
      <pc-xiao-feizhe-table :headArray="headArray" :tableData="tableData">
        <template #cao-zuo>
        <el-table-column
        class-name="col-style"
          label="操作">
          <template slot-scope="scope">
            <el-button @click="dongjie(scope.row)" type="text" size="small">冻结</el-button>
            <el-button @click="jiedong(scope.row)"  type="text" size="small">解冻</el-button>
          </template>
        </el-table-column>
      </template>
      </pc-xiao-feizhe-table>
    </div>
  </div>
</template>

<script>
import PcXiaoFeizheTable from './modules/pc-xiao-feizhe-table.vue'
export default {
  components: { PcXiaoFeizheTable },
  data(){
    return{
      headArray:[
        {
          prop:"user_id",
          label:"用户名"
        },
        {
          prop:"nickname",
          label:"昵称"
        },
        {
          prop:"mail",
          label:"邮箱"
        },
        {
          prop:"status",
          label:"状态"
        },
         {
          prop:"time",
          label:"时间"
        }
      ],
      tableData:[]
    }
  },
  methods:{
    async getApiData(){
    try {
      let params={
        page:1,
        number:10000000,
        status:100,
        role:3
      }
      let {data:{data:{data}=[],msg,code}} = await this.$http({url:"/api/v1/user/list",method:"GET",params})
    data.forEach(element => {
      switch (element.status){
        case 0:
          element.status="审核中";
          break;
        case 1:
          element.status="未通过";
          break;
         case 2:
          element.status="已通过";
          break;
         case 3:
          element.status="已冻结";
          break;
         case 4:
          element.status="已注销";
          break;
      }
    });
    if(code==200) {
      this.tableData=data
      return;
    }
           this.$message({
            type:"success",
            message:msg
          })
       return;
        
    } catch (error) {
      console.error(error)
      throw error;
    }
  },
  async dongjie(item){
    try {
      let postData={
        user_id:item.user_id,
        status:3
      }
         let {data:{msg,code}} = await this.$http({url:"/api/v1/user/audit/account/status",method:"PUT",data:postData})
    if(code ===200){
        this.$message({
            type:"success",
            message:msg
          })
         setTimeout(()=>{
        location.reload();
        },200);
          return;
    }
    this.$message({
            type:"success",
            message:msg
          })

    } catch (error) {
      console.error(error);
      throw error;  
    }
  },
    async jiedong(item){
    try {
      let postData={
        user_id:item.user_id,
        status:2
      }
         let {data:{msg,code}} = await this.$http({url:"/api/v1/user/audit/account/status",method:"PUT",data:postData})
    if(code ===200){
        this.$message({
            type:"success",
            message:msg
          })
         setTimeout(()=>{
        location.reload();
        },200);
          return;
    }
    this.$message({
            type:"success",
            message:msg
          })

    } catch (error) {
      console.error(error);
      throw error;  
    }
  }
  },

  async beforeMount(){
    await this.getApiData();
  }
}
</script>

<style scoped>
.content{
  width: 100%;
  height: 100%;
  position: relative;
}
.me{
  border: 1px solid slategrey;
}
.title-label-btn{
  padding: 2vh 1vw 2vh 1vw;
  position: relative;
  border-bottom: 1px solid slategrey;
}
.label{
  font-size: 1.2vw;
}
.btn{
  position: absolute;
  right: 1vw;
  top: 1.5vh;
}
</style>