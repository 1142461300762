<template>
  <div class="content">
    <my-table :headArray="headArray" :tableData="tableData">
    <template #title-label>
      资料列表
    </template>
      <!-- 操作 -->
    <template #cao-zuo>
        <el-table-column
    class-name="col-style"
      label="操作">
      <template slot-scope="scope">
        <el-button @click="shenghePass(scope.row)" type="text" size="small">通过</el-button>
        <el-button @click="shengheRefuse(scope.row)" type="text" size="small">拒绝</el-button>
      </template>
    </el-table-column>
    </template>
    </my-table>
  </div>
</template>

<script>
import MyTable from '../../modules/my-table'
export default {
  components: { MyTable },
  data(){
    return{
      headArray:[{
        prop:"bus_name",
        label:"企业名称"
      },{
        prop:"bus_prefix",
        label:"企业前缀"
      },{
        prop:"status",
        label:"申请状态"
      },{
        prop:"time",
        label:"申请时间"
      }],
      tableData:[]
    }
  },
  methods:{
    async getApiData(){
      try {
       let params = {
         page:1,
         number:100000,
         status:0
       }
     let {data:{data={},code,msg}} = await this.$http({url:"/api/v1/business/prefix/list",method:"GET",params});
    if(code==200){
      data.data.forEach(item => {
        if(item.status==0){
          item.status="正在审核中";
        }else if(item.status==1){
          item.status="审核未通过";
        }else if(item.status==2){
          item.status="审核已通过";
        }
        });
     this.tableData=data.data;
    }else{
      if(code != 404){
        this.$message({
          type:"error",
          message:msg
        })
      }
      return;
    }}
     catch (error) {
       console.error(error)
        throw error;
      }
    },
    async shenghePass(item){
      try {
        let postData={
          bus_prefix:item.bus_prefix,
          status:2
        }
        let {data:{code,msg}} = await this.$http({url:"/api/v1/business/prefix/review",method:"PUT",data:postData});
        if(code==200){
           this.$message({
            type:"success",
            message:msg
          })
           setTimeout(()=>{
          location.reload();
          },200);
          return;
        }
        this.$message({
            type:"error",
            message:msg
          })
      } catch (error) {
        console.error(error)
        throw error;
      }
    },
    async shengheRefuse(item){
       try {
        let postData={
          bus_prefix:item.bus_prefix,
          status:1
        }
        let {data:{code,msg}} = await this.$http({url:"/api/v1/business/prefix/review",method:"PUT",data:postData});
        if(code==200){
           this.$message({
            type:"success",
            message:msg
          })
         setTimeout(()=>{
        location.reload();
        },200);
          return;
        }
        this.$message({
            type:"error",
            message:msg
          })
      } catch (error) {
        console.error(error)
        throw error;
      }
    }
  },
  async beforeMount(){
    await this.getApiData();
  }
}
</script>

<style scoped>

</style>