<template>
  <div style="height: calc(100% - 3rem)">
    <slot></slot>
    <div class="checkList">
      <div>
        <span>产品名：</span>
        <el-input style="width: 18vw" v-model="products_type_name" @change="changeName" placeholder="请输入内容"></el-input>
        <el-button class="checkHave" @click="DialogVisible = true">选择已有产品</el-button>
      </div>
      <div>
        <span>产品ID：</span>
        <el-input style="width: 18vw" v-model="products_type_id" placeholder="请输入内容" disabled></el-input>
        <div class="tips"><span>*该字段系统生成，不可修改</span></div>
      </div>
    </div>
    <el-dialog
      title="选择已有产品"
      :visible.sync="DialogVisible"
      width="30%"
      top="20%"
      center>
      <div class="dialogMain">
        <div class="dialogMainLeft">
          <span>产品名称</span>
          <dialog-left :listData='DialogListDataLeft' @getInfo="getName"></dialog-left>
        </div>
        <div class="dialogMainRight">
          <span>产品名称id</span>
          <dialog-right :listData='DialogListDataRight' @getInfo="getId"></dialog-right>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="DialogVisible = false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';
import DialogLeft from './pc-registered-addview-check-dialog-list.vue'
import DialogRight from './pc-registered-addview-check-dialog-list.vue'
export default {
  components: {
    DialogLeft,
    DialogRight
  },
  data () {
    return {
      products_type_name: '',
      products_type_id: '',
      DialogVisible: false,
      DialogListDataLeft: [],
      DialogListDataRight: []
    }
  },
  beforeMount() {
    this.firstGetName()
  },
  beforeDestroy() {
    sessionStorage.removeItem("productsTypeName");
    sessionStorage.removeItem("productsTypeID");
  },
  methods: {
    ...mapMutations(['changeTemplateData']),

    /**
     * 获取商家已注册的产品名称
     */
    firstGetName () {
      this.$http({
        url: this.Configs.adminUrl1 + '/api/v1/products/search/existproductsname',
        method: 'GET'
      }).then(res => {
        if(res.data.code === 200){
          res.data.data.forEach(element => {
            this.DialogListDataLeft.push({value: element})
          });
        }
      }).catch(() => {
        this.$message.error('网络错误');
      })
    },

    /**
     * 修改产品名
     */
    changeName () {
      //产品名写入会话
      sessionStorage.removeItem("productsTypeName");
      sessionStorage.setItem('productsTypeName', this.products_type_name);
    },

    /**
     * 通过产品名称获取商家已注册的产品名称id
     */
    getName (val) {
      this.DialogListDataRight = []
      this.$http({
        url: this.Configs.adminUrl1 + '/api/v1/products/search/existproductsid',
        method: 'GET',
        params:{
          products_type_name: val
        }
      }).then(res => {
        if(res.data.code === 200){
          res.data.data.forEach(element => {
            this.DialogListDataRight.push({value: element})
          });
        }
      }).catch(() => {
          this.$message.error('网络错误');
      })
    },

    /**
     * 通过产品名称id选择模板详情
     */
    getId (val) {
      this.$http({
        url: this.Configs.adminUrl1 + '/api/v1/products/choice/existproducts',
        method: 'POST',
        data:{
          products_type_id: val
        }
      }).then(res => {
        if(res.data.code === 200){
          // 选择的结果写入页面
          this.products_type_name = res.data.data.products_type_name
          this.products_type_id = res.data.data.products_type_id

          // 数据写入vuex
          this.changeTemplateData(res.data.data.data)

          // 产品名写入会话
          sessionStorage.removeItem("productsTypeName");
          sessionStorage.setItem('productsTypeName', this.products_type_name);

          // 产品ID写入会话
          sessionStorage.removeItem("productsTypeID");
          sessionStorage.setItem('productsTypeID', this.products_type_id);
        }
      }).catch(() => {
          this.$message.error('网络错误');
      })
    }
  }
}
</script>

<style scoped>
.checkList {
  width: 100%;
  height: 100%;
}
.checkList div {
  text-align: right;
  margin-bottom: 1rem;
}
.el-select >>> .el-input__inner {
  background-color: rgb(248, 248, 248);
  border: 0px;
  border-radius: 10px;
  color: black;
}
.el-input >>> .el-input__inner {
  background-color: rgb(248, 248, 248);
  border: 0px;
  border-radius: 10px;
  color: black;
}
.tips {
  width: 10vw;
  height: 40px;
  line-height: 40px;
  padding-right: 2rem;
  color: #aaa;
  float:right;
}
.checkHave {
  width: 8vw;
  margin-left: 2vw;
  color: #009966;
  border-color: #009966;
}
.checkHave:hover {
  background-color: #e6e9ee;
  color: #555;
  border-color: #e6e9ee;
}
.dialogMain {
  height: 20vh;
}
.dialogMain span {
  font-size: 1.5vh;
}
.dialogMainLeft {
  width: 40%;
  height: 100%;
  float: left;
  text-align: center;
}
.dialogMainRight {
  width: 40%;
  height: 100%;
  float: right;
  text-align: center;
}
</style>