<template>
  <div class="toast" v-show="isShow">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: "Toast",
  data() {
    return {
      message: "",
      isShow: false,
    };
  },
  methods: {
    show(message, duration = 2000) {
      //duration = 2000默认值为2000毫秒
      this.isShow = true;
      this.message = message;
      setTimeout(() => {
        this.isShow = false;
        this.message = "";
      }, duration);
    },
  },
};
</script>

<style scoped>
.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  padding: 8rem 10rem;
  border-radius: 8rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
  font-size: 8rem;
}
</style>