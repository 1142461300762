<template>
  <el-tabs class="wh-tab" v-model="activeName">
    
    <el-tab-pane class="wh-pane" label="登录" name="first">
    <slot name="ad-login-content"></slot>
    </el-tab-pane>

    <el-tab-pane class="wh-pane" label="注册" name="second">
    <slot name="ad-register-content"></slot>
    </el-tab-pane>
    
    </el-tabs>
</template>
<script>
  export default {
    data() {
      return {
        activeName: 'first'
      };
    }
  }
</script>
<style scoped>
.wh-tab{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: white;
}
.wh-tab >>> .el-tabs__content{
  width: 100%;
  margin-top: 0.5rem;
  text-align: center;
}
.wh-pane{
  width: 100%;
  height: 100%;
  text-align: center;
}

/* el的登录注册的tab的头部大小 */
.wh-tab >>> .el-tabs__header{
  width: 100%;
  height: 8rem;
  margin: 0;
  line-height: 8rem;
}
/* el的登录注册的未选中下划线 */
.wh-tab >>> .el-tabs__nav-wrap::after{
  opacity: 0;
  width: 100%;
}
/* 登录注册盒子选中大小 */
.wh-tab >>> .el-tabs__nav{
  width: 50%;
  height: 8rem;
  text-align: center;
}

/* 激活划线的样式 */
.wh-tab >>> .el-tabs__active-bar{
  background: #009966;
  width: 60% !important;
  text-align: center;
  position: absolute;
  left: 4.5rem;
  height: 0.4rem;
}

.wh-tab >>> .el-tabs__item{
  height: 8rem;
  width: 100%;
  line-height: 8rem;
  padding: 0;
  font-size: 3rem;
  font-weight: 600;
  color: #999;
}
.wh-tab >>> .el-tabs__item.is-active{
  color: #009966;
}
.wh-tab >>>.el-tabs__item:hover{
  color: #009966;
}
</style>