import SendNotice from './SendNotice';





export default{
    modules:{
        SendNotice
    }
}