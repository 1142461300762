<template>
  <div class="content">
    <!-- 指定对象 -->
    <div class="title-input-box zhiding-box">
        <label for="duixiang">对象</label>
         <el-autocomplete
         v-model="personObject"
      class="inline-input"
      :fetch-suggestions="querySearch"
      :trigger-on-focus="false"
      id="duixiang"
    ></el-autocomplete>
    </div>
      <!-- 标题 -->
      <div class="title-input-box">
        <label for="title">标题</label>
        <input type="text" v-model="title" name="" id="title">
      </div>
      <!-- 内容 -->
      <div class="msg-input-box">
        <label for="msg">内容</label>
        <el-input id="msg"
          type="textarea"
          maxlength="1200"
          show-word-limit
          v-model="msg">
        </el-input>
      </div>
      <!-- 发送按钮 -->
      <div class="send-button">
        <el-button @click="sendMsgBtn" type="success wh-button">发送</el-button>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msg: '',
      title:"",
      personObject:"",
      dataArray:[],
      user_list:[]
    }
  },
  methods:{
    // 发送消息
      async sendMsgBtn(){
      try {
        this.user_list=this.personObject.split("--")[1];
        let {data:{code,msg}} = await this.$http({
        url:"/api/v1/notice/id/group/send",
        method:"POST",
        data:{
          title:this.title,
          message:this.msg,
          user_list:[this.user_list]
        }
      })
      
      if(code==200){
        this.$message({
        type:"success",
        message:msg
      })
      this.msg="";
      this.title="";
      this.personObject="";
      }else{
        this.$message({
        type:"error",
        message:msg
      })
      }
      } catch (error) {
         this.$message({
        type:"error",
        message:"网络错误"
      })
      console.error(error)
      }
     
  },
  // 模糊查询
  async querySearch(queryString,cb){
    try {
      let {data:{code,data:{data}=[]}} = await this.$http({
        url:"/api/v1/user/list",
        methods:"GET",
        params:{
          nickname:this.personObject,
          status:100,
          page:1,
          number:10
        }
      })
      if(code==200){
         this.dataArray=[]
        data.forEach(element => {
          this.dataArray.push({"value":element.nickname+"--"+element.user_id})
        });
          cb(this.dataArray)
      }else{
        cb([{value:"无数据"}])
      }
    } catch (error) {
      this.$message({
        type:"error",
        message:"无数据"
      })
      console.error(error)
    }
  }
  }
}
</script>

<style scoped>
.content{
  width: 100%;
  height: 100%;
}
.title-input-box{
    width: 100%;
   position: relative;
   line-height: 3.8vh;
  
}
.zhiding-box{
  margin-bottom: 2.2vh;
}
/* 指定对象输入框 */
.inline-input{
  width: 88%;
  border: 1px solid black;
}
.title-input-box label{
  font-size: 2.2rem;
  font-weight: 500;
  position: absolute;
  padding: 0;
  left: 0;
}
.title-input-box input[type="text"]{
  width: 88%;
  height: 3.8vh;
  border: 1px solid #999;
  padding: 2px 15px;
  font-size: 2rem;
  font-weight: 580;
}
.msg-input-box{
  position: relative;
  margin-top: 2.2vh;
}
.msg-input-box label{
  position: absolute;
  left: 0;
  font-size: 2.2rem;
  font-weight: 500;
}
.el-textarea{
  width: 88%;
  border: 1px solid #999;
}
.el-textarea >>> .el-textarea__inner{
  height: 45vh !important;
}
.wh-button{
  width: 8vw;
  line-height: 3vh;
  margin-top: 3.2vh;
  font-size: 2.3rem;
  font-weight: 600;
}
.el-button--success{
  width: 8vw;
  line-height: 3vh;
  margin-top: 3.2vh;
  font-size: 2.3rem;
  font-weight: 600;
  background-color: #009966;
}
.el-button--success:hover {
  background-color: #00b679;
}
.el-button--success:focus{
  background-color: #009966;
}
.el-button--success.is-active, .el-button--success:active{
  background-color: #00b679;
}
</style>