<template>
  <!-- 详情展示功能弹窗 -->
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      :show-close="false"
      top="20vh"
      :before-close="() => {this.$parent.dialogVisible = false}">
      <div class="mainBody">
        <div class="mainBodyLeft">
          <div class="mainBodyLeftHeader">
            <span>新增</span>
          </div>
          <div class="mainBodyLeftBodyer">
            <div class="comeFrom">
              <el-row>
                <el-col :span="1"></el-col>
                <el-col :span="2"><span>标题：</span></el-col>
                <el-col :span="20">
                  <el-input type="text" v-model="title"></el-input>
                </el-col>
                <el-col :span="1"></el-col>
              </el-row>
            </div>
            <div class="contentb">
              <el-row>
                <el-col :span="1"></el-col>
                <el-col :span="2"><span>内容：</span></el-col>
                <el-col :span="20">
                  <el-input type="textarea" v-model="content"></el-input>
                </el-col>
                <el-col :span="1"></el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toSend">发 送</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

export default {
  props: ['dialogVisible'],
  components:{
  },
  data () {
    return {
      title: '',
      content: ''
    }
  },
  methods: {
    /**
     * 发送投诉建议
     */
    toSend() {
      this.$http({
        url: '/api/v1/sugges',
        method: 'POST',
        data: {
          title: this.title,
          content: this.content
        }
      }).then(res => {
        if(res.data.code === 200){
          this.$message.success(res.data.msg)
          this.title = ''
          this.content = ''
          this.$parent.dialogVisible = false
          this.$parent.pageChioce(1)
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误')
      })
    }
  }
}
</script>

<style scoped>
/*弹框样式*/
.el-dialog__wrapper >>> .el-dialog__header{
  padding: 0;
}
.el-dialog__wrapper >>> .el-dialog__body{
  padding: 0;
}
.grid-content {
  text-align: center;
}
.mainBody {
  height: 55vh;
  font-size: 2rem;
}
.mainBody span{
  width: 80px;
}
.mainBody .mainBodyLeft{
  width: 100%;
  height: 100%;
  padding-left: 5rem;
}
.mainBody .mainBodyLeft .mainBodyLeftHeader{
  width: 100%;
  height: 10vh;
  line-height: 10vh;
}
.mainBody .mainBodyLeft .mainBodyLeftHeader span {
  color: #555;
  font-weight: bold;
  font-size: 3rem;
}
.mainBody .mainBodyLeft .mainBodyLeftBodyer div{
  margin-bottom: 0.5vh;
}
.el-textarea >>> .el-textarea__inner{
  height: 37vh !important;
}
button {
  background-color: #009966;
  color: white;
  border-radius: 15px;
}
button:hover {
  background-color: #e6e9ee;
  color: #555;
  border-color: #e6e9ee;
}
.el-input >>> .el-input__inner{
  border-radius: 15px;
  background-color: #eee;
}
.el-textarea >>> .el-textarea__inner{
  border-radius: 15px;
  background-color: #eee;
}
</style>
