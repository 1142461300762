<template>
   <!-- 菜单 -->
    <el-menu
    :router="true"
    @select="handleSelect"
    :default-active="$route.path.slice(0,($route.path.split('/')).length>=5?$route.path.lastIndexOf('/'):$route.path.length)"
    class="el-menu-vertical-demo"
    >
       <el-menu-item-group>
           <!-- 发送通知 -->
          <template slot="title">通知中心</template>
          <el-menu-item  index="/pc/adminhome/sendnotice">
              <i v-if="!isShowA" class="iconfont icontongzhi_01"></i>
              <i v-if="isShowA" class="iconfont icontongzhi_02"></i>
              发送通知
        </el-menu-item>
        <!-- 投诉建议 -->
          <el-menu-item index="/pc/adminhome/tousujianyi">
              <i v-if="!isShowB" class="iconfont iconjianyi_01"></i>
              <i v-if="isShowB" class="iconfont iconjianyi_02"></i>
              投诉建议
        </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
          <template slot="title">用户管理</template>
          <!-- 这里改过，是商家改成的审核管理 -->
          <el-menu-item index="/pc/adminhome/shangjiaguanli">
              <i v-if="!isShowC" class="iconfont iconshangjia_01"></i>
              <i v-if="isShowC" class="iconfont iconshangjia_02"></i>
              审核管理
            </el-menu-item>
            <!--下面这个 真正的商家管理 -->
          <el-menu-item index="/pc/adminhome/realshangjiaguanli">
              <i v-if="isShowD" class="iconfont iconkehu_02"></i>
              <i v-if="!isShowD" class="iconfont iconkehu_01"></i>
              商家管理
            </el-menu-item>
            <!-- 客户管理  -->
             <el-menu-item index="/pc/adminhome/kehuguanli">
              <i v-if="isShowD" class="iconfont iconkehu_02"></i>
              <i v-if="!isShowD" class="iconfont iconkehu_01"></i>
              客户管理
            </el-menu-item>
        </el-menu-item-group>
          <!-- <el-menu-item-group>
          <template slot="title">模板管理</template>
          <el-menu-item index="/pc/adminhome/mobanku">
              <i v-if="isShowE" class="iconfont iconmobanku_02"></i>
              <i v-if="!isShowE" class="iconfont iconmobanku_01"></i>
              模板 库</el-menu-item>
          <el-menu-item index="/pc/adminhome/yushemoban">
              <i v-if="isShowF" class="iconfont iconyushe_02"></i>
              <i v-if="!isShowF" class="iconfont iconyushe_01"></i>
              预设模板
              </el-menu-item>
        </el-menu-item-group> -->
        <el-menu-item-group>
          <template slot="title">防伪管理</template>
          <el-menu-item index="/pc/adminhome/daishenhe">
              <i v-if="isShowG" class="iconfont icondaichuli_02"></i>
              <i v-if="!isShowG" class="iconfont icondaichuli_01"></i>
              待  审核
              </el-menu-item>
          <el-menu-item index="/pc/adminhome/yishenhe">
              <i v-if="isShowH" class="iconfont iconyichuli_02"></i>
              <i v-if="!isShowH" class="iconfont iconyichuli_01"></i>
              已  审核
              </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
            <template slot="title">数据展示</template>
            <el-menu-item index="/ncpsjzs">
            <i class="iconmy el-icon-s-data"></i>
              数据展示
            </el-menu-item>
        </el-menu-item-group>
    </el-menu>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
    data(){
        return{
            isShowA:false,
            isShowB:false,
            isShowC:false,
            isShowD:false,
            isShowE:false,
            isShowF:false,
            isShowG:false,
            isShowH:false
        }
    },
    methods:{
        ...mapMutations(["wb_changeMianBaoXie"]),
        handleSelect(key) {
            switch (key) {
                case "/pc/adminhome/sendnotice":
                    this.isShowFunc();
                    this.isShowA=true;
                    this.wb_changeMianBaoXie(["通知中心","发送通知"]);
                    break;
                case "/pc/adminhome/tousujianyi":
                    this.isShowFunc();
                    this.isShowB=true;
                    this.wb_changeMianBaoXie(["通知中心","投诉建议"]);
                    break;
                case "/pc/adminhome/shangjiaguanli":
                    this.isShowFunc();
                    this.isShowC=true;
                    this.wb_changeMianBaoXie(["用户管理","审核管理"]);
                    break;
                case "/pc/adminhome/realshangjiaguanli":
                    this.isShowFunc();
                    this.isShowC=true;
                    this.wb_changeMianBaoXie(["用户管理","商家管理"]);
                    break;
                case "/pc/adminhome/kehuguanli":
                    this.isShowFunc();
                    this.isShowD=true;
                    this.wb_changeMianBaoXie(["用户管理","客户管理"]);
                    break;
                case "/pc/adminhome/mobanku":
                    this.isShowFunc();
                    this.isShowE=true;
                    this.wb_changeMianBaoXie(["模板管理","模板库"]);
                    break;
                case "/pc/adminhome/yushemoban":
                    this.isShowFunc();
                    this.isShowF=true;
                    this.wb_changeMianBaoXie(["模板管理","预设模板"]);
                    break;
                case "/pc/adminhome/daishenhe":
                    this.isShowFunc();
                    this.isShowG=true;
                    this.wb_changeMianBaoXie(["防伪管理","待审核"]);
                    break;
                case "/pc/adminhome/yishenhe":
                    this.isShowFunc();
                    this.isShowH=true;
                    this.wb_changeMianBaoXie(["防伪管理","已审核"]);
                    break;    
                case "/ncpsjzs":
                    break;  
                default:
                    this.isShowFunc();
                    this.$router.push("/")
                    break;
            }
        },
        isShowFunc(){
            this.isShowA=false;
            this.isShowB=false;
            this.isShowC=false;
            this.isShowD=false;
            this.isShowE=false;
            this.isShowF=false;
            this.isShowG=false;
            this.isShowH=false;
        }
    },
    beforeMount(){
        this.handleSelect(this.$route.path.slice(0,(this.$route.path.split('/')).length>=5?this.$route.path.lastIndexOf('/'):this.$route.path.length));
    },
    mounted(){
        window.addEventListener('hashchange',()=>{
            this.handleSelect(this.$route.path.slice(0,(this.$route.path.split('/')).length>=5?this.$route.path.lastIndexOf('/'):this.$route.path.length));
        },false);
    }
}
</script>

<style lang='scss' scoped>
.el-menu-vertical-demo{
    /deep/.el-menu-item-group__title{
        width: 100%;
        padding-top: 2.5rem;
        padding-bottom: 1.5rem;
        background-color: #009966;
        font-size: 1.8rem;
        color: white;
        font-weight: 600;
    }
    /deep/.el-menu-item{
        font-size: 1.8rem;
        font-weight: 600;
        color: white;
        background-color: #009966;
        height: 4rem;
        line-height: 4rem;
        padding-left: 3.6rem !important;
        &:hover{
            background-color: white;
            color: #009966;
            i{
                color: #009966;
            }
        }
    }
    /deep/.el-menu-item.is-active{
        background-color: white;
        color: #009966;
    }
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 2rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}
.iconmy {
  font-size: 2rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}
</style>