<template>
  <div id="MBProfit">
        <wb-mb-tab-bar>
          <div slot="center">个人中心</div>
          <div slot="right">注销</div>
        </wb-mb-tab-bar>
        <div class="profitContent">
          <div @click="messageClick">通知消息</div>
          <div @click="adClick">建议反馈</div>
          <div @click="pmClick">个人信息</div>
          <div @click="changeClick">修改密码</div>
        </div>
        <div>
          <router-view></router-view>
        </div>
  </div>
</template>

<script>
import wbMbTabBar from '@/components/WB-Mb-Tabbar/wb-mb-tabBar.vue'
export default {
  name:'MBProfit',
  components: { wbMbTabBar},
  data(){
    return{
      newNumber:{
        number:0,
        name:''
      },
    }
  },
  methods:{
    messageClick(){
      this.$router.push('profit/message')
    },
    adClick(){
      this.$router.push('profit/advice')
    },
    pmClick(){
       this.$router.push('profit/pm')
    },
    changeClick(){
      this.$router.push('profit/change')
    }
  }
}
</script>

<style>
.profitContent{
  margin-top: 20rem;
  overflow-y: auto !important;
}
.profitContent>div{
  padding: 4rem;
  font-size: 7rem;
  
}
.profitContent>div:hover{
  background-color: blue;
}
</style>