<template>
  <!-- 注册信息 -->
  <div class="Registered">
    <div class="header">
      <div class="headerLeft">
        <span>已注册信息</span>
      </div>
      <div class="headerRight">
        <el-button @click="addOne">新增</el-button>
      </div>
    </div>
    <div class="bodyer">
      <registered-table ref="registeredTable"></registered-table>
      <registered-table-dialog :DialogVisible="DialogVisible" ref="registeredTableDialog"></registered-table-dialog>
    </div>
  </div>
</template>
<script>
import RegisteredTable from './modules/pc-registered-table'
import RegisteredTableDialog from './modules/pc-registered-table-dialog'

export default {
  components: {
    RegisteredTable,
    RegisteredTableDialog
  },
  data() {
    return {
      isbyTemplate: true,
      DialogVisible: false,
      products_type_id: null,
      batch: null
      // query: ''
    }
  },
  methods: {
    /**
     * 切换查询方式
     */
    changeby () {
      this.isbyTemplate = ! this.isbyTemplate
    },
    
    /**
     * 添加弹窗显示
     */
    addOne () {
      this.$router.push('/pc/bu/suc/registered/add');
    },

    /**
     * 全选
     */
    allSelect () {
      this.$refs.registeredTable.allSelect();
    },

    /**
     * 删除选中
     */
    delSelect () {
      this.$refs.registeredTable.delSelect();
    },

    queryOnePage (page) {
      this.$refs.registeredTableDialog.queryOnePage(page);
    }
  }
}
</script>


<style scoped>
.Registered {
  width: 100%;
  height: 100%;
  position: relative;
}
.bodyer {
  height: calc(100% - 14rem);
}
.header {
  height: 8rem;
  width: 100%;
  border-bottom: 2px solid #555;
}
.header .headerLeft {
  width: 50%;
  height: 100%;
  line-height: 8rem;
  float: left;
  font-size: 2.5rem;
  padding-left: 2.5rem;
}
.header .headerRight {
  width: 50%;
  height: 100%;
  line-height: 8rem;
  float: left;
  text-align: right;
  padding-right: 2rem;
}
.byTemplate {
  display:table-cell;
  vertical-align:middle;
  text-align: right;
  width: 20%;
}
.byTemplate span, .byIdentification span {
  font-size: 2rem;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
}
.byTemplate span {
  padding-left: 25%;
  background-color: white;
}
.footer {
  position: absolute;
  bottom: 0;
  height: 6rem;
  width: 100%;
}
.footer button {
  position: absolute;
  bottom: 2rem;
  right: 12rem;
  border: 1px solid #009966;
  color: #009966;
  margin: 0;
}
.footer button:hover {
  background-color: #e6e9ee;
}
.footer button:first-child {
  left: 2rem;
}
.footer button:last-child {
  right: 2rem;
}
.headerRight button {
  border: 1px solid #009966;
  color: #009966;
}
.headerRight button:hover {
  background-color: #e6e9ee;
}
</style>