<template>
  <div style="height: calc(100% - 3rem)">
    <slot></slot>
    <!--删除按钮-->
    <el-button class="deleteoption" @click="deleteNewOption('father')">删除</el-button>
    <!--产品信息的新增按钮-->
    <el-popconfirm
      v-if="informationTab == 'productInformation'"
      class="addnewoption"
      confirm-button-text='图片内容'
      cancel-button-text='文字内容'
      confirm-button-type=''
      cancel-button-type=''
      icon=''
      v-on:confirm="addNewOption('photo')"
      v-on:cancel="addNewOption('text')"
    >
      <el-button slot="reference">新增</el-button>
    </el-popconfirm>
    <!--其他项的新增按钮-->
    <el-button class="addnewoption" v-if="informationTab != 'productInformation'" @click="addNewOption('null')">新增</el-button>

    <!--选项卡-->
    <el-tabs v-model="informationTab" type="card">
      <el-tab-pane label="轮播图" name="carouselImages"></el-tab-pane>
      <el-tab-pane label="产品信息" name="productInformation"></el-tab-pane>
      <el-tab-pane label="溯源信息" name="traceabilityInformation"></el-tab-pane>
      <el-tab-pane label="企业信息" name="companyIntroduction"></el-tab-pane>
    </el-tabs>

    <!--填写表单-->
    <div class="wTable">
      <!--轮播图-->
      <div v-if="informationTab == 'carouselImages'">
        <div class="wTableOne" v-for="(item,index) in templateDataHtml.productInformation.carouselImages" :key="'productInformationRotation'+index">
          <span>{{item.name}}：</span>
          <el-input class="photoselectinput" v-model="item.content" placeholder="请通过右侧按钮选择图片" disabled></el-input>
          <el-button class="photoselectbutton" @click="showDialog(index,-1)">选择</el-button>
        </div>
      </div>
      
      <!--产品信息-->
      <div v-if="informationTab == 'productInformation'">
        <!--产品名-->
        <div class="wTableOne">
          <span>产品名：</span>
          <el-input class="textselectinputright" v-model="templateDataHtml.productInformation.productTitle" placeholder="请输入内容"></el-input>
        </div>
        <!--基本信息-->
        <div  class="newOptionDiv" v-for="(item,index) in templateDataHtml.productInformation.productBasicInformation" :key="'productInformationText'+index" >
          <div class="wTableOne" >
            <span>第{{index+1}}项标题：</span>
            <el-input class="textselectinputright" v-model="item.name" placeholder="请输入标题"></el-input>
          </div>
          <div class="wTableOne" >
            <span>第{{index+1}}项内容：</span>
            <el-input v-if="item.type == 'photo'" class="photoselectinput" v-model="item.content" placeholder="请通过右侧按钮选择图片" disabled></el-input>
            <el-button v-if="item.type == 'photo'" class="photoselectbutton" @click="showDialog(index,-1)">选择</el-button>
            <el-input v-if="item.type == 'text'"  class="textselectinputright" v-model="item.content" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </div>

      <!--溯源信息-->
      <div v-if="informationTab == 'traceabilityInformation'">
        <div v-for="(traceabilityInformationActivitie,outindex) in templateDataHtml.traceabilityInformation.traceabilityInformationActivities" :key="'traceabilityInformationOut'+outindex">
          <div class="wTableOne" style="border-top: 1px solid #555555; padding-top: 1rem;" >
              <span>追溯总标题：</span>
              <el-input class="textselectinputright" v-model="traceabilityInformationActivitie.title" placeholder="请输入追溯总标题"></el-input>
            </div>
          <div  class="newOptionDiv" v-for="(item,inindex) in traceabilityInformationActivitie.content" :key="'traceabilityInformationIn'+inindex">
            <div v-if="item.type == 'text'" class="wTableOne" >
              <span>第{{inindex+1}}项标题：</span>
              <el-input class="textselectinputright" v-model="item.name" placeholder="请输入标题"></el-input>
            </div>
            <div class="wTableOne" >
              <span>第{{inindex+1}}项内容：</span>
              <el-input v-if="item.type == 'photo'" class="photoselectinput" v-model="item.content" placeholder="请通过右侧按钮选择图片" disabled></el-input>
              <el-button v-if="item.type == 'photo'" class="photoselectbutton" @click="showDialog(inindex,outindex)">选择</el-button>
              <el-input v-if="item.type == 'text'"  class="textselectinputright" v-model="item.content" placeholder="请输入内容"></el-input>
            </div>
          </div>
        </div>
      </div>
      
      <!--企业信息-->
      <div v-if="informationTab == 'companyIntroduction'">
        <div class="wTableOne">
          <span>企业图片：</span>
          <el-input class="photoselectinput" v-model="templateDataHtml.companyIntroduction.companyIntroductionPhoto" placeholder="请通过右侧按钮选择图片" disabled></el-input>
          <el-button class="photoselectbutton" @click="showDialog(-1,-1)">选择</el-button>
        </div>
        <div class="newOptionDiv" v-for="(item,index) in templateDataHtml.companyIntroduction.companyIntroductionActivity" :key="'companyIntroduction'+index">
          <div class="wTableOne" >
            <span>第{{index+1}}项标题：</span>
            <el-input class="textselectinputright" v-model="item.name" placeholder="请输入标题"></el-input>
          </div>
          <div class="wTableOne" >
            <span>第{{index+1}}项内容：</span>
            <el-input class="textselectinputright" v-model="item.content" placeholder="请输入内容"></el-input>
          </div>
        </div>
        
      </div>
    </div>

    <!--溯源信息添加新项弹窗-->
    <el-dialog
      v-if="templateDataHtml.traceabilityInformation.traceabilityInformationActivities.length != 0"
      title="溯源信息新项添加"
      :visible.sync="AddNewOptionDialogVisible"
      width="35%"
      top="15%"
      center>
      <div class="AddNewOptionDialog">
        <div class="AddNewOptionDialogButton">
          <el-button @click="deleteNewOption('children')">删除</el-button>
          <el-button @click="addNewOption('text')">新增文字内容</el-button>
          <el-button @click="addNewOption('photo')">新增图片内容</el-button>
        </div>
        <div class="AddNewOptionDialogContent">
          <div class="AddNewOptionDialogTableOne">
            <span>追溯总标题：</span>
            <el-input class="AddNewOptionDialogTableOneright" v-model="templateDataHtml.traceabilityInformation.traceabilityInformationActivities[templateDataHtml.traceabilityInformation.traceabilityInformationActivities.length-1].title" placeholder="请输入溯源项目总标题"></el-input>
          </div>
          <div class="newOptionDiv" v-for="(item,index) in templateDataHtml.traceabilityInformation.traceabilityInformationActivities[templateDataHtml.traceabilityInformation.traceabilityInformationActivities.length-1].content" :key="'AddNewOptionDialog'+index">  
            <div class="AddNewOptionDialogTableOne" v-if="item.type == 'text'">
              <span>第{{index+1}}项标题：</span>
              <el-input class="textselectinputright" v-model="item.name" placeholder="请输入标题"></el-input>
            </div>
            <div class="AddNewOptionDialogTableOne" >
              <span>第{{index+1}}项内容：</span>
              <el-input v-if="item.type == 'photo'" class="photoselectinput" v-model="item.content" placeholder="请通过右侧按钮选择图片" disabled></el-input>
              <el-button v-if="item.type == 'photo'" class="photoselectbutton" @click="showDialog(index,-1)">选择</el-button>
              <el-input v-if="item.type == 'text'"  class="textselectinputright" v-model="item.content" placeholder="请输入内容"></el-input>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!--图片选择弹窗-->
    <el-dialog
      title="图片选择"
      :visible.sync="showDialogVisible"
      width="50%"
      top="15%"
      center>
      <div class="photoMain">
        <div class="oneF" v-for="(item,index) in photoData" :key="item.name" @click="checkOne(index)">
          <img :src="httpUrl + '/' + item.name" class="imageF">
          <span id="img-name">{{item.name}}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex';
export default {
  data () {
    return {
      // 模板信息
      templateDataHtml:{
        // 产品信息
        productInformation:{
          name: '产品信息',
          productTitle: '',
          productCode: '暂无',
          carouselImages:[
          {name: "轮播图1",content: ''},
          {name: "轮播图2",content: ''}
          ],
          productBasicInformation:[]
        },
        // 溯源信息
        traceabilityInformation:{
          name: '溯源信息',
          traceabilityInformationActivities: []
        },
        // 企业信息
        companyIntroduction:{
          name: '企业信息',
          companyIntroductionPhoto: '',
          companyIntroductionActivity:[]
        }
      },
      // 图片根路径
      httpUrl: '',
      // 图片数据
      photoData: [],
      // 图片选择弹窗显示开关
      showDialogVisible: false,
      // 最新选择的图片添加的位置
      photoinindex: null,
      photooutindex: null,
      // 选项卡名称
      informationTab: 'carouselImages',
      // 溯源信息添加新项弹窗显示开关
      AddNewOptionDialogVisible: false
    }
  },
  computed:{
    ...mapGetters(['templateData'])
  },
  watch: {
    templateData () {
      this.templateDataHtml = JSON.parse(JSON.stringify(this.templateData))
    }
  },
  beforeMount () {
    this.queryImg()
  },
  beforeDestroy () {
    this.changeIsPreview(false)
    sessionStorage.removeItem("templateData");
  },
  methods: {
    ...mapMutations(['changeTemplateData','changeIsPreview']),

    /**
     * 查询图片列表
     */
    queryImg () {
      this.$http({
        url: '/api/v1/cos/query/file/list',
        method: 'GET'
      }).then(res => {
        if(res.data.code === 200){
          this.httpUrl = res.data.data.url_prefix
          this.photoData = res.data.data.data
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
          this.$message.error('网络错误');
      })
    },

    /**
     * 打开图片选择弹窗
     */
    showDialog (inindex,outindex) {
      this.showDialogVisible = true
      this.photoinindex = inindex
      this.photooutindex = outindex
    },

    /**
     * 选中一张图片
     */
    checkOne (index) {
      if (this.informationTab == 'carouselImages') {
        // 轮播图
        this.templateDataHtml.productInformation.carouselImages[this.photoinindex].content = this.httpUrl+ '/' +this.photoData[index].name
      }else if(this.informationTab == 'productInformation') {
        // 产品信息
        this.templateDataHtml.productInformation.productBasicInformation[this.photoinindex].content = this.httpUrl+ '/' +this.photoData[index].name
      }else if(this.informationTab == 'traceabilityInformation') {
        // 追溯信息
        if (this.photooutindex == -1) {
          this.templateDataHtml.traceabilityInformation.traceabilityInformationActivities[this.templateDataHtml.traceabilityInformation.traceabilityInformationActivities.length-1].content[this.photoinindex].content = this.httpUrl+ '/' +this.photoData[index].name
        }else{
          this.templateDataHtml.traceabilityInformation.traceabilityInformationActivities[this.photooutindex].content[this.photoinindex].content = this.httpUrl+ '/' +this.photoData[index].name
        }
      }else if(this.informationTab == 'companyIntroduction') {
        // 企业信息
        this.templateDataHtml.companyIntroduction.companyIntroductionPhoto = this.httpUrl+ '/' +this.photoData[index].name
        
      }
      this.showDialogVisible = false
    },

    /**
     * 新增一项内容
     */
    addNewOption (type) {
      if (this.informationTab == 'carouselImages') {
        // 轮播图
        if (this.templateDataHtml.productInformation.carouselImages.length < 6){
          this.templateDataHtml.productInformation.carouselImages.push({name: "轮播图"+(this.templateDataHtml.productInformation.carouselImages.length+1),content: ''})
        }else {
          this.$message.error('轮播图最多六张！！！')
        }
      }else if(this.informationTab == 'productInformation') {
        // 产品信息
        if(type == 'text'){
          this.templateDataHtml.productInformation.productBasicInformation.push({ name:"",content:"", type:'text'})
        }else if(type == 'photo'){
          this.templateDataHtml.productInformation.productBasicInformation.push({ name:"",content:"", type:'photo'})
        }
      }else if(this.informationTab == 'traceabilityInformation') {
        // 追溯信息
        if(type == 'null'){
          this.templateDataHtml.traceabilityInformation.traceabilityInformationActivities.push({title: '',content: []})
          this.AddNewOptionDialogVisible = true
        }else if (type == 'text') {
          this.templateDataHtml.traceabilityInformation.traceabilityInformationActivities[this.templateDataHtml.traceabilityInformation.traceabilityInformationActivities.length-1].content.push({name:'',content: '', type: 'text'})
        }else if (type == 'photo') {
          this.templateDataHtml.traceabilityInformation.traceabilityInformationActivities[this.templateDataHtml.traceabilityInformation.traceabilityInformationActivities.length-1].content.push({name:'展示图片',content: '', type: 'photo'})
        }
      }else if(this.informationTab == 'companyIntroduction') {
        // 企业信息
        this.templateDataHtml.companyIntroduction.companyIntroductionActivity.push({ name:"",content:""})
      }
    },

    /**
     * 删除一项内容
     */
    deleteNewOption (type) {
      if (this.informationTab == 'carouselImages') {
        // 轮播图
        if (this.templateDataHtml.productInformation.carouselImages.length > 2){
          this.templateDataHtml.productInformation.carouselImages.pop()
        }else {
          this.$message.error('轮播图最少两张！！！')
        }
      }else if(this.informationTab == 'productInformation') {
        // 产品信息
        this.templateDataHtml.productInformation.productBasicInformation.pop()
      }else if(this.informationTab == 'traceabilityInformation') {
        // 追溯信息
        if (type == 'father') {
          this.templateDataHtml.traceabilityInformation.traceabilityInformationActivities.pop()
        }else if (type == 'children') {
          this.templateDataHtml.traceabilityInformation.traceabilityInformationActivities[this.templateDataHtml.traceabilityInformation.traceabilityInformationActivities.length-1].content.pop()
        }
        
      }else if(this.informationTab == 'companyIntroduction') {
        // 企业信息
        this.templateDataHtml.companyIntroduction.companyIntroductionActivity.pop()
      }
    },

    /**
     * 预览
     */
    toPreview () {
      // 将数据存储到预览界面可以接收到的位置
      sessionStorage.removeItem("templateData");
      sessionStorage.setItem('templateData', JSON.stringify(this.templateDataHtml));

      // 修改vuex里的模板内容
      this.changeTemplateData(this.templateDataHtml)

      // 显示预览结果
      this.changeIsPreview(false)
      setTimeout(()=>{
        this.changeIsPreview(true)
      },10)
    }
  }
}
</script>

<style scoped>
.wTable {
  height: 100%;
  overflow: scroll;
}
.wTableTitle {
  margin-left: 1vw;
}
.wTableOne {
  text-align: right;
  margin-bottom: 1rem;
  padding-right: 1rem;
}
.el-input >>> .el-input__inner {
  background-color: rgb(248, 248, 248);
  border: 0px;
  border-radius: 10px;
  color: black;
}

.photoMain {
  padding: 5px;
  width: 100%;
  height: 400px;
  overflow: scroll;
  display: flex;
  align-content: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.oneF {
  width: 220px;
  height: 182px;
  text-align: center;
  margin: 5px;
  border: 1px dashed #DCDFE6;
  border-radius:10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.imageF {
  display: block;
  height: 150px;
  width: 100%;
  border-radius:10px;
  object-fit: contain;
}
#img-name {
  width: 90%;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 30px;
  font-size: 10px;
  line-height: 30px;
  display: block;
  margin: 0 auto;
}
.textselectinputright {
  width: 25vw
}
.photoselectinput {
  width: 20vw
}
.photoselectbutton {
  width: 4vw;
  margin-left: 1vw
}
.addnewoption {
  position: absolute;
  right: 1vw;
  z-index: 20;
}
.deleteoption {
  position: absolute;
  right: 5vw;
  z-index: 20;
}
.newOptionDiv {
  padding-top: 1rem;
  border-top: 1px solid #DCDFE6;
}
.AddNewOptionDialog {
  height: 35vh
}
.AddNewOptionDialogButton {
  position: absolute;
  top: 5vh;
  right: 5vw;
}
.AddNewOptionDialogContent {
  margin-top: 3vh;
  height: 100%;
  overflow: scroll;
}
.AddNewOptionDialogTableOne {
  margin-bottom: 1rem;
  padding-right: 1rem;
}
.AddNewOptionDialogTableOneright {
  width: 25vw
}
</style>
