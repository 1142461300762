<template>
  <div id="MBProfitMessage">
      <wb-mb-tab-bar>
        <div slot="left" @click="backClick">返回</div>
        <div slot="center">个人中心</div>
        <div slot="right">管理</div>
      </wb-mb-tab-bar>
  </div>
</template>

<script>
import wbMbTabBar from '@/components/WB-Mb-Tabbar/wb-mb-tabBar.vue'
export default {
  name:'MBProfitMessage',
  components: { wbMbTabBar },
  props:{
    newNumber:{
      type:Object,
      default(){
        return{}
      }
    }
  },
  methods:{
    backClick(){
     this.$router.go(-1)
    }
  },
}
</script>

<style>

</style>