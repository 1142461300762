<template>
  <div id="MBRegister">
    <!-- 背景图片 -->
    <img src="../../assect/bg-image/mb-login.png" id="background">
    <!-- 返回按钮 -->
    <div class="register-back" @click="backClick">
      返回
    </div>
    <!-- 标题 -->
      <div class="logoTest">
        <div class="home-text">
          <div>农产品</div>
          <div>质量追溯</div>
          <div>管理平台</div>
        </div>
      </div>
      <!-- 注册输入框  -->
      <div class="register-input">
        <div class="register-user-input">
          <input type="text" placeholder="用户名" v-model="mbUser">
        </div>
        <div class="register-password-input">
          <input type="password" placeholder="密码" v-model="mbPassword">
        </div>
        <div class="password-again">
          <input type="password" placeholder="再次输入密码" v-model="mbPasswordSure">
        </div>
        <div class="register-email">
          <input type="text" 
          placeholder="邮箱" 
          v-model="mbEmail">
        </div>
        <div class="register-phone">
          <input type="text" placeholder="电话" v-model="mbPhone">
        </div>
        <div class="register-icon" @click="registerSend">
          <div>注册</div>
        </div>
        <div class="register-login">
          已经有账号？
        </div>
        <div class="login" @click="loginClick">
          登录
        </div>
      </div>
  </div>
</template>

<script>

import { sha256 } from 'js-sha256'

export default {
  name:'MBRegister',
  data(){
    return {
      mbUser:'',
      mbPassword:'',
      mbPasswordSure:'',
      mbEmail:'',
      mbPhone:''
    }
  },
  methods:{
    verification(){
      //用户名
      if(this.mbUser.length <= 2 || this.mbUser.length >= 12){
        this.$toast.show('用户昵称长度在2-12个字符');
        return false
      }
      //密码
       if(this.mbPassword==''||this.mbPassword==undefined||this.mbPassword==null){
         this.$toast.show('密码不能为空');
         return false
        }else{
          if(this.mbPassword.length <=5){
            this.$toast.show('密码长度不能小于5')
            return false
          }
        }
      //确认密码
      if(this.mbPasswordSure==''||this.mbPasswordSure==undefined||this.mbPasswordSure==null){
         this.$toast.show('确认密码不能为空');
         return false
      }else{
        if (this.mbPasswordSure !== this.mbPassword){
             this.$toast.show('密码与确认密码不同');
             return false
        }
      }
      //验证邮箱
      const regEmail =/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if(this.mbEmail==''||this.mbEmail==undefined||this.mbEmail==null){
         this.$toast.show('邮箱不能为空');
         return false
      }else{
        if (!regEmail.test(this.mbEmail)){
             this.$toast.show('请输入正确的邮箱地址');
             return false
        }
      }
      //验证手机号
       const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
       if(this.mbPhone==''||this.mbPhone==undefined||this.mbPhone==null){
         this.$toast.show('手机号不能为空');
         return false
      }else{
        if (!regMobile.test(this.mbPhone)){
             this.$toast.show('请输入正确的手机号');
             return false
        }
      }
      return true
    },
    // 一次验证
    registerSend(){
      if(this.verification()){
        //发送请求
        this.$http({
        url: '/api/v1/user/register',
        method: 'POST',
        data: {
          nickname: this.mbUser,
          password: sha256(this.mbPassword),
          phone: this.mbPhone,
          mail: this.mbEmail,
          role: 3
        }
       }).then(res => {
          if(res.data.code === 200){
            this.$toast.show(res.data.msg);
            this.$router.push('/mb/login')
          }else{
            this.$toast.show(res.data.msg);
          }
       }).catch(() => {
          this.$toast.show('网络错误');
        })
      }
    },
    loginClick(){
      this.$router.push('/mb/login')
    },
    backClick(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
#MBRegister{
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
#background {
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
}
.register-back{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  font-size: 8rem;
  font-weight: bold;
  color: #fff;
}
.logoTest{
    flex: 1;
    display: flex;
    flex-direction:column;
    align-items: center;
    width: 50%;
    margin-left: 50%;
    margin-top: 20%;
    transform: translate(-50%);
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #fff;
}
.register-input{
  flex: 2;
  position: relative;
  width: 80%;
  height: 80%;
  margin-left: 50%;
  margin-top: 7%;
  transform: translate(-50%);
  z-index: 9;
}
.register-user-input{
  width: 100%;
  height: 8%;
  background-color: rgba(255, 255, 255, .3);
  border-radius: 8rem;
  border: 1px solid #fff;
}
.register-user-input input{
  width: 95%;
  height: 100%;
  margin-left: 5%;
  outline: none;
  font-size: 8rem;
  background-color: transparent;
}
input::-webkit-input-placeholder{
  color: #fff;
}
.register-password-input{
  width: 100%;
  height: 8%;
  border-radius: 8rem;
  margin-top: 3%;
  background-color: rgba(255, 255, 255, .3);
  border: 1px solid #fff;
}
.register-password-input input{
  width: 95%;
  height: 100%;
  margin-left: 5%;
  outline: none;
  font-size: 8rem;
  background-color: transparent;
}
.password-again{
  width: 100%;
  height: 8%;
  border-radius: 8rem;
  margin-top: 3%;
  background-color: rgba(255, 255, 255, .3);
  border: 1px solid #fff;
}
.password-again input{
  width: 95%;
  height: 100%;
  margin-left: 5%;
  outline: none;
  font-size: 8rem;
  background-color: transparent;
}
.register-email{
  width: 100%;
  height: 8%;
  border-radius: 8rem;
  margin-top: 3%;
  background-color: rgba(255, 255, 255, .3);
  border: 1px solid #fff;
}
.register-email input{
  width: 95%;
  height: 100%;
  margin-left: 5%;
  outline: none;
  font-size: 8rem;
  background-color: transparent;
}
.register-phone{
  width: 100%;
  height: 8%;
  border-radius: 8rem;
  margin-top: 3%;
  background-color: rgba(255, 255, 255, .3);
  border: 1px solid #fff;
}
.register-phone input{
  width: 95%;
  height: 100%;
  margin-left: 5%;
  outline: none;
  font-size: 8rem;
  background-color: transparent;
}
.register-icon{
  position: relative;
  width: 100%;
  height: 8%;
  border-radius: 8rem;
  margin-top: 8%;
  border: 1px solid #009966;
  text-align: center;
  padding-top: 3%;
  font-size: 8rem;
  color: #fff;
  background-color: #009966;
}
.register-icon div{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.register-login{
  text-align: center;
  margin-top: 5%;
  color: #fff;
  font-size: 7rem;
}
.login{
  text-align: center;
  color: #fff;
   font-size: 8rem;
   margin-top: 2%;
   font-weight: bold;
}
</style>