export default{
        wb_mianBaoXieArray:[],

        // 新的模板
        templateData: {
                // 产品信息
                productInformation:{
                        name: '产品信息',
                        productTitle: '',
                        productCode: '暂无',
                        carouselImages:[
                        {name: "轮播图1",content: ''},
                        {name: "轮播图2",content: ''}
                        ],
                        productBasicInformation:[]
                },
                // 溯源信息
                traceabilityInformation:{
                        name: '溯源信息',
                        traceabilityInformationActivities: []
                },
                // 企业信息
                companyIntroduction:{
                        name: '企业信息',
                        companyIntroductionPhoto: '',
                        companyIntroductionActivity:[]
                }
        },

        // 预览判断
        isPreview: false
}