<template>
  <div>
    <img class="mybackground" src="../../../assect/ncp-sjzs/组 21.png" alt="">
  <div class="header">
    <img class="headerimg" src="../../../assect/ncp-sjzs/组 7.png" alt="">
    <span>农产品追溯数据展示</span>
  </div>
  <main>
    <div class="maintop">
      <img class="maintopone" src="../../../assect/ncp-sjzs/组 29.png" alt="">
      <div class="maintopleft maintopleftone">
        <span>商家数量</span>
        <div class="maintopleftimg">
          <h4 id="numBusiness">{{numBusiness}}</h4>
        </div>
      </div>
      <div class="maintopleft maintoplefttwo">
        <span class="maintoplefttwospan">标识注册总量</span>
        <div class="maintopleftimg">
          <h4 id="numRegister">{{numRegister}}</h4>
        </div>
      </div>
      <div class="maintopright maintoprightone">
        <span>消费者数量</span>
        <div class="maintoprightimg">
          <h4 id="numConsumer">{{numConsumer}}</h4>
        </div>
      </div>
      <div class="maintopright maintoprighttwo">
        <span>解析总数量</span>
        <div class="maintoprightimg">
          <h4 id="numAnalysis">{{numAnalysis}}</h4>
        </div>
      </div>
    </div>
    <div class="mainfooter">
      <div class="mainfooterleft">
        <span>标识注册数量趋势</span>
        <div class="topbutton">
          <button id="leftday" @click="changeBydmy('day')">日</button>
          |
          <button id="leftmonth" @click="changeBydmy('month')">月</button>
          |
          <button id="leftyear" @click="changeBydmy('year')">年</button>
        </div>
        <div id="leftcontent"></div>
      </div>
      <div class="mainfooterright">
        <span>商家标识注册总量排名</span>
        <div id="rightcontent"></div>
      </div>
    </div>
  </main>
  <button class="goBack" @click="goBack">返回</button>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  data(){
    return{
      numBusiness: 0,
      numRegister: 0,
      numConsumer: 0,
      numAnalysis: 0,
    }
  },
  computed: {
    /**
     * 左图表DOM
     */
    myLeftChartDom() {
      const dom = document.getElementById('leftcontent')
      return dom && this.$echarts.init(dom)
    },

    /**
     * 左图表resize节流，这里使用了lodash，也可以自己使用setTimout实现节流
     */
    myLeftChartResize() {
      
      return _.throttle(() => this.myLeftChartDom && this.myLeftChartDom.resize(), 500)
    },
    
    /**
     * 右图表DOM
     */
    myRightChartDom() {
      const dom = document.getElementById('rightcontent')
      return dom && this.$echarts.init(dom)
    },

    /**
     * 右图表resize节流，这里使用了lodash，也可以自己使用setTimout实现节流
     */
    myRightChartResize() {
      
      return _.throttle(() => this.myRightChartDom && this.myRightChartDom.resize(), 500)
    }
  },
  beforeMount(){
    this.getNumBusiness()
    this.getNumRegister()
    this.getNumConsumer()
    this.getNumAnalysis()
  },
  mounted(){
    //挂载监听器
    window.addEventListener('resize', this.myLeftChartResize)
    window.addEventListener('resize', this.myRightChartResize)
    
    this.getLeftData("month")
    this.getRightData()
  },
  destroyed() {
    //卸载监听器
    window.removeEventListener('resize', this.myLeftChartResize)
    window.removeEventListener('resize', this.myRightChartResize)
  },
  methods:{
    /**
     * 获取已注册的商家数量
     */
    getNumBusiness(){
      this.$http({
        url: this.Configs.adminUrl2 + '/api/v2/show/merchant/numbers',
        method: 'GET'
      }).then(res => {
        if(res.data.code === 200){
          this.numBusiness = res.data.data
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误');
      })
    },
    
    /**
     * 获取已注册的标识注册总数量
     */
    getNumRegister(){
      this.$http({
        url: this.Configs.adminUrl2 + '/api/v2/show/code/all/numbers',
        method: 'GET'
      }).then(res => {
        if(res.data.code === 200){
          this.numRegister = res.data.data
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误');
      })
    },
    
    /**
     * 已注册的消费者数量
     */
    getNumConsumer(){
      this.$http({
        url: this.Configs.adminUrl2 + '/api/v2/show/consumer/numbers',
        method: 'GET'
      }).then(res => {
        if(res.data.code === 200){
          this.numConsumer = res.data.data
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误');
      })
    },
    
    /**
     * 获取标识解析总数量
     */
    getNumAnalysis(){
      this.$http({
        url: this.Configs.adminUrl2 + '/api/v2/show/code/scan/numbers',
        method: 'GET'
      }).then(res => {
        if(res.data.code === 200){
          this.numAnalysis = res.data.data
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误');
      })
    },

    /**
     * 切换年月日
     */
    changeBydmy(bydmy){
      this.getLeftData(bydmy)
      if (bydmy == "month") {
        document.getElementById("leftday").style.color = "#00E9C3"
        document.getElementById("leftyear").style.color = "#00E9C3"
        document.getElementById("leftmonth").style.color = "#FFF"
      }else if (bydmy == "year") {
        document.getElementById("leftday").style.color = "#00E9C3"
        document.getElementById("leftmonth").style.color = "#00E9C3"
        document.getElementById("leftyear").style.color = "#FFF"
      }else if(bydmy == "day"){
        document.getElementById("leftmonth").style.color = "#00E9C3"
        document.getElementById("leftyear").style.color = "#00E9C3"
        document.getElementById("leftday").style.color = "#FFF"
      }
    },

    /**
     * 获取左部图表数据
     */
    getLeftData(bydmy){
      this.$http({
        url: this.Configs.adminUrl2 + '/api/v2/show/code/scan/numbers/bydmy',
        method: 'GET',
        params:{
          bydmy: bydmy
        }
      }).then(res => {
        if(res.data.code === 200){
          var xAxisvalue = []
          var seriesvalue = []
          Object.keys(res.data.data).forEach(function(key,index){
              xAxisvalue[index]=key
              seriesvalue[index]=res.data.data[key],index
          });
          this.getLeftView(xAxisvalue,seriesvalue)
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误');
      })
    },

    /**
     * 生成左部图表
     */
    getLeftView(xAxisData,seriesData){
      let that = this
      // 绘制图表
      this.myLeftChartDom.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
                backgroundColor: '#999'
            }
          },
          formatter(params){
          for(let x in params){
            return ""+params[x].data;
          } 
        }
        },
        legend: {
            show: false
        },
        xAxis: [
            {
                type: 'category',
                name: '时间',
                boundaryGap: false,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#999'
                    },
                },
                data: xAxisData
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '数量',
                axisLine: {
                  show: true,
                  lineStyle: {
                  color: '#999'
                  },
                },
            }
        ],
        series: [
            {
                name: 'Line 1',
                type: 'line',
                stack: '数量',
                smooth: true,
                lineStyle: {
                    width: 1,
                    color: 'RGBA(0,233,195,1)'
                },
                texStyle:{
                  color: "#FFF"
                },
                showSymbol: false,
                areaStyle: {
                    opacity: 0.8,
                    color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: 'RGBA(0,233,195,1)'
                    }, {
                        offset: 1,
                        color: 'RGBA(0,233,195,0)'
                    }])
                },
                emphasis: {
                    focus: 'series'
                },
                data: seriesData
            }
        ]
      });
    },

    /**
     * 获取右部图表数据
     */
    getRightData(){
      this.$http({
        url: this.Configs.adminUrl2 + '/api/v2/show/code/nums/sort',
        method: 'GET',
      }).then(res => {
        if(res.data.code === 200){
          this.getRightView(res.data.data.MerchantList,res.data.data.ProductNumsList)
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误');
      })
    },

    /**
     * 生成右部图表
     */
    getRightView(xAxisData,seriesData){
      let that = this
      // 绘制图表
      this.myRightChartDom.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'     
          },
          formatter(params){
            for(let x in params){
              return params[x].name +": "+params[x].data;
            }
          }
        },
        xAxis: [
            {
                type: 'category',
                name: '公司名称',
                data: xAxisData,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#999'
                    },
                },
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    rotate: 15,
                    align: 'center',
                    color: '#999',
                    margin: 15
                },
                offset: 15
            }
        ],
        yAxis: [
            {
                type: 'value',
                name: '数量',
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#999'
                    },
                },
            }
        ],
        series: [
            {
                name: 'bar 1',
                type: 'bar',
                itemStyle:{
                    color: new that.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: 'RGBA(0,233,195,1)'
                    }, {
                        offset: 1,
                        color: 'RGBA(0,233,195,0)'
                    }])
                },
                barWidth: '60%',
                data: seriesData
            }
        ]
    });
    },

    /**
     * 返回事件
     */
    goBack(){
      window.history.go(-1);
    }
  }
}
</script>

<style scoped>
/* 背景样式 */
.mybackground {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

/* 标题头样式 */
.header {
  position: relative;
  width: 100%;
  height: 7vh;
}
.headerimg{
  width: 100%;
  height: 100%;
}
.header span {
  color: #00E9C3;
  font-size: 2vw;
  position: absolute;
  left: 50%;
  top:50%;
  transform:translate(-50%,-50%);
}

/* 主体样式 */
main {
  width: 100%;
  height: 93vh;
}

/* 上半部数据样式 */
.maintop {
  position: relative;
  height: 46.5%;
}
.maintopone {
  height: 80%;
  position: absolute;
  left: 50%;
  transform:translate(-50%,0);
}

/* 上半部的左半边数据 */
.maintopleft{
  position: relative;
  width: auto;
}
.maintopleft span {
  position: absolute;
  font-size: 1.5vw;
  top: 7vh;
  right: 1vw;
  color: #FFF;
}
.maintoplefttwospan {
  right: -1.5vw !important;
}
.maintopleft img {
  margin-left: 7.5vw;
}
.maintopleft .maintopleftimg {
  font-size: 3vw;
  top: 1.4vh;
}

/* 子样式 */
.maintopleftone {
  position: fixed;
  left: 6vw;
  top: 15.5vh;
  width: 40vw;
}
.maintopleftone .maintopleftimg {
  position: absolute;
  left: 0;
  width: 80%;
  padding: 1vh 0 1vh 0;
  background-image: url(../../../assect/ncp-sjzs/组\ 24.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #ee1e17;
}
.maintopleftone .maintopleftimg h4 {
  width: 22vw;
  height: 100%;
  margin: 0;
  margin-left: 5vw;
  text-align: center;
}
.maintoplefttwo {
  position: fixed;
  left: 6vw;
  width: 40vw;
  top: 33vh;
}
.maintoplefttwo .maintopleftimg {
  position: absolute;
  left: 0;
  width: 80%;
  padding: 1vh 0 1vh 0;
  background-image: url(../../../assect/ncp-sjzs/组\ 24.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: rgb(0, 174, 255);
}
.maintoplefttwo .maintopleftimg h4 {
  width: 22vw;
  height: 100%;
  margin: 0;
  margin-left: 5vw;
  text-align: center;
}

/* 上半部的右半边数据 */
.maintopright {
  position: relative;
  width: auto;
}
.maintopright span {
  position: absolute;
  font-size: 1.5vw;
  top: 1vh;
  color: #FFF;
}
.maintopright img {
  margin-left: 7.5vw;
}
.maintopright .maintoprightimg {
  font-size: 3vw;
  top: 1.4vh;
  right: 9vw;
}

/* 子样式 */
.maintoprightone {
  position: fixed;
  right: 6vw;
  top: 15.5%;
  width: 40vw;
}
.maintoprightone .maintoprightimg {
  position: absolute;
  right: 0;
  width: 80%;
  padding: 1vh 0 1vh 0;
  background-image: url(../../../assect/ncp-sjzs/组\ 24.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #13c413;
}
.maintoprightone .maintoprightimg h4 {
  width: 22vw;
  height: 100%;
  margin: 0;
  margin-left: 5vw;
  text-align: center;
}
.maintoprighttwo {
  position: fixed;
  right: 6vw;
  width: 40vw;
  top: 33.1%;
}
.maintoprighttwo .maintoprightimg {
  position: absolute;
  right: 0;
  width: 80%;
  padding: 1vh 0 1vh 0;
  background-image: url(../../../assect/ncp-sjzs/组\ 24.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: yellow;
}
.maintoprighttwo .maintoprightimg h4 {
  width: 22vw;
  height: 100%;
  margin: 0;
  margin-left: 5vw;
  text-align: center;
}

/* 下半部图表样式 */
.mainfooter {
  position: relative;
  height: 50%;
}

.mainfooter div span {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 1.3vw;
}

.topbutton {
  z-index: 999;
  position: absolute;
  right: 50px;
  top: 20px;
  height: 2vw;
  font-size: 1vw;
  line-height: 1vw;
  padding: 5px;
  border: 2px solid #00E9C3;
}
.topbutton button {
  background: none;
  border: none;
  font-size: 1vw;
  line-height: 1vw;
  color: #00E9C3;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
}
.topbutton button :active{
  color: white;
}

/* 左 */
.mainfooterleft {
  position: absolute;
  left: 6vw;
  width: 41.5vw;
  height: 40vh;
  background-image: url("../../../assect/ncp-sjzs/组\ 28.png");
  background-size: 100% 100%;
  color: #FFF;
}
#leftcontent {
  margin-top: 4vh;
  width: 41.5vw;
  height: 36vh;
}
#leftmonth {
  color: white;
}

/* 右 */
.mainfooterright {
  position: absolute;
  right: 6vw;
  width: 41.5vw;
  height: 40vh;
  background-image: url("../../../assect/ncp-sjzs/组\ 28.png");
  background-size: 100% 100%;
  color: #FFF;
}
#rightcontent {
  margin-top: 4vh;
  width: 41.5vw;
  height: 36vh;
}

.goBack {
  position: fixed;
  top: 11.3vh;
  right: 2vw;
  color: white;
  font-size: 1.5vmax;
  z-index: 888;
  background-color: unset;
  cursor: pointer;
}
.goBack:hover {
  color: #00E9C3;
}
</style>