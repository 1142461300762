import { render, staticRenderFns } from "./pc-recyclebin-bottom.vue?vue&type=template&id=7dc58278&scoped=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dc58278",
  null
  
)

export default component.exports