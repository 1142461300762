<!--
 * @Description: 
 * @Version: 1.0
 * @Author: AndBridge
 * @LastEditors: AndBridge
-->
<template>
  <div id="tab-and-content">
       <el-tabs stretch v-model="activeName">
            <el-tab-pane label="产品信息" name="first">
                <slot name="product-information"></slot>
            </el-tab-pane>
            <el-tab-pane label="溯源信息" name="second">
                <slot name="traceability-information"></slot>
            </el-tab-pane>
            <el-tab-pane label="企业介绍" name="third">
                <slot name="company-introduction"></slot>
            </el-tab-pane>
        </el-tabs>
  </div>
</template>

<script>
export default {
    data() {
        return {
            activeName: "first"
        };
    },
    methods: {
    }
}
</script>

<style scoped>
.tab-and-content{
    width: 100%;
    height: 100%;
}
.el-tabs >>> .el-tabs__header{
    margin: 0;
    padding: 0;
}
</style>