<template>
    <!-- 菜单 -->
    <el-menu
    @select="handleSelect"
    :default-active="$route.path.slice(0,($route.path.split('/')).length>=6?$route.path.lastIndexOf('/'):$route.path.length)"
    class="el-menu-vertical-demo"
    :router="true"
    >
        <el-menu-item-group>
            <template slot="title">个人中心</template>
            <el-menu-item index="/pc/bu/suc/notification">
                <i :class="!isShowA ? 'iconfont icontongzhi_01' : 'iconfont icontongzhi_02'"></i>
                通知管理
            </el-menu-item>
            <el-menu-item index="/pc/bu/suc/complaints">
                <i :class="!isShowB ? 'iconfont iconjianyi_01' : 'iconfont iconjianyi_02'"></i>
                投诉建议
            </el-menu-item>
            <el-menu-item index="/pc/bu/suc/information">
                <i :class="!isShowC ? 'iconfont icongeren_01' : 'iconfont icongeren_02'"></i>
                个人信息
            </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
            <template slot="title">产品管理</template>
            <el-menu-item index="/pc/bu/suc/registered">
                <i :class="!isShowD ? 'iconfont iconzhucexinxi_01' : 'iconfont iconzhucexinxi_02'"></i>
                注册信息
                </el-menu-item>
            <el-menu-item v-if="true" index="/pc/bu/suc/regulated">
                <i :class="!isShowE ? 'iconfont iconzhengfu_01' : 'iconfont iconzhengfu_02'"></i>
                政府监管
            </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group v-if="false">
            <template slot="title">模板管理</template>
            <el-menu-item index="3-1">
                <i :class="!isShowF ? 'iconfont iconchanpin_01' : 'iconfont iconchanpin_02'"></i>
                产品介绍
            </el-menu-item>
            <el-menu-item index="3-2">
                <i :class="!isShowG ? 'iconfont iconshebei_01' : 'iconfont iconshebei_02'"></i>
                设备管理
            </el-menu-item>
            <el-menu-item index="3-3">
                <i :class="!isShowH ? 'iconfont iconliucheng_01' : 'iconfont iconliucheng_02'"></i>
                生产流程
            </el-menu-item>
            <el-menu-item index="3-4">
                <i :class="!isShowI ? 'iconfont iconqita_01' : 'iconfont iconqita_02'"></i>
                其  他
            </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
            <template slot="title">客户管理</template>
            <el-menu-item index="/pc/bu/suc/distribution">
                <i :class="!isShowJ ? 'iconfont iconweizhi_01' : 'iconfont iconweizhi_02'"></i>
                位置分布
            </el-menu-item>
            <el-menu-item index="/pc/bu/suc/warning">
                <i :class="!isShowJJ ? 'iconfont iconweizhi_01' : 'iconfont iconweizhi_02'"></i>
                窜货预警
            </el-menu-item>
            <el-menu-item index="/pc/bu/suc/antiCounterfeit">
                <i :class="!isShowJJ ? 'iconfont iconweizhi_01' : 'iconfont iconweizhi_02'"></i>
                防伪预警
            </el-menu-item>
            <el-menu-item v-if="false" index="/pc/bu/suc/scanData">
                <i :class="!isShowK ? 'iconfont iconshuju_01' : 'iconfont iconshuju_02'"></i>
                扫码数据
            </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group>
            <template slot="title">图片管理</template>
            <el-menu-item index="/pc/bu/suc/gallery">
                <i :class="!isShowL ? 'iconfont icontupian_01' : 'iconfont icontupian_02'"></i>
                图片    库
            </el-menu-item>
            <el-menu-item v-if="false" index="/pc/bu/suc/recycle">
                <i :class="!isShowM ? 'iconfont iconhuishou_01' : 'iconfont iconhuishou_02'"></i>
                回收    站
            </el-menu-item>
        </el-menu-item-group>
    </el-menu>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    data(){
        return{
            isShowA:false,
            isShowB:false,
            isShowC:false,
            isShowD:false,
            isShowE:false,
            isShowF:false,
            isShowG:false,
            isShowH:false,
            isShowI:false,
            isShowJ:false,
            isShowJJ:false,
            isShowJJJ:false,
            isShowK:false,
            isShowL:false,
            isShowM:false,
            defaultActive:"/pc/bu/suc/notification"
        }
    },
    methods:{
        ...mapMutations(["wb_changeMianBaoXie"]),

        /**
         * 菜单选项切换
         */
        handleSelect(key) {
            switch (key) {
                case "/pc/bu/suc/notification":
                    this.isShowFunc();
                    this.isShowA=true;
                    this.wb_changeMianBaoXie(["个人中心","通知管理"]);
                    break;
                case "/pc/bu/suc/complaints":
                    this.isShowFunc();
                    this.isShowB=true;
                    this.wb_changeMianBaoXie(["个人中心","投诉建议"]);
                    break;
                case "/pc/bu/suc/information":
                    this.isShowFunc();
                    this.isShowC=true;
                    this.wb_changeMianBaoXie(["个人中心","个人信息"]);
                    break;
                case "/pc/bu/suc/registered":
                    this.isShowFunc();
                    this.isShowD=true;
                    this.wb_changeMianBaoXie(["产品管理","注册信息"]);
                    break;
                case "/pc/bu/suc/regulated":
                    this.isShowFunc();
                    this.isShowE=true;
                    this.wb_changeMianBaoXie(["产品管理","政府监管"]);
                    break;
                case "3-1":
                    this.isShowFunc();
                    this.isShowF=true;
                    break;
                case "3-2":
                    this.isShowFunc();
                    this.isShowG=true;
                    break;
                case "3-3":
                    this.isShowFunc();
                    this.isShowH=true;
                    break;
                case "3-4":
                    this.isShowFunc();
                    this.isShowI=true;
                    break;
                case "/pc/bu/suc/distribution":
                    this.isShowFunc();
                    this.isShowJ=true;
                    this.wb_changeMianBaoXie(["客户管理","位置分布"]);
                    break;
                case "/pc/bu/suc/warning":
                    this.isShowFunc();
                    this.isShowJJ=true;
                    this.wb_changeMianBaoXie(["客户管理","窜货预警"]);
                    break;
                case "/pc/bu/suc/antiCounterfeit":
                    this.isShowFunc();
                    this.isShowJJJ=true;
                    this.wb_changeMianBaoXie(["客户管理","防伪预警"]);
                    break;
                case "/pc/bu/suc/scanData":
                    this.isShowFunc();
                    this.isShowK=true;
                    this.wb_changeMianBaoXie(["客户管理","扫码数据"]);
                    break;
                case "/pc/bu/suc/gallery":
                    this.isShowFunc();
                    this.isShowL=true;
                    this.wb_changeMianBaoXie(["图片管理","图片库"]);
                    break;
                case "/pc/bu/suc/recycle":
                    this.isShowFunc();
                    this.isShowM=true;
                    this.wb_changeMianBaoXie(["图片管理","回收站"]);
                    break;        
                default:
                    this.isShowFunc();
                    this.isShowA=true;
                    break;
            }
      },

      /**
       * 菜单选中状态清空
       */
      isShowFunc(){
          this.isShowA=false;
          this.isShowB=false;
          this.isShowC=false;
          this.isShowD=false;
          this.isShowE=false;
          this.isShowF=false;
          this.isShowG=false;
          this.isShowH=false;
          this.isShowI=false;
          this.isShowJ=false;
          this.isShowJJ=false;
          this.isShowJJJ=false;
          this.isShowK=false;
          this.isShowL=false;
          this.isShowM=false;
      }
    },
    beforeMount(){
        this.handleSelect(this.$route.path.slice(0,(this.$route.path.split('/')).length>=6?this.$route.path.lastIndexOf('/'):this.$route.path.length));
    },
    mounted(){
        window.addEventListener('hashchange',()=>{
            this.handleSelect(this.$route.path.slice(0,(this.$route.path.split('/')).length>=6?this.$route.path.lastIndexOf('/'):this.$route.path.length));
        },false);
    }
}
</script>

<style scoped>
.el-menu-vertical-demo >>> .el-menu-item-group__title{
    width: 100%;
    padding-top: 2.5rem;
    padding-bottom: 1.5rem;
    background-color: #009966;
    font-size: 2rem;
    color: white;
    font-weight: 600;
}
.el-menu-vertical-demo >>> .el-menu-item{
    font-size: 2rem;
    font-weight: 600;
    color: white;
    background-color: #009966;
    height: 4rem;
    line-height: 4rem;
    padding-left: 3.6rem !important;
}
.el-menu-vertical-demo >>> .el-menu-item.is-active{
    background-color: white;
    color: #009966;
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 2.5rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}
.el-menu-item:hover {
    background-color: white;
    color: #909399 !important;
}
.el-menu-item:hover i{
    color: #909399;
}
</style>