<template>
  <div id="home-joinus">
    <!-- 第一张 -->
    <div class="join-item-first">
      <div class="image-first">
        <img src="../../../../assect/enterHomeImg/joinus/2.png" alt="东南数字经济发展研究院">
      </div>
      <div class="content-first">
        <div>东南数字经济发展研究院</div>
        <ul style="padding-left:3rem;">
          <li>衢州发展数字经济的"一号院"</li>
          <li>东南地区首家数字经济研究院</li>
          <li>衢州市政府经编制部门登记</li>
          <li>人才高度聚集的重要基地</li>
          <li>联系电话:18562632357</li>
        </ul>
      </div>
    </div>
    <!-- 第二张 -->
    <div class="join-item-second"> 
      <div class="content-first">
        <div style="margin-left:-8vw">浙江网标物联有限公司</div>
        <ul>
          <li>市委"1433"战略体系试点</li>
          <li>数字经济产业化经营公司</li>
          <li>经中国信息研究院审批授权</li>
          <li>负责浙西及周边地区推广和生态研发</li>
          <li>联系电话:0570-3358817</li>
        </ul>
      </div>
      <div class="image-first">
        <img src="../../../../assect/enterHomeImg/joinus/3.png" alt="浙江网标物联有限公司">
      </div>
    </div>
    <!-- 第三张 -->
    <div class="join-item-third">
      <div class="image-first">
        <img src="../../../../assect/enterHomeImg/joinus/1.png" alt="标识解析技术的数字营销系统">
      </div>
      <div class="content-first">
        <div>标识解析技术的数字营销系统</div>
        <ul style="padding-left:14rem;">
          <li>工业互联网标识应用的深度挖掘</li>
          <li>搭建服务于终端门店及消费者数字化营销平台</li>
          <li>对门店进货实现数字化管理和建设</li>
          <li>对消费者扫码实现数字化管理和建设</li>
          <li>联系邮箱:official@zjid.net</li>
        </ul>
      </div>
    </div>
    <!-- 第四张 -->
    <div class="join-item-forth">
      <div class="content-first">
        <div style="margin-left:-4vw">特种设备全生命周期管理平台</div>
        <ul>
          <li>结合标识解析技术及特种设备编码规范</li>
          <li>对特种设备全生命周期追溯</li>
          <li>根据个性化需求进行一对一研发</li>
          <li>联系邮箱:official@zjid.net</li>
        </ul>
      </div>
      <div class="image-first">
        <img src="../../../../assect/enterHomeImg/joinus/4.png" alt="特种设备全生命周期管理平台">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'home-joinus',
  data(){
    return {
      
    }
  }
}
</script>

<style lang='scss' scoped>
%joinus_css{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .image-first{
    width: 50%;
    img{
      width: 100%;
    }
  }
  .content-first{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    text-align: left;
    box-sizing: border-box;
    div{
      font-weight: 500;
      font-size: 4rem;
    }
    ul{
      padding: 10px 0px 0px 0px;
      font-size: 3rem;
      li{
        padding-top: 5px;
        &::marker{
          color: #feab66;
        }
      }
    }
  }
}
#home-joinus{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  color: rgb(85, 85, 85);
}
/* 第一张图 */

.join-item-first{
  @extend %joinus_css;
}
/**第二张图 */
.join-item-second{
  @extend %joinus_css;
  justify-content: flex-end;
  margin-top: 20rem;
}
/**第三张图 */
.join-item-third{
  @extend %joinus_css;
  margin-top: 20rem;
}
/**第四张图 */
.join-item-forth{
  @extend %joinus_css;
  justify-content: flex-end;
  margin-top: 20rem;
}
</style>