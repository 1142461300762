<template>
  <div id="MBProfitAdvice">
        <div v-if="number==0">
          <wb-mb-tab-bar>
            <div slot="left" @click="backClick">返回</div>
            <div slot="center">个人中心</div>
            <div slot="right">新增</div>
          </wb-mb-tab-bar>
          <div class="adviceContent">
            <div @click="suggestClick">我的建议</div>
            <div>我的投诉</div>
            <div>草稿</div>
          </div>
        </div>
        <MBSuggest v-if="number==1" :number='number' @newNumber='newNumber'></MBSuggest>
  </div>
</template>

<script>
//组件
import wbMbTabBar from '@/components/WB-Mb-Tabbar/wb-mb-tabBar.vue'
import MBSuggest from './MBProfitAdviceChild/MBSuggest'
export default {
  name:'MBProfitAdvice',
  components: { wbMbTabBar,MBSuggest},
  data(){
    return {
      number:0
    }
  },
  methods:{
    backClick(){
      this.$router.go(-1)
    },
    suggestClick(){
      this.number=1
    },
    newNumber(){
      this.number=0
    }
  }
}
</script>

<style>
.adviceContent{
  margin-top: 20rem;
}
.adviceContent>div{
  padding: 4rem;
  font-size: 7rem;
}
.adviceContent>div:hover{
  background-color: blue;
}
</style>