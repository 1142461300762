<template>
  <!-- 树形结构展示 -->
  <el-tree style="padding: 10px;" :data="treeData" :props="defaultProps" default-expand-all>
    <span class="span-ellipsis" slot-scope={data}>
      <span :title="data.name">{{data.name}}</span>
    </span>
  </el-tree>
</template>

<script>
export default {
  data() {
    return {
      treeData: [{
        name: '图片列表',
        data: []
      }],
      defaultProps: {
        children: 'data',
        label: 'name'
      }
    };
  },
  beforeMount () {
    this.queryImg()
  },
  methods: {
    /**
     * 查询图片列表
     */
    queryImg() {
      this.$http({
        url: '/api/v1/cos/query/file/list',
        method: 'GET'
      }).then(res => {
        if(res.data.code === 200){
          this.treeData[0].data = res.data.data.data
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
          this.$message.error('网络错误');
      })
    },
  }
}
</script>


<style scoped>
.span-ellipsis {
  display: block;
  width: 500px;
  font-size: 1.5vh;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
