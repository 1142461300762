<template>
  <div class="content">
    <div class="choice-btn">
      <ad-pc-three-choice :choiceArray="choiceArray">
          <template #choiceA>
            <i class="iconfont iconziliaoshencha_02"></i>
          <br>
          <span class="title">资料审查</span>
          </template>
          <!-- <template #choiceB>
            <i class="iconfont iconqianzhuimingshencha_02"></i>
          <br>
          <span class="title">前缀名注册</span>
          </template> -->
          <template #choiceC>
            <i class="iconfont iconzhanghaoguanli_02"></i>
          <br>
          <span slot="title" class="title">商家账号</span>
          </template>
      </ad-pc-three-choice>
    </div>
    <div class="rg-content">
      <transition>
        <router-view/>
      </transition>
    </div>
  </div>
</template>

<script>
import AdPcThreeChoice from '../components/Ad-Pc-Rg-Content-Left/ad-pc-three-choice'
export default {
  components:{
    AdPcThreeChoice
  },
  data(){
    return{
      choiceArray:[
        {
        url:"/pc/adminhome/shangjiaguanli/ziliaoshengcha",
        slotName:"choiceA"
        },
    
        {
        url:"/pc/adminhome/shangjiaguanli/shangjiazhanghao",
        slotName:"choiceC"
        }]
    }
  }
}
</script>

<style scoped>
.a{
  background: red;
}
.content{
  width: 100%;
  height: 100%;
  position: relative;
}
.choice-btn{
  width: 13.2%;
  height: 100%;
  position: absolute;
  left: 0;
}
.title{
  font-size: 3rem;
  font-weight: 600;
  position: absolute;
  left: 0;
  right: 0;
  /* bottom: 3.8vh; */
  bottom: 6.8vh;
}
.iconfont{
  font-family: "iconfont" !important;
  font-size: 7vw;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #9999;
}
.rg-content{
  overflow: hidden;
  width: 87%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  padding: 3vh 2vw 2vh 2vw;
}
</style>