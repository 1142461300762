<template>
  <div class="content">
      <!-- 标题 -->
      <div class="title-input-box">
        <label for="title">标题</label>
        <input type="text" @change="getTitle" v-model="title" name="" id="title">
      </div>
      <!-- 内容 -->
      <div class="msg-input-box">
        <label for="msg">内容</label>
        <el-input id="msg"
        @change="getMsg"
          type="textarea"
          maxlength="888"
          show-word-limit
          v-model="msg">
        </el-input>
      </div>
      <!-- 发送按钮 -->
      <div class="send-button">
        <slot name="send-btn"></slot>
      </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  data() {
    return {
    title:"",
    msg:""
    }
  },
  methods:{
    ...mapMutations(["ad_setSendNoticeTitle","ad_setSendNoticeMsg"]),
    getTitle(){
      this.ad_setSendNoticeTitle(this.title);
    },
    getMsg(){
      this.ad_setSendNoticeMsg(this.msg)
    }
  },
  computed:{
    
  }
}
</script>

<style scoped>
.content{
  width: 100%;
  height: 100%;
}
.title-input-box{
    width: 100%;
   position: relative;
   line-height: 3.8vh;
}
.title-input-box label{
  font-size: 2.2rem;
  font-weight: 500;
  position: absolute;
  padding: 0;
  left: 0;
}
.title-input-box input[type="text"]{
  width: 88%;
  height: 3.8vh;
  border: 1px solid #999;
  padding: 2px 15px;
  font-size: 2rem;
  font-weight: 580;
}
.msg-input-box{
  position: relative;
  margin-top: 2vh;
}
.msg-input-box label{
  position: absolute;
  left: 0;
  font-size: 2.2rem;
  font-weight: 500;
}
.el-textarea{
  width: 88%;
  border: 1px solid #999;
}
.el-textarea >>> .el-textarea__inner{
  height: 51vh !important;
}
.wh-button{
  width: 8vw;
  line-height: 3vh;
  margin-top: 3.2vh;
  font-size: 2.3rem;
  font-weight: 600;
}
.el-button--success{
  width: 8vw;
  line-height: 3vh;
  margin-top: 3.2vh;
  font-size: 2.3rem;
  font-weight: 600;
  background-color: #009966;
}
.el-button--success:hover {
  background-color: #00b679;
}
.el-button--success:focus{
  background-color: #009966;
}
.el-button--success.is-active, .el-button--success:active{
  background-color: #00b679;
}
</style>