<template>
  <el-dialog
      title="标识注册详情"
      :visible.sync="DialogVisible"
      :before-close="() => {this.$parent.DialogVisible = false}"
      :destroy-on-close="true"
      width="50%"
      top="10%"
      center>
      <div class="dialogMain">
        <div class="dialogMainTop">
          <div class="dialogMainTopLeft">
            <span>期望注册数量</span><br>
            <span>{{allNumber}}</span>
          </div>
          <div class="dialogMainTopRight">
            <span>成功注册数量</span><br>
            <span>{{succNumber}}</span>
          </div>
        </div>
        <div class="dialogMainBottom">
          <el-table
            ref="multipleTable"
            :data="detailsData"
            tooltip-effect="dark"
            :show-header="false"
            height="100%"
            style="width: 100%">
            <el-table-column
              prop="id_code"
              align="center"
              label="标识">
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="detailsNumber"
            :total="detailsTotal"
            :current-page="detailsPage"
            style="text-align: center; margin-top: 2rem"
            @current-change="queryOnePage">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
</template>

<script>
export default {
  props:['DialogVisible'],
  data () {
    return {
      detailsData: [],
      allNumber: 0,
      succNumber: 0,
      detailsPage: 1,
      detailsNumber: 10,
      detailsTotal: 0
    }
  },
  methods: {
    /**
     * 查询单个详情axios
     */
    queryOnePage (page) {
      this.$http({
        url: this.Configs.adminUrl1 + '/api/v1/products/look/register',
        method: 'GET',
        params:{
          page: page,
          number: this.detailsNumber,
          products_type_id: this.$parent.products_type_id,
          batch: this.$parent.batch
        }
      }).then(res => {
        if(res.data.code === 200){
          this.allNumber = res.data.count
          this.succNumber = res.data.successnum
          this.detailsTotal = res.data.successnum
          this.detailsData = res.data.data
        }
      }).catch(() => {
        this.$message.error('网络错误');
      })
    },
  }
}
</script>

<style scoped>
.el-dialog__wrapper >>>.el-dialog__title {
  font-weight: bold;
  font-size: 2.5rem;
}
.dialogMain {
  height: 55vh;
}
.dialogMain .dialogMainTop {
  height: 5vh;
  width: 100%;
  position: relative;
  border-bottom: 1px solid rgb(230, 225, 225);
}
.dialogMain .dialogMainTop .dialogMainTopLeft{
  height: 5vh;
  width: 50%;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 1.5vh;
  color: red;
}
.dialogMain .dialogMainTop .dialogMainTopRight{
  height: 5vh;
  width: 50%;
  position: absolute;
  right: 0;
  text-align: center;
  font-size: 1.5vh;
  color: red;
}
.dialogMain .dialogMainBottom {
  height: 45vh;
  width: 100%;
  position: relative;
}
</style>