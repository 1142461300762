<template>
  <div class="title-msg-button-box">
    <pc-title-msg-zhiding-button/>
  </div>
</template>

<script>
import pcTitleMsgZhidingButton from './modules/pc-title-msg-zhiding-button.vue'
export default {
  components: { pcTitleMsgZhidingButton },
  computed:{
    
  },
  methods:{
    
  }
}
</script>

<style src="../../css/title-msg-button-box.css" scoped>
</style>