<template>
  <div id="MBLogo">
      <wb-mb-tab-bar>
        <div slot="left" @click="addClick" v-if="!isActive">新增</div>
        <div slot="left" @click="backClick" v-else>返回</div>
        <div slot="center">标识管理</div>
        <div slot="right" @click="manageClick">管理</div>
      </wb-mb-tab-bar>
      <mb-profit-child 
      v-for="(item,index) in goods" :key="item.id6" 
      :index='index'
      :item='item' 
      :isActive='isActive'>
      </mb-profit-child>
      <select-all 
      :isActive='isActive' 
      v-for="item in goods" 
      :goods='goods'
      :key="item.id7"
       @newGoods='newGoods'></select-all>
  </div>
</template>

<script>
import wbMbTabBar from '@/components/WB-Mb-Tabbar/wb-mb-tabBar.vue'
import MbProfitChild from './mbProfitChild.vue';
import SelectAll from './selectAll.vue';
//导入mb vuex
import {mapGetters} from 'vuex';
// network请求
import {mLogo} from '@/components/network/getNetwork.js'
export default {
  name:'MBLogo',
  components: { wbMbTabBar,MbProfitChild, SelectAll },
  data(){
    return {
      back:"新增",
      goods:[],
      isActive:false,
      
    }
  },
  computed:{
    ...mapGetters(["mb_scanningResult","mb_add_scanningResult"])
  },
  created(){
    if(this.mb_add_scanningResult !=''){
      mLogo(this.mb_add_scanningResult).then((res)=>{
        if(res.data.code===200){
          this.$toast.show(res.data.msg)
          this.goods=res.data.data
          // this.goods.push({text:this.mb_add_scanningResult,check:false})
        }else{
          this.$toast.show(res.data.msg)
        }
      }).catch(()=>{
        this.$toast.show('网络错误')
      })
      }
  },
  methods:{
    manageClick(){
      this.isActive=true
    },
    backClick(){
      if( this.isActive==true){
         this.isActive=false
      }
    },
    addClick(){
      this.$router.push({path:'/mb/saosao',query: {iid:true}})
    },
    newGoods(){
      let newGoods=[]
      this.goods.forEach((val)=>{
        if(val.check==false){
          newGoods.push(val)
        }
      })
      this.goods=newGoods
    }
  }
}
</script>

<style scoped>
#MBLogo{
  width: 100vw;
  height: 100vh;
  margin-top: 20rem;
}
</style>