import {request} from './request'
//统一管理需要请求的组件
export function fplant(id_code){
    return request({
        url:'https://ncp.wbwl.cc/node/api/v1/consumers/sanf/productsqr',
        method:"GET",
        params:{
            id_code
        },
    })
}

export function mLogo(id_code){
    return request({
        url:'https://ncp.wbwl.cc/node/api/v1/consumers/add/OwnIdCode',
        method:"POST",
        data:{
            id_code
        },
    })
}
export function adviceAdd(title,content){
    return request({
        url: 'https://ncp.wbwl.cc/api/v1/sugges',
        method: 'POST',
        data: {
            "title": title,
            "content": content
        }
    })
}