<template>
  <!-- 详情展示功能弹窗 -->
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="false"
      top="20vh"
      :before-close="() => {this.$parent.dialogVisible = false}"
      center>
      <div class="mainBody">
        <div class="mainBodyLeft">
          <div class="mainBodyLeftHeader">
            <span>详情</span>
          </div>
          <div class="mainBodyLeftBodyer">
            <div class="comeFrom">
              <el-row>
                <el-col :span="4"><span>标题：</span></el-col>
                <el-col :span="20">
                  <div class="inputCss">
                    <slot name="title"></slot>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="time">
              <el-row>
                <el-col :span="4"><span>时间：</span></el-col>
                <el-col :span="20">
                  <div class="inputCss">
                    <slot name="send_time"></slot>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="contentb">
              <el-row>
                <el-col :span="4"><span>内容：</span></el-col>
                <el-col :span="20">
                  <div class="article">
                    <slot name="content"></slot>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  props: ['dialogVisible'],
  components:{
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang='scss' scoped>
/*弹框样式*/
.el-dialog__wrapper{
  /deep/.el-dialog__header{
    padding: 0;
  }
  /deep/.el-dialog__body{
    padding: 0;
  }
}

.mainBody {
  height: 60vh;
  font-size: 2rem;
  span{
    width: 80px;
  }
  .mainBodyLeft{
    width: 100%;
    height: 100%;
    float: left;
    padding-left: 5rem;
    .mainBodyLeftHeader{
      width: 100%;
      height: 10vh;
      line-height: 10vh;
      span {
        color: #555;
        font-weight: bold;
        font-size: 3rem;
      }
    }
    .mainBodyLeftBodyer{
      div{
        margin-bottom: 0.5vh;
      }
    }
  }
}
.contentb span,.comeFrom span,.time span {
  height: 4.5vh;
  line-height: 4.5vh;
}
.inputCss {
  float: left;
  height: 4.5vh;
  width: 90%;
  padding: 1.5rem 3rem 0 3rem;
  font-size: 1.5rem;
  border-radius: 15px;
  background-color: #eee;
}
.contentb .article {
  float: left;
  height: calc(60vh - 25vh);
  width: 90%;
  padding: 1.5rem 3rem 0 3rem;
  font-size: 1.5rem;
  border-radius: 15px;
  background-color: #eee;
  overflow: scroll;
}
</style>
