<template>
  <!-- 注册表单 -->
  <div class="formBody">
    <el-form :model="dataForm" :rules="rules" ref="dataForm" label-width="160px" class="demo-dataForm">
      <el-row>
        <!-- 左侧表单内容 -->
        <el-col :span="11">
          <div class="colLeft">
            <el-form-item label="企业机构名称" prop="bus_name">
              <el-input v-model="dataForm.bus_name" placeholder="请输入企业机构名称" maxlength="25"></el-input>
            </el-form-item>
            <el-form-item label="机构证件类型" prop="bus_lic_type_code">
              <el-select v-model="dataForm.bus_lic_type_code" placeholder="请选择">
                  <el-option v-for="item in certificateTypeList" :key="item.code" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="营业执照(小于1MB)" prop="bus_lic_img">
              <div v-if="!isImg1" class="imgno1" @click="$refs.uploadimg1.click()"> <i class="el-icon-plus"></i></div>
              <img v-if="isImg1" :src="imgUrl1" class="image1" @click="$refs.uploadimg1.click()">
              <input type="file" ref="uploadimg1" name="image1" style="display:none;" @change="updateChange($event,1)">
            </el-form-item>
            <el-form-item label="官方网站" prop="bus_web_url">
              <el-input v-model="dataForm.bus_web_url" placeholder="请输入官方网站"></el-input>
            </el-form-item>
            <el-form-item label="联系人姓名" prop="contact_name">
              <el-input v-model="dataForm.contact_name" placeholder="请输入联系人姓名" maxlength="18"></el-input>
            </el-form-item>
            <el-form-item label="联系人邮箱" prop="contact_mail">
              <el-input v-model="dataForm.contact_mail" placeholder="请输入联系人邮箱"></el-input>
            </el-form-item>
            <el-form-item label="法人证件类型" prop="leg_lic_type">
              <el-select v-model="dataForm.leg_lic_type" placeholder="请选择">
                  <el-option v-for="item in legalTypeList" :key="item.code" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="证件照片(小于1MB)" prop="leg_lic_img2">
              <el-form-item prop="leg_lic_img1" style="float:left; width:40%">
                <div v-if="!isImg2" class="imgno2" @click="$refs.uploadimg2.click()"> <i class="el-icon-plus"></i></div>
                <img v-if="isImg2" :src="imgUrl2" class="image2" @click="$refs.uploadimg2.click()">
                <input type="file" ref="uploadimg2" name="image2" style="display:none;" @change="updateChange($event,2)">
              </el-form-item>
              <el-form-item prop="leg_lic_img2" style="float:left; width:40%; margin-left: 2rem;">
                <div v-if="!isImg3" class="imgno3" @click="$refs.uploadimg3.click()"> <i class="el-icon-plus"></i></div>
                <img v-if="isImg3" :src="imgUrl3" class="image3" @click="$refs.uploadimg3.click()">
                <input type="file" ref="uploadimg3" name="image3" style="display:none;" @change="updateChange($event,3)">
              </el-form-item>
            </el-form-item>
          </div>
        </el-col>

        <!-- 分隔块 -->
        <el-col :span="2"><div style="height: 20rem;"></div></el-col>

        <!-- 右侧表单内容 -->
        <el-col :span="11">
          <div class="colRight">
            <el-form-item label="企业类型" prop="bus_type_code">
              <el-select v-model="dataForm.bus_type_code" placeholder="请选择">
                  <el-option v-for="item in enterprisesTypeList" :key="item.code" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="信用代码" prop="bus_lic_code">
              <el-input v-model="dataForm.bus_lic_code" placeholder="请输入信用代码"></el-input>
            </el-form-item>
            <el-form-item label="企业/机构行业" prop="sub_ind_code">
              <el-col :span="11">
                <el-form-item prop="industryBig">
                  <el-select style="width:100%" v-model="dataForm.industryBig" placeholder="行业大类" @change="changeIndustryBig">
                    <el-option v-for="item in industryBigList" :key="item.code" :label="item.name" :value="item.code"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="2"><div style="text-align:center">-</div></el-col>
              <el-col :span="11">
                <el-form-item prop="sub_ind_code">
                  <el-select style="width: 100%; float:right" v-model="dataForm.sub_ind_code" placeholder="行业小类">
                    <el-option v-for="item in industrySmallList" :key="item.code" :label="item.name" :value="item.code"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item label="注册所在地" prop="reg_area_code">
              <el-col :span="6">
                <el-form-item prop="province">
                  <el-select v-model="dataForm.province" placeholder="省" @change="changeProvince">
                    <el-option v-for="item in provinceList" :key="item.code" :label="item.name" :value="item.code"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="3"><div style="text-align:center">-</div></el-col>
              <el-col :span="6">
                <el-form-item prop="city">
                  <el-select v-model="dataForm.city" placeholder="市" @change="changeCity">
                    <el-option v-for="item in cityList" :key="item.code" :label="item.name" :value="item.code"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="3"><div style="text-align:center">-</div></el-col>
              <el-col :span="6">
                <el-form-item prop="reg_area_code">
                  <el-select v-model="dataForm.reg_area_code" placeholder="区">
                    <el-option v-for="item in areaList" :key="item.code" :label="item.name" :value="item.code"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item label="地址" prop="reg_address">
              <el-input v-model="dataForm.reg_address" placeholder="请输入地址" maxlength="50"></el-input>
            </el-form-item>
            <el-form-item label="公司备注" prop="bus_desc">
              <el-input type="textarea" v-model="dataForm.bus_desc" placeholder="请输入公司备注"></el-input>
            </el-form-item>
            <el-form-item label="联系人手机" prop="contact_phone">
              <el-input v-model="dataForm.contact_phone" placeholder="请输入联系人手机" maxlength="11"></el-input>
            </el-form-item>
            <el-form-item label="法人姓名" prop="leg_name">
              <el-input v-model="dataForm.leg_name" placeholder="请输入法人姓名" maxlength="18"></el-input>
            </el-form-item>
            <el-form-item label="法人证件号码" prop="leg_lic_num">
              <el-input v-model="dataForm.leg_lic_num" placeholder="请输入法人证件号码" maxlength="30"></el-input>
            </el-form-item>
          </div>
        </el-col>

        <!-- 按钮与声明 -->
        <el-col :span="24">
          <el-form-item class="colBottom">
            <el-checkbox v-model="accept">
              <span>我已阅读并接受</span>
              <a href="">《SNMS用户协议》</a>
              <span>及</span>
              <a href="">《SNMS隐私声明》</a>
            </el-checkbox>
            <br>
            <el-button class="submitButton" @click="submitForm">提交</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 上传用表单
      dataForm: {
        bus_name: '',
        bus_lic_type_code: '',
        bus_web_url: '',
        contact_name: '',
        contact_mail: '',
        leg_lic_type: '',
        bus_type_code: '',
        bus_lic_code: '',
        sub_ind_code: '',
        reg_area_code: '',
        reg_address: '',
        bus_desc: '',
        contact_phone: '',
        leg_name: '',
        leg_lic_num: '',
        bus_lic_img: '',
        leg_lic_img1: '',
        leg_lic_img2: '',
        industryBig: '',
        province: '',
        city: '',
      },
      rules: {
        bus_name: [
          { required: true, message: '请输入企业机构名称', trigger: 'blur' },
          { min: 3, max: 25, message: '企业名称不合法（3-25个字符）', trigger: 'blur' }
        ],
        bus_lic_type_code: [
          {required: true, message: '请选择机构证件类型', trigger: 'change'}
        ],
        contact_name: [
          {required: true, message: '请输入联系人姓名', trigger: 'blur'},
          { min: 2, max: 18, message: '联系人姓名不合法（2-18个字符）', trigger: 'blur' }
        ],
        contact_mail: [
          {required: true, message: '请输入联系人邮箱', trigger: 'blur'},
          { pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/, message: '请输入正确的邮箱格式',trigger: 'blur'}
        ],
        leg_lic_type: [
          {required: true, message: '请选择法人证件类型', trigger: 'change'}
        ],
        bus_type_code: [
          {required: true, message: '请选择企业类型', trigger: 'change'}
        ],
        bus_lic_code: [
          {required: true, message: '请输入信用代码', trigger: 'blur'}
        ],
        industryBig: [
          {required: true, message: '请选择行业大类', trigger: 'change'}
        ],
        sub_ind_code: [
          {required: true, message: '请选择行业小类', trigger: 'change'}
        ],
        province: [
          {required: true, message: '请选择省', trigger: 'change'}
        ],
        city: [
          {required: true, message: '请选择市', trigger: 'change'}
        ],
        reg_area_code: [
          {required: true, message: '请选择区', trigger: 'change'}
        ],
        reg_address: [
          {required: true, message: '请输入地址', trigger: 'blur'},
          { min: 3, max: 50, message: '企业详细地址不合法（3-50个字符）', trigger: 'blur' }
        ],
        contact_phone: [
          {required: true, message: '请输入联系人手机', trigger: 'blur'},
          { min: 11, max: 11, message: '请输入正确的11位手机号', trigger: 'blur' }
        ],
        leg_name: [
          {required: true, message: '请输入法人姓名', trigger: 'blur'},
          { min: 2, max: 18, message: '法人姓名不合法（2-18个字符）', trigger: 'blur' }
        ],
        leg_lic_num: [
          {required: true, message: '请输入法人证件号码', trigger: 'blur'},
          { min: 8, max: 30, message: '法人证件号码不合法（8-30个字符）', trigger: 'blur' }
        ],
        bus_lic_img: [
          {required: true, message: '请上传营业执照', trigger: 'change'}
        ],
        leg_lic_img1: [
          {required: true, message: '请上传证件正面照片', trigger: 'change'}
        ],
        leg_lic_img2: [
          {required: true, message: '请上传证件背面照片', trigger: 'blur,change'}
        ]
      },

      // 下拉框列表数据
      certificateTypeList: [],
      legalTypeList: [],
      enterprisesTypeList: [],
      industryBigList: [],
      industrySmallList: [],
      provinceList:[],
      cityList: [],
      areaList: [],

      //图片展示
      imgUrl1: require('@/assect/uploadImg.jpg'),
      isImg1: false,
      imgUrl2: require('@/assect/uploadImg.jpg'),
      isImg2: false,
      imgUrl3: require('@/assect/uploadImg.jpg'),
      isImg3: false,
      accept: false
    };
  },
  beforeMount () {
    this.queryCertificateType()
    this.queryLegalType()
    this.queryEnterprisesType()
    this.queryIndustry()
    this.queryAdministration()
  },
  methods: {
    /**
     * 查询机构证件类型
     */
    queryCertificateType () {
      this.$http({
        url: '/api/v1/business/license/type/list',
        method: 'GET',
      }).then(res => {
        if(res.data.code){
          this.certificateTypeList = res.data.data
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
          this.$message.error('网络错误');
      })
    },

    /**
     * 查询法人证件类型
     */
    queryLegalType () {
      this.$http({
        url: '/api/v1/business/legal/license/type/list',
        method: 'GET',
      }).then(res => {
        if(res.data.code){
          this.legalTypeList = res.data.data
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
          this.$message.error('网络错误');
      })
    },

    /**
     * 查询企业类型
     */
    queryEnterprisesType () {
      this.$http({
        url: '/api/v1/business/type/list',
        method: 'GET',
      }).then(res => {
        if(res.data.code){
          this.enterprisesTypeList = res.data.data
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
          this.$message.error('网络错误');
      })
    },

    /**
     * 查询行业大类
     */
    queryIndustry () {
      this.$http({
        url: '/api/v1/business/industry/list',
        method: 'GET',
      }).then(res => {
        if(res.data.code){
          this.industryBigList = res.data.data
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
          this.$message.error('网络错误');
      })
    },

    /**
     * 选择大类后查询小类
     */
    changeIndustryBig () {
      this.$http({
        url: '/api/v1/business/industry/list',
        method: 'GET',
        params:{
          code: this.dataForm.industryBig
        }
      }).then(res => {
        if(res.data.code){
          this.industrySmallList = res.data.data
          this.dataForm.sub_ind_code = '';
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
          this.$message.error('网络错误');
      })
    },

    /**
     * 
     * 查询省份
     */
    queryAdministration () {
      this.$http({
        url: '/api/v1/region/list',
        method: 'GET',
      }).then(res => {
        if(res.data.code){
          this.provinceList = res.data.data
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
          this.$message.error('网络错误');
      })
    },

    /**
     * 选择省份后查询市
     */
    changeProvince () {
      this.$http({
        url: '/api/v1/region/list',
        method: 'GET',
        params:{
          code: this.dataForm.province
        }
      }).then(res => {
        if(res.data.code){
          this.cityList = res.data.data
          this.dataForm.city = '';
          this.areaList = [];
          this.dataForm.reg_area_code = '';
          this.streetList = [];
          this.dataForm.street = '';
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
          this.$message.error('网络错误');
      })
    },

    /**
     * 选择市后查询区
     */
    changeCity () {
      this.$http({
        url: '/api/v1/region/list',
        method: 'GET',
        params:{
          code: this.dataForm.city
        }
      }).then(res => {
        if(res.data.code){
          this.areaList = res.data.data
          this.dataForm.reg_area_code = '';
          this.streetList = [];
          this.dataForm.street = '';
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
          this.$message.error('网络错误');
      })
    },

    /**
     * 图片的上传
     */
    updateChange (file,index) {
      let image = file.target.files[0]
      file.path[0].value = ''
      let reader = new FileReader();

      // 文件大小判断
      if(image.size > 1048576){
        this.$message.error('图片大于1MB！请重新上传！')
      }else{
        // index用于区分上传的是哪一位置的图片
        if(index == '1'){
          this.isImg1 = true;
          reader.readAsDataURL(image)
          reader.onload = (e) => {
            this.dataForm.bus_lic_img = e.target.result
            this.imgUrl1 = e.target.result
            this.$refs['dataForm'].validateField("bus_lic_img")
          }
        }else if(index == '2'){
          this.isImg2 = true;
          reader.readAsDataURL(image)
          reader.onload = (e) => {
            this.dataForm.leg_lic_img1 = e.target.result
            this.imgUrl2 = e.target.result
            this.$refs['dataForm'].validateField("leg_lic_img1")
          }
        }else{
          this.isImg3 = true;
          reader.readAsDataURL(image)
          reader.onload = (e) => {
            this.dataForm.leg_lic_img2 = e.target.result
            this.imgUrl3 = e.target.result
            this.$refs['dataForm'].validateField("leg_lic_img2")
          }
        }
      }
      
    },

    /**
     * 提交表单信息
     */
    submitForm() {
      if(this.accept == true){
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            //表单验证通过
            this.$http({
              url: '/api/v1/business/register/info/snms',
              method: 'POST',
              data: this.dataForm
            }).then(res => {
              if(res.data.code === 200){
                this.$message.success(res.data.msg)
              }else{
                this.$message.error(res.data.msg)
              }
            }).catch(() => {
              this.$message.error('网络错误');
            })
          } else {
            this.$message.error('填写不规范!')
            return false;
          }
        });
      }else{
        this.$message.error('请先阅读声明并勾选已阅读!')
      }
    }
  }
}
</script>


<style scoped>
.formBody {
  width: 100%;
  height: 100%;
  padding: 5rem 6rem 0 6rem;
}
.colBottom {
  /* margin-top: 5rem; */
  text-align: center;
}
a {
  text-decoration: none;
  color: #409EFF;
}
.submitButton {
  padding: 1rem 1.5rem 1rem 1.5rem;
  font-size: 2rem;
}
/* 图片上传样式 */
.imgno1 {
  height: 15rem;
  width: 40%;
  cursor: pointer;
  text-align: center;
  line-height: 15rem;
  border: 1px dashed #DCDFE6;
  border-radius:10px;
  margin-bottom: 15px;
}
.image1 {
  height: 15rem;
  width: 40%;
  border-radius:10px;
  object-fit: contain;
  cursor: pointer;
}
.imgno2 {
  height: 15rem;
  width: 100%;
  cursor: pointer;
  text-align: center;
  line-height: 15rem;
  border: 1px dashed #DCDFE6;
  border-radius:10px;
  float: left;
}
.image2 {
  height: 15rem;
  width: 100%;
  border-radius:10px;
  object-fit: contain;
  cursor: pointer;
  float: left;
}
.imgno3 {
  height: 15rem;
  width: 100%;
  cursor: pointer;
  text-align: center;
  line-height: 15rem;
  border: 1px dashed #DCDFE6;
  border-radius:10px;
  float: left;
}
.image3 {
  height: 15rem;
  width: 100%;
  border-radius:10px;
  object-fit: contain;
  cursor: pointer;
  float: left;
  margin-left: 2rem;
}
</style>