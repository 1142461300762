<template>
  <el-tree 
  style="padding: 10px; overflow:scroll; height: 67vh" 
  ref="refTree"
  :props="defaultProps" 
  :load="loadNode"
  lazy
  accordion
  node-key="name"
  :highlight-current="true"
  @node-click="handleNodeClick">
    <span class="span-ellipsis" slot-scope={data}>
      <span :title="data.name">{{data.name}}</span>
    </span>
  </el-tree>
</template>

<script>
  export default {
    data() {
      return {
        treeData: [],
        defaultProps: {
          label: 'name',
          children: 'data',
          isLeaf: 'leaf'
        }
      };
    },
    methods: {
      async loadNode(node, resolve) {
        if (node.level === 0) {
          let data = await this.queryName()
          return resolve(data);
        }else if(node.level === 1){
          let data = await this.queryID(node.label)
          return resolve(data);
        }else if(node.level === 2){
          let data = await this.queryNum(node.label)
          return resolve(data);
        }else if(node.level === 3){
          let data = await this.queryWarning(node.parent.label,node.label)
          return resolve(data);
        }
      },

      /**
       * 获取商家已注册的产品名称
       */
      async queryName() {
        let treData = []
        await this.$http({
          url: this.Configs.adminUrl1 + '/api/v1/products/search/existproductsname',
          method: 'GET'
        }).then(res => {
          if(res.data.code === 200){
            res.data.data?.forEach((element) => {
              treData.push({name: element})
            })
          }else{
            this.$message.error(res.data.msg)
          }
        }).catch(() => {
            this.$message.error('网络错误');
        })
        return treData
      },

      /**
       * 通过产品名称获取商家已注册的产品名称id
       */
      async queryID(products_type_name) {
        let treData = []
        await this.$http({
          url: this.Configs.adminUrl1 + '/api/v1/products/search/existproductsid',
          method: 'GET',
          params:{
            products_type_name:products_type_name
          }
        }).then(res => {
          if(res.data.code === 200){
            res.data.data?.forEach((element) => {
              treData.push({name: element})
            })
          }else{
            this.$message.error(res.data.msg)
          }
        }).catch(() => {
            this.$message.error('网络错误');
        })
        return treData
      },

      /**
       * 通过产品名称获取商家已注册的产品名称id
       */
      async queryNum(products_type_id) {
        let treData = []
        await this.$http({
          url: this.Configs.adminUrl2 + '/api/v2/products/see/products/batch',
          method: 'GET',
          params:{
            products_type_id:products_type_id
          }
        }).then(res => {
          if(res.data.code === 200){
            res.data.data?.forEach((element) => {
              treData.push({name: element.batch})
            })
          }else{
            this.$message.error(res.data.msg)
          }
        }).catch(() => {
            this.$message.error('网络错误');
        })
        return treData
      },

      /**
       * 预警有异地扫码的标识
       */
      async queryWarning(products_type_id,batch) {
        let treData = []
        await this.$http({
          url: this.Configs.adminUrl1 + '/api/v1/products/bad/products',
          method: 'GET',
          params:{
            products_type_id:products_type_id,
            batch:batch
          }
        }).then(res => {
          if(res.data.code === 200){
            res.data.data?.forEach((element) => {
              treData.push({name: element, leaf: true})
            })
          }else{
            this.$message.error(res.data.msg)
          }
        }).catch(() => {
            this.$message.error('网络错误');
        })
        return treData
      },
    
      /**
       * 点击事件
       */
      handleNodeClick(data,node) {
        this.$parent.$children[1].clearAddress() //清空地图坐标数据
        if(node.level === 4){
          this.$parent.$children[1].queryAddress(node.parent.parent.label,node.parent.label,data.name)//获取坐标点
        }
      }
    }
  }
</script>

<style scoped>
.el-tree--highlight-current >>> .el-tree-node.is-current>.el-tree-node__content {
  background-color: #c2d5eb;
}
.span-ellipsis {
  display: block;
  width: 500px;
  font-size: 1.5vh;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>