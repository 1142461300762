<template>
  <div id="home-introduction" class="content-box">
    <!-- 标题 -->
    <div class="content-title">
      <h1 class="title">农产品质量追溯平台</h1>
    </div>
    <!-- one -->
    <div class="one">
      <div class="one-left">
        <img class="img1" src="../../../../assect/enterHomeImg/jieshao/6.png" alt="" srcset="">
      </div>
      <div class="one-right">
        <h2 class="title">产 <br> 品<br>设<br>计<br>理<br>念</h2>
        <p class="text1">
          一直以来，建立农产品质量安全追溯体系，
          是创新农产品质量安全监管方式的重要举措，
          对进一步提升农产品质量安全监管能力、落实生产经营主体责任、
          增强食用农产品消费信心具有重要意义。
          本公司围绕“从农田到餐桌”的安全管理理念，
          研发的“基于标识解析技术的农产品质量追溯平台”，以标识解析技术为核心，
          对接国家工业互联网体系，赋予农产品标识编码，实现产品智能化识别和追溯。
          根据“一物一码”标准，在农产品生产、加工、
          仓储、物流到销售等追溯环节设置相关负责人以及数据采集点，
          根据国际编码标准在各追溯节点设计对应的追溯编码，
          利用射频技术以及条码技术实现数据的自动采集，将各关键节点进行串联形成完整的追溯链，
          得到完整的追溯信息，既可为消费者提供透明的产品信息，又实现了政府部门对农业生产、
          流通等环节信息的溯源管理和决策的依据，
          同时又为企业建立了包含生产、物流、销售的可信流通体系。
        </p>
      </div>
    </div>

    <!-- two -->
    <div class="two">
      <div class="two-left">
        <h2 class="title">产品运营体系</h2>
          <p class="text1">
          平台对接互联网标识解析系统，通过互联网唯一标识追溯农产品。
          给消费者提供完整的农产品生命周期信息，给生产者提供消费者信息图谱，遇到问题方便溯源与追责。
          </p>
        </div>
      <div class="two-right">
        <div class="img1-box">
          <img class="img1" src="../../../../assect/enterHomeImg/jieshao/5.png" alt="" srcset="">
        </div>
        <div class="img2-box">
          <img class="img2" src="../../../../assect/enterHomeImg/jieshao/4.1.png" alt="" srcset="">
        </div>
      </div>
    </div>
    <!-- three -->
    <div class="three">
      <h2 class="title">三端分离方案</h2>
      <div class="three-content">
        <div class="three-left">
          <p class="text1">
            管理端:<br>
            监管商品、接纳投诉建议、定期巡检商品、审核商家、赋予商家国家工业互联网唯一前缀。
          </p>
          <div class="img1-box">
            <img class="img1" src="../../../../assect/enterHomeImg/jieshao/7.png" alt="" srcset="">
          </div>
        </div>
        <div class="three-center">
          <p class="text1">
            消费端<br/>
            农产品扫码、产品信息查询、产品追溯、农产品生长环境实时信息查询等。
          </p>
          <div class="img1-box">
            <img class="img1" src="../../../../assect/enterHomeImg/jieshao/9.jpg" alt="" srcset="">
          </div>
        </div>
        <div class="three-right">
          <p class="text1">
            商家端:<br/>
            农产品注册、农产品位置分布图、扫码时间统计、产品售后信息反馈及建议、农产品防伪等。
          </p>
          <div class="img1-box">
            <img class="img1" src="../../../../assect/enterHomeImg/jieshao/8.png" alt="" srcset="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home-introduction'
}
</script>

<style lang='scss' scoped>
//公共样式
.text1{
    width: 100%;
    font-size: 0.9vw;
    font-weight: bolder;
    overflow: auto;
    scrollbar-width: none;
}
img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
::-webkit-scrollbar{
  display: none;
}
//标题部分
.content-box{
  width: 85%;
  height: auto;
  margin: 0 auto;
  .content-title{
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    .title{
      font: {
        size: 1.1vw;
        weight: 550;
      }
      color: rgb(85, 85, 85);
    }
  }
}
//one 部分
.one{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 26px 0;
  .one-left{
    flex: 1;
  }
  .one-right{
    flex: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    .title{
      padding-top: 2vh;
      flex: 1;
      font-size: 0.92vw;
      font-weight: 550;
    }
    .text1{
      flex: 11;
      text-align: left;
      text-indent: 1.86vw;
      word-wrap: break-word;
      word-break: break-all;
      font-weight: 450;
      color: rgb(85, 85, 85);
    }
  }
}
//two部分
.two {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
  .two-left{
    flex: 1;
    padding-bottom: 8vh;
    .title{
      flex: 1;
      height: 8vh;
      line-height: 8vh;
      font-weight: 550;
      font-size: 0.92vw;
    }
    .text1 {
      flex: 1;
      text-align: left;
      text-indent: 1.86vw;
      word-wrap: break-word;
      word-break: break-all;
      font-weight: 450;
      color: rgb(85, 85, 85);
    }
  }
  .two-right{
    flex: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .img1-box{
      padding: 0 10px;
      flex: 1;
    }
    .img2-box{
      padding: 0 10px;
      flex: 1;
    }
  }
}
//three 部分
.three{
  width: 100%;
  height: 55vh;
  display: grid;
  grid-template-rows: 1fr auto;
  align-items: start;
}
.three > .title{
  width: 100%;
  height: 10vh;
  line-height: 10vh;
  font-size: 0.92vw;
}
.three-content{
  width: 100%;
  height: 43vh;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 10px;
  justify-items: center;
  align-content: center;
 
}
.three-left{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.three-left > .text1{
  width: 100%;
  height: 10vh;
  text-align: left;
  font-weight: 450;
  color: rgb(85, 85, 85);
}
.three-left > .img1-box{
  width: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 22px 0;
}
.three-right{
  width: 100%;
  height: 100%;
}
.three-right > .text1{
  width: 100%;
  height: 10vh;
  text-align: left;
  font-weight: 450;
  color: rgb(85, 85, 85);
}
.three-right > .img1-box{
  text-align: center;
  vertical-align: middle;
  padding: 22px 0;
  width: 100%;
}
.three-center{
  width: 100%;
  height: 100%;
}
.three-center > .text1{
  width: 100%;
  height: 10vh;
  text-align: left;
  font-weight: 450;
  color: rgb(85, 85, 85);
}
.three-center > .img1-box{
  text-align: center;
  vertical-align: middle;
  width: 100%;
  padding: 22px 0;
}
.three-center > .img1-box > .img1{
  width: 27.19%;
  object-fit: contain;
}
</style>