<template>
  <div class="content">
    <div class="title">
      <label>
        <slot name="title-label"/>
      </label>
    </div>
    <div class="rg-table">
    <el-table
    height="61vh"
    :stripe="true"
    :data="tableData"
    border
    style="width: 100%;">
    <el-table-column
    v-for="(item,index) in headArray"
    :key="index"
    class-name="col-style"
      fixed
      :prop="item.prop"
      :label="item.label"
      >
    </el-table-column>
    <slot name="cao-zuo"></slot>
  </el-table>
    </div>
  </div>
</template>

<script>
export default {
    props:["headArray","tableData"],
    data() {
      return {
        
      }
    }
}
</script>

<style scoped>
.content{
    width: 100%;
    height: 100%;
}
.title{
  font-size: 1.2vw;
  font-weight: 600;
}
.rg-table{
  margin-top: 2vh;
}
.el-table >>> .col-style{
  text-align: center;
  font-size: 0.6vw;
}
</style>