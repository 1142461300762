<template>
  <!-- 数据展示表格 -->
  <div class="complaintTable">
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      height="100%"
      style="width: 100%"
      @row-click="checkOne">
      <el-table-column
        type="selection">
      </el-table-column>
      <el-table-column
        prop="id"
        label="对象">
      </el-table-column>
      <el-table-column
        prop="send_id"
        label="投诉人">
      </el-table-column>
      <el-table-column
        prop="title"
        label="标题">
      </el-table-column>
      <el-table-column
        prop="send_time"
        label="时间">
      </el-table-column>
      <el-table-column
        label="状态"
        width="200px">
        <template slot-scope="scope">
            <el-tag
            v-if="scope.row.status === 1"
            type="primary"
            disable-transitions>已处理</el-tag>
            <el-tag
            v-if="scope.row.status === 0"
            type="success"
            disable-transitions>未处理</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <complaint-dialog :dialogVisible="dialogVisible" :id="id" ref="complaintDialog" v-if="detailsData != {}">
        <span slot="title">{{detailsData.title}}</span>
        <span slot="send_time">{{detailsData.send_time}}</span>
        <span slot="content">{{detailsData.content}}</span>
      </complaint-dialog>
      
      
      <complaint-dialogs :dialogVisibles="dialogVisibles" ref="complaintDialogs" v-if="detailsData != {}">
        <span slot="title">{{detailsData.title}}</span>
        <span slot="send_time">{{detailsData.send_time}}</span>
        <span slot="content">{{detailsData.content}}</span>
        <span slot="handler_id">{{detailsData.handler_id}}</span>
        <span slot="handle_time">{{detailsData.handle_time}}</span>
        <span slot="handle_msg">{{detailsData.handle_msg}}</span>
      </complaint-dialogs>
    </div>
  </div>
</template>

<script>
import ComplaintDialog from './pc-complaint-table-dialog'
import ComplaintDialogs from './pc-complaint-table-dialog-s'

export default {
  components: {
    ComplaintDialog,
    ComplaintDialogs
  },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      dialogVisibles: false,
      detailsData: {},
      id: ''
    }
  },
  beforeMount() {
    this.queryData(1,null,null)
  },
  methods: {
    /**
     * 查询数据
     */
    queryData(page,status,title,send_id) {
      this.$http({
        url: '/api/v1/sugges/list',
        methods: 'GET',
        params:{
          page: page,
          number: 10,
          status: status,
          title: title,
          send_id: send_id
        }
      }).then(res => {
        if(res.data.code === 200){
          this.$parent.page = res.data.data.page
          this.$parent.total = res.data.data.total
          this.$parent.pages = res.data.data.pages
          this.tableData = res.data.data.data
        }else{
          this.$message.error(res.data.msg)
          this.tableData = []
          this.$parent.page = 0
          this.$parent.pages = 0
        }
      }).catch(() => {
        this.$message.error('网络错误')
      })
    },
    
    /**
     * 删除选中
     */
    async deleteSelect() {
      //获取已选中的数据
      let isSelect = document.getElementsByClassName('el-checkbox__original')
      let id_list = []
      await isSelect.forEach((element,index) => {
        if(index != 0){
          if(element.checked == true){
            id_list.push(this.tableData[index-1].id);
          }
        }
      });
      // 删除请求
      this.$http({
        url: '/api/v1/sugges',
        method: 'DELETE',
        data:{
          id: id_list
        }
      }).then(res => {
        if(res.data.code === 200){
          this.$message.success(res.data.msg)
          this.queryData(1,null,null)
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误')
      })
    },

    /**
     * 查看单项详情
     */
    checkOne(val) {
      this.id = val.id
      this.$http({
        url: '/api/v1/sugges',
        method: 'GET',
        params:{
          id: val.id
        }
      }).then(res => {
        if(res.data.code === 200){
          this.detailsData = res.data.data
          if(val.status === 1){
            this.dialogVisibles = true
          }else if(val.status === 0){
            this.dialogVisible = true
          }
          
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误')
      })
    }
  }
}
</script>

<style scoped>
.complaintTable {
  width: 100%;
  height: 100%;
}
.el-table >>> .cell {
  text-align: center;
  height: 3vh;
  line-height: 3vh;
}
.el-table >>> .el-table__header-wrapper .cell {
  color: black;
}
</style>