export default{
    adminUrl: 'https://ncp.wbwl.cc',
    adminUrl1: 'https://ncp.wbwl.cc/node',
    adminUrl2: 'https://ncp.wbwl.cc/go',
    // 测试用地址
    // adminUrl: 'http://localhost:8080',  //本地
    // adminUrl: 'http://192.168.1.10:8080',  //熊
    // adminUrl: 'http://192.168.10.10:8080', //加桥
    // adminUrl1: 'http://192.168.1.14:8099',
    // adminUrl2: 'http://192.168.10.10:8101',
    // adminUrl2: 'http://localhost:8101',
}