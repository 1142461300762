<template>
  <!-- 安全问题 -->
  <div class="security">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
      <el-form-item type="password" label="新密码：" prop="newPassword">
        <el-input v-model="ruleForm.newPassword" type="password"></el-input>
      </el-form-item>
      <el-form-item type="password" label="再次输入：" prop="newPasswordQ">
        <el-input v-model="ruleForm.newPasswordQ" type="password" @keyup.native.enter="submitForm"></el-input>
      </el-form-item>
      <el-form-item class="buttonb">
        <el-button class="buttonSub" @click="submitForm">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {sha256} from 'js-sha256'

export default {
  data() {
    const isEqually = (rule, value, callback) => {
      if(this.ruleForm.newPassword != '' && this.ruleForm.newPasswordQ != ''){
        if(this.ruleForm.newPassword == this.ruleForm.newPasswordQ){
          callback()
        }else{
          callback(new Error('两次输入不一致'))
        }
      }else{
        callback()
      }
    }
    return {
      ruleForm: {
        newPassword: '',
        newPasswordQ: ''
      },
      rules: {
        newPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { type: 'string', min: 5, message: '密码至少5位', trigger: 'blur' },
          { validator: isEqually, trigger: 'blur'}
        ],
        newPasswordQ: [
          { required: true, message: '请输入再次密码', trigger: 'blur' },
          { type: 'string', min: 5, message: '密码至少5位', trigger: 'blur' },
          { validator: isEqually, trigger: 'blur'}
        ]
      }
    };
  },
  methods: {
    /**
     * 提交修改信息
     */
    submitForm () {
      if(this.ruleForm.newPasswordQ != '' && this.ruleForm.newPassword != '' && this.ruleForm.newPassword == this.ruleForm.newPasswordQ){
        this.$http({
          url: '/api/v1/user/single/password',
          method: 'PUT',
          data: {
            password: sha256(this.ruleForm.newPasswordQ)
          }
        }).then( res => {
          if(res.data.code ===200){
            this.$message.success(res.data.msg)
          }else{
            this.$message.error(res.data.msg)
          }
        }).catch(() => {
          this.$message.error('网络错误')
        })
      }else{
        this.$message.error('输入信息不合法')
      }
      
    }
  }
}
</script>
<style scoped>
.security {
  margin: 0 auto;
  width: 40%;
  margin-top: 15vh;
}
.el-form-item >>>.el-form-item__label {
  font-size: 2rem !important;
  font-weight: bold;
}
.buttonb {
  position: absolute;
  bottom: 25vh;
}
.buttonSub {
  margin-top: 10vh;
  border: 1px solid #009966;
  color: #009966;
}
.buttonSub:hover {
  border: 1px solid #009966;
  color: #009966;
  background-color: #e6e9ee !important;
}
</style>