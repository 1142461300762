<!--
 * @Description: 
 * @Version: 1.0
 * @Author: AndBridge
 * @LastEditors: AndBridge
-->
<template>
  <div id="sweep-code-results">
    <div class="sweep-code-results-public-head">
      <div class="logo">
        <img src="../../../assect/pageHead/titleLogo.jpg" />
      </div>
    </div>
      <div class="tab-and-content">
      <TabAndContent>
      <!--基本信息-->
      <template #product-information>
        <ProductInformation
              :productInformation="sweepCodeData.productInformation"
              :traceabilityInformation="sweepCodeData.traceabilityInformation"
              :companyIntroduction="sweepCodeData.companyIntroduction"/>
      </template>
      <!--溯源信息-->
      <template #traceability-information>
         <TraceabilityInformation
          :traceabilityInformation="sweepCodeData.traceabilityInformation"/>
      </template>
      <!--企业信息-->
      <template #company-introduction>
        <CompanyIntroduction
          :companyIntroduction="sweepCodeData.companyIntroduction"/>
      </template>
        </TabAndContent>
      </div>
    </div>
</template>

<script>
import TabAndContent from "../../../components/TabAndContent";
import ProductInformation from "../../../components/ProductInformation";
import TraceabilityInformation from "../../../components/TraceabilityInformation";
import CompanyIntroduction from "../../../components/CompanyIntroduction";
export default {
  components:{
    ProductInformation,
    TraceabilityInformation,
    CompanyIntroduction,
    TabAndContent
  },
  data(){
    return{
      sweepCodeData: {
        productInformation:{},
        companyIntroduction:{},
        traceabilityInformation:{}
      }
      }
  },
   async beforeMount(){
    await this.SweepCodeContentDataRequest()
  },
  methods:{
      async SweepCodeContentDataRequest(){
      this.id_code = this.$route.query.id_code
      //id_code为'0000'时--进行预览查看，否则进行扫码查看
      if(this.$route.query.id_code === '0000'){
        this.sweepCodeData = JSON.parse(sessionStorage.getItem("templateData"))
       
        this.products_content='成功生成标识后显示'
      }else{
        try {
          let {data:{data}}=await this.$http({
          url: this.Configs.adminUrl1 + '/api/v1/consumers/sanf/productsqr',
          method: 'GET',params:{id_code: this.$route.query.id_code}})
          this.sweepCodeData=data.data
          this.sweepCodeData.productInformation.scanNum=data.qr_num;
          if(data.products_status==0){
            this.sweepCodeData.productInformation.isAudit="产品未审核"
          }
          this.sweepCodeData.productInformation.productCode=data.id_code
          this.sweepCodeData.productInformation.scanNum=data.qr_num
        } catch (error) {
          alert("网络错误")
          throw error;
        }
    }
    }
    }
}
</script>

<style scoped>
.sweep-code-results{
  width: 100%;
  height: 100%;
}
.sweep-code-results-public-head{
  width: 100%;
  height: 100%;
}
.logo{
  width: 100%;
  height: 15vh;
}
.logo > img{
  width: 100%;
  height: 100%;
  object-fit: inherit;
}
.tab-and-content{
  width: 100%;
  height: 100%;
}
.sweep-code-tab{
  width: 100%;
  height: 5.5vh;
  display: flex;
  align-items: center;
  justify-items: center;
  vertical-align: middle;
  background: white;
  box-sizing: border-box !important;
  line-height: 4.9vh;
  padding-bottom: 1.6vh;
  border-bottom: 1px solid whitesmoke;
}
.tab{
  flex: 1;
  height: 100%;
  text-align: center;
  margin: 0 !important;
  padding: 0 !important;
}
.tab > a{
    width: 100%;
    height: 100%;
    display: inline-block;
    text-decoration: none;
    font-size: 3.8vw;
    color: #7e7e7e;
    font-weight: bold;
}
.sweep-code-content{
  margin-top: 2vh;
  position: absolute;
  top: 20.5vh;
  left: 0;
  right: 0;
}
.product-information
.traceability-information
.company-information{
  width: 100%;
  height: auto;
}
.feedback{
  position: absolute;
  width: 100%;
  height: 25vh;
  padding: 0 5vw;
  background: #f7f7f7;;
}
</style>