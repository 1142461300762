/* 引入vue */
import Vue from 'vue'
Vue.config.productionTip = false
/* 引入vue-router */
import VueRouter from 'vue-router';
/* 安装vue-router */
Vue.use(VueRouter);
/* 引入路由文件 */
import router from './router/router';
/* 引入入口组件 */
import App from './App.vue';

// 引入elementui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//fjh 创建的toast 组件
import toast from './components/Wb-Mb-Toast/index'
//安装toast插件
Vue.use(toast)

// 引入echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

import AmapVueConfig from '@amap/amap-vue/lib/config';
AmapVueConfig.key = '16a302b5bbfb4ecd11a3738d9e6b3552';

// 引入vuex
import store from '@/store';

// 引入icon
import "@/assect/wb-icon/iconfont.css";
import "@/assect/sweep-icon/iconfont.css"

// 引入axios
import axios from 'axios'
import  config  from './config/config';

import AmapVue from '@amap/amap-vue';

// 原型绑定Axios(Ajax)
Vue.prototype.$http = axios
Vue.prototype.Configs = config
// 配置全局默认URI
axios.defaults.baseURL = config.adminUrl

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  if(response.data.code == 205) {
    let path = router.history.current.fullPath
    if(path.slice(0,3)=="/pc"){
      router.push('/pc/loading');
    }else if(path == "/mb"){
      router.push('/mb');
    }
  }
  
  return response;
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
});


//导航守卫
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
      //这里判断用户是否登录，验证本地存储是否有token
      if (!sessionStorage.getItem("token")) { // 判断当前的token是否存在
          next({
              path: '/mb/login',
              query: { redirect: to.fullPath }
          })
      } else {
          next()
      }
  } else {
      next() // 确保一定要调用 next()
  }
})

//地图配置
AmapVue.config.version = '2.0'; // 默认2.0，这里可以不修改
AmapVue.config.key = '5ed9503d2bcfc402c27a91d0e16fddbc';
AmapVue.config.plugins = [
  'AMap.ToolBar',
  'AMap.MoveAnimation',
  // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
];
Vue.use(AmapVue);












/* FJH */

//终极解决多次触发push或者repalce，报错的问题
//NavigationDuplicated

const originPush = VueRouter.prototype.push
const originReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function(location,onResolved,onRejected){
  if(onResolved === undefined && onRejected === undefined){
    // originPush.call目的是让VueRouter实例化对象去调用
    //如果不加，那就是window在调用
    return originPush.call(this,location).catch(() => {})
  }else{
    return originPush.call(this,location,onResolved,onRejected)
  }
}

VueRouter.prototype.replace = function(location,onResolved,onRejected){
  if(onResolved === undefined && onRejected === undefined){
    // originPush.call目的是让VueRouter实例化对象去调用
    //如果不加，那就是window在调用
    return originReplace.call(this,location).catch(() => {})
  }else{
    return originReplace.call(this,location,onResolved,onRejected)
  }
}

/* 创建Vue对象 */
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
