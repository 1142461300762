<template>
  <!-- 图片库 -->
  <div class="PhotoGallery">
    <div class="photoGalleryLeft">
      <photo-gallery-left></photo-gallery-left>
    </div>
    
    <div class="photoGalleryRight">
      <div class="buttonTop">
        <el-button style="left: 20px;" @click="toUpdate">上传</el-button>
        <el-button @click="toDelete">删除</el-button>
      </div>
      <photo-gallery-right ref="imgR"></photo-gallery-right>
    </div>
  </div>
</template>
<script>
import PhotoGalleryLeft from './modules/pc-photogallery-left'
import PhotoGalleryRight from './modules/pc-photogallery-right'

export default {
  components: {
    PhotoGalleryLeft,
    PhotoGalleryRight
  },
  data() {
    return {
    }
  },
  methods: {
    toDelete () {
      this.$refs.imgR.toDelete();
    },
    toUpdate () {
      this.$refs.imgR.toUpdate();
    }
  }
}
</script>


<style scoped>
.PhotoGallery {
  width: 100%;
  height: 100%;
  position: relative;
}
.photoGalleryLeft {
  width: 15vw;
  height: 100%;
  float: left;
  border-right: 2px solid #555;
}
.photoGalleryRight {
  width: calc(100% - 15vw - 2px);
  height: 100%;
  float: left;
}
.buttonTop {
  height: 50px;
  position: relative;
}
.buttonTop button {
  width: 70px;
  height: 40px;
  border: 1px solid #009966;
  color: #009966;
  position: absolute;
  right: 20px;
  top: 5px;
}
.buttonTop button:hover {
  background-color: #e6e9ee;
}
</style>
