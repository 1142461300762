//手动制作一个全局的toast插件
import Toast from './Toast.vue'
const obj = {}
//install函数会自动传入vue参数
obj.install = function (Vue) {
    // 1.创建组件构造器
    const toastContrustor = Vue.extend(Toast)
    // 2.new 的方式，根据组件构造器，可以创造出一个组件对象
    const toast = new toastContrustor
    // 3.将组件构造器手动挂载到某一个元素上
    toast.$mount(document.createElement('div'))
    // 4.toast.$el对应的就是这个div
    document.body.appendChild(toast.$el)

    Vue.prototype.$toast = toast

}
export default obj