<template>
  <div id="mbProfitChild">
      <div class="checked" :class="{activeStyle:isActive,bgcStyle:item.check}" @click="changClick" ></div>
      <el-collapse>
        <el-collapse-item :title=item :name="index">
            <div></div>
        </el-collapse-item>
      </el-collapse>
  </div>
</template> 

<script>
export default {
    name:'mbProfitChild',
    data(){
        return {
            
        }
    },
    props:{
        item:{
            type:Object
        },
        index:{
            type:Number
        },
        isActive:{
            type:Boolean
        }
    },
    
    methods:{
        changClick(){
            this.item.check=!this.item.check
        }
    }
}
</script>

<style>
 #mbProfitChild {
  width: 100%;
  height: auto;
  margin-left: 50%;
  transform: translate(-50%);
}

.el-collapse-item__header{
    position: relative;
    padding-left: 10rem;
    font-size: 5.6rem  !important;
}
.checked{
    position: absolute;
    width: 15px;
    height: 15px;
    left: 1rem;
    top: 18px;
    z-index: 9;
    border: 1px solid #000;
    display: none;
    background-color: transparent;
}
.activeStyle{
    display: block;
}
.bgcStyle{
    background-color: #339966;
}
</style>