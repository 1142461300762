<template>
  <div id="MBPm">
      <wb-mb-tab-bar>
        <div slot="left" @click="backClick">返回</div>
        <div slot="center">个人中心</div>
        <div slot="right" @click="manageClick">{{currentText}}</div>
      </wb-mb-tab-bar>
      <div class="messageInput">
          <div>
            <span>用户账号：</span>
            <input type="text"  :disabled='flag' v-model="newMessage.MBname" > 
          </div>
          <div>
            <span>电话：</span>
            <input type="text" :disabled='flag' v-model="newMessage.MBphone">
          </div>
          <div>
            <span>邮箱：</span>
            <input type="text" :disabled='flag' v-model="newMessage.MBemail">
          </div>
          <div>
            <span>年龄：</span>
            <input type="nuber" :disabled='flag' v-model="newMessage.MBage">
          </div>
      </div>
  </div>
</template>

<script>
import wbMbTabBar from '@/components/WB-Mb-Tabbar/wb-mb-tabBar.vue'
export default {
  name:'MBPm',
  components: { wbMbTabBar },
  data(){
    return {
      currentText:'修改',
      flag:true,
      newMessage:{
        MBname:'',
        MBphone:'',
        MBemail:'',
        MBage:0,
        role:3
      }
    }
  },
  props:{
      newNumber:{
          type:Object,
          default(){
              return {}
          }
      }
  },
  beforeMount(){
    //获取个人信息
    this.$http({
        url: '/api/v1/user/single/info',
        method: 'GET'
      }).then( res => {
        if(res.data.code === 200){
          this.newMessage.MBname = res.data.data.nickname
          this.newMessage.MBphone = res.data.data.phone
          this.newMessage.MBemail = res.data.data.mail
          this.newMessage.MBage = res.data.data.age
          switch (this.newMessage.role) {
            case 0:
              this.role = '超级管理员'
              break
            case 1:
              this.role = '农业局账户'
              break
            case 2:
              this.role = '商家账户'
              break
            case 3:
              this.role = '消费者账户'
              break
            default:
              this.role = '未知角色'
          }
        }else{
          this.$toast.show(res.data.msg)
        }
      }).catch(() => {
        this.$toast.show('网络错误')
      })
    },
  methods:{
    manageClick(){
     this.flag=!this.flag;
     if(this.flag){
       this.currentText='修改'
     }else{
       this.currentText='确认'
     }
     if(this.currentText==='修改'){
       //提交修改参数
         this.$http({
          url: '/api/v1/user/single/info',
          method: 'PUT',
          data:{
            "nickname": this.newMessage.MBname,
            "phone": this.newMessage.MBphone,
            "mail": this.newMessage.MBemail,
            "age": Number(this.newMessage.MBage),
          }
        }).then( res => {
          if(res.data.code === 200){
            this.$toast.show(res.data.msg)
          }else{
            this.$toast.show(res.data.msg)
          }
        }).catch(() => {
          this.$message.error('网络错误')
        })
     }
    },
    backClick(){
        this.$router.go(-1)
    }
  }
}
</script>

<style>
.messageInput{
  width: 100%;
  margin-top: 20rem;
}
.messageInput>div{
  width: 100%;
  display: flex;
  margin-bottom: 8rem;
}
.messageInput>div>span{
  display: block;
  font-size: 7.2rem;
  line-height: 20rem;
  padding-left: 0.8rem;
}
.messageInput>div>input{
  flex: 1;
  outline: none;
  padding-left: 4rem;
  font-size: 7.2rem;
}
</style>