import { render, staticRenderFns } from "./MBLogin.vue?vue&type=template&id=0dbd8651&scoped=true&"
import script from "./MBLogin.vue?vue&type=script&lang=js&"
export * from "./MBLogin.vue?vue&type=script&lang=js&"
import style0 from "./MBLogin.vue?vue&type=style&index=0&id=0dbd8651&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dbd8651",
  null
  
)

export default component.exports