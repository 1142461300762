<template>
  <div class="AddView">
    <!-- 边框 -->
    <div class="BorderTop"></div>
    <div class="Border LeftBottom"></div>
    <div class="Border RightBottom"></div>

    <div class="AddViewLeft">
      <div class="AddViewLeftTop">
        <add-view-check>
          <h1 style="margin-bottom: 1rem">选择</h1>
        </add-view-check>
      </div>
      <div class="AddViewLeftBottom">
        <add-view-write ref="AddViewLeftBottom">
          <h1 style="margin-bottom: 1rem; " >填写信息</h1>
          <span class="tips">*注意：列表顺序会影响展示的结果（如：企业信息新增一项后，增加的那一项会在企业信息栏目的最后位置）</span>
        </add-view-write>
      </div>
      <el-button class="preview" @click="toPreview">预览</el-button>
    </div>
    <div class="AddViewRight">
      <add-view-see>
        <h1 style="margin-bottom: 1rem">预览</h1>
      </add-view-see>
    </div>
  </div>
</template>

<script>
import AddViewSee from './modules/pc-registered-addview-see'
import AddViewCheck from './modules/pc-registered-addview-check'
import AddViewWrite from './modules/pc-registered-addview-write'

export default {
  components: {
    AddViewSee,
    AddViewCheck,
    AddViewWrite
  },
  beforeMount () {
    this.$parent.isAdd = true
  },
  destroyed () {
    this.$parent.isAdd = false
  },
  methods: {
    toPreview () {
      this.$refs.AddViewLeftBottom.toPreview();
    }
  }
}
</script>


<style scoped>
.AddView {
  position: relative;
  width: 100%;
  height: 100%;
}
.Border {
  width: 15px;
  height: 20px;
}
.LeftBottom {
  position: absolute;
  left: 0;
  bottom: 0;
  border-left: 1px solid #555;
  border-bottom: 1px solid #555;
}
.RightBottom {
  position: absolute;
  right: 0;
  bottom: 0;
  border-right: 1px solid #555;
  border-bottom: 1px solid #555;
}
.BorderTop {
  width: 100%;
  height: 20px;
  position: absolute;
  top: 0;
  border: 1px solid #555;
  border-bottom: 0px;
}
.AddViewLeft {
  width: 50%;
  height: 100%;
  position: relative;
  float: left;
  padding: 4rem 6rem 10rem 6rem;
  border-right: 1px dashed #AAA;
}
.AddViewRight {
  width: 50%;
  height: 100%;
  float: left;
  padding-left: 6rem;
  padding-top: 4rem;
}
.AddViewLeftTop {
  width: 100%;
  height: 25%;
}
.AddViewLeftBottom{
  position: relative;
  width: 100%;
  height: 68%;
}
.preview {
  position: absolute;
  right: 6rem;
  bottom: 4rem;
  background-color: #009966;
  color: white;
  border-radius: 10px;
  font-size: 1.5rem;
}
.preview:hover {
  background-color: #e6e9ee;
  color: #555;
  border-color: #e6e9ee;
}
.tips {
  position: absolute; 
  left: 5vw; 
  top: 0.1vh;
  color: #aaa;
}
</style>