<template>
  <div id="MBIndex">
    <!-- 背景图片 -->
    <img src="../../../assect/bg-image/mb-login.png" class="background">
    <!-- 农产品标志 -->
    <div class="logoTest">
      <div class="home-text">
        <div>农产品</div>
        <div>质量追溯</div>
        <div>管理平台</div>
      </div>
    </div>
    <!-- 搜索框 -->
    <div class="homeInput">
      <div class="search-input">
        <div class="homeImg"><img src="../../../assect/mb-icon/shouye2.png" alt=""></div>  
        <input type="text"  placeholder="请输入标识" v-model="result">
        <div class="homeButton"><button @click="btnClick">确 定</button></div>
      </div>
      <!-- 扫描图标 -->
      <div class="homeSweep">
        <button @click="ssClick"></button>
      </div>
    </div>
  </div>
</template>

<script>
//导入mb vuex
import {mapGetters} from 'vuex';

export default {
  name: 'MBIndex',
  data(){
    return {
      result:''
    }
  },
  computed:{
    ...mapGetters(["mb_scanningResult"])
  },
  mounted(){
    this.result=this.mb_scanningResult
  },
  methods:{
    btnClick(){
      let aa='https://ncp.wlwb.cc/result?'
      if(this.result.indexOf(aa) == -1){
        this.$router.push({path:'/mb/result',query: {id_code:this.result}})
      }else{
        this.result=this.result.split(0,aa.length)
        this.$router.push({path:'/mb/result',query: {id_code:this.result}})
      }
      this.$store.commit('changRes',this.result)
    },
    ssClick(){
      this.$router.push({path:'/mb/saosao',query: {iid:false}})
    }
  }
}
</script>

<style scoped>
#MBIndex{
  position: relative;
  display: flex;
  flex-direction:column;
  overflow: hidden;
  width: 100vw;
  height: calc(100vh - 24rem);
}
.background {
  display: block;
  width: 100vw;
  height: calc(100vh - 24rem);
  object-fit: cover;
  position: absolute;
}
.logoTest{
    flex: 1;
    display: flex;
    flex-direction:column;
    align-items: center;
    width: 50%;
    margin-left: 50%;
    margin-top: 20%;
    transform: translate(-50%);
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: #fff;
}
.homeInput{
  flex: 2;
  position: relative;
  width: 80%;
  margin-left: 50%;
  transform: translate(-50%);
  z-index: 9;
}
.search-input{
  display: flex;
  height: 15%;
  background-color: rgba(255, 255, 255, .3);
  border: 1px solid #fff;
  border-radius: 20px;
}
.search-input input{
  flex: 1;
  height: 100%;
  border-radius: 8rem;
  outline: none;
  padding-left: 5px;
  background-color: transparent;
  font-size: 8rem;
}
.search-input input::-webkit-input-placeholder{
  color: #fff;
}
.homeImg{
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.homeImg img {
  width: 20px;
  align-items: center;
  margin-left: 5px;
}
.homeButton{
  width: 20%;
  position: relative;
  flex-direction: row;
  background-color: rgba(255, 255, 255, .8);
  border: 1px solid transparent;
  border-radius: 8rem;
}
.homeButton button{
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 8rem;
  background-color: transparent;
  color: #000;
  outline: none;
}
.homeSweep{
  height: 80%;
}
.homeSweep button{
  width: 52.8rem;
  height: 51.2rem;
  position: relative;
  left: 50%;
  top: 5%;
  transform: translate(-50%);
  background: url(../../../assect/mb-icon/shouye1.png) no-repeat ;
  background-size: 100% 100%;
  outline: none;
}
</style>