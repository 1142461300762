<template>
  <!-- 数据展示表格 -->
  <div style="height: 100%">
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      height="calc(100% - 28px - 1rem)"
      style="width: 100%"
      @row-click="checkOne">
      <el-table-column
        type="selection">
      </el-table-column>
      <el-table-column
        prop="id"
        label="消息ID">
      </el-table-column>
      <el-table-column
        prop="group_id"
        label="消息分组ID">
      </el-table-column>
      <el-table-column
        prop="receive_user_id"
        label="消息接收人ID">
      </el-table-column>
      <el-table-column
        prop="title"
        label="消息标题">
      </el-table-column>
      <el-table-column
        label="消息发送时间">
        <template slot-scope="scope">{{ moment(scope.row.time).format('YYYY年MM月DD日 HH:mm') }}</template>
      </el-table-column>
      <el-table-column
        label="消息状态"
        width="200px">
        <template slot-scope="scope">
            <el-tag
            v-if="scope.row.status === 0"
            type="success"
            disable-transitions>未读</el-tag>
            <el-tag
            v-if="scope.row.status === 1"
            type="primary"
            disable-transitions>已读</el-tag>
            <el-tag
            v-if="scope.row.status === 2"
            type="warning"
            disable-transitions>收藏</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="number"
      :total="total"
      :current-page="page"
      style="text-align: center; margin-top: 4rem;"
      @current-change="queryRegistered">
    </el-pagination>
    
    <complaint-dialog :dialogVisible="dialogVisible" :id="id" ref="complaintDialog" v-if="detailsData != {}">
      <span slot="title">{{detailsData.title}}</span>
      <span slot="send_time">{{detailsData.time}}</span>
      <span slot="content">{{detailsData.message}}</span>
    </complaint-dialog>
  </div>
</template>

<script>
import moment from "moment"
import ComplaintDialog from './pc-complaint-table-dialog'

export default {
  components: {
    ComplaintDialog
  },
  data() {
    return {
      tableData: [],
      page: 1,
      number: 10,
      total: 0,
      moment: moment,
      dialogVisible: false,
      detailsData: {},
      id: ''
    }
  },
  beforeMount() {
    this.queryRegistered(1)
  },
  methods: {

    /**
     * 查询已注册信息
     */
    queryRegistered(page) {
      this.$http({
        url: '/api/v1/notice/my/sended',
        method: 'GET',
        params: {
          page: page,
          number: this.number
        }
      }).then(res => {
        if(res.data.code === 200){
          this.tableData =res.data.data.data
          this.total = res.data.data.total
          this.page = res.data.data.page
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误');
      })
    },

    /**
     * 删除选择项
     */
    delSelect () {
      let isSelect = document.getElementsByClassName('el-checkbox__original')
      let deleteList = []
      for(let index = 1; index < isSelect.length; index++){
        if(isSelect[index].checked == true){
          deleteList.push(this.tableData[index-1].id)
        }
      }
      this.$http({
        url: '/api/v1/notice/delete',
        method: 'DELETE',
        data:{
          id_list: deleteList
        }
      }).then(res => {
        if(res.data.code === 200){
          this.$message.success(res.data.msg)
          this.queryRegistered(this.page)
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error("网络错误")
      })
    },

    /**
     * 查看单项详情
     */
    checkOne(val) {
      this.id = val.id
      this.$http({
        url: '/api/v1/notice/detail',
        method: 'GET',
        params:{
          id: val.id
        }
      }).then(res => {
        if(res.data.code === 200){
          this.detailsData = res.data.data
          this.dialogVisible = true
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误')
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.el-table{
  /deep/.cell {
    text-align: center;
  }
  /deep/.el-table__header-wrapper{
    .cell {
      font-size: 2rem;
      color: black;
    }
  }
}
</style>