<template>
  <div class="MBHome">
    <!-- 调试 -->
      <router-view class="router-view"/>
      <!-- 导航栏 -->
      <wb-mobile-main-navbar/>
  </div>
</template>

<script>
import WbMobileMainNavbar from '@/components/Wb-Mb-Navbar/wb-mobile-main-navbar.vue'
export default {
  name:'MBHome',
  components: {
    WbMobileMainNavbar
  },
  created () {
    let token = sessionStorage.getItem("token");
    if(token != null){
      this.$http.defaults.headers.common['Authorization'] = token;
    }
  }
}
</script>

<style scoped>
.MBHome{
  height:100vh;
  width: 100%;
   overflow-x: hidden;
  overflow-y: hidden;
}
.router-view {
  height:calc(100vh - 24rem);
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
}
</style>