<template>
  <!--审核未通过--首页视图 -->
  <div id="FailMain">
    <wb-header></wb-header>
    
    <!-- 提示语标题 -->
    <ttitle>
      <span>欢迎用户</span>
      <span style="text-decoration: underline">{{username}}</span>
      <span>,您的</span>
      <span style="color:red">{{msg}}</span>
      <span v-if="isMsgShow === 0">，请通过下列方式提交</span>
      <span v-if="isMsgShow === 1">，请耐心等待</span>
      <span v-if="isMsgShow === 2">，请通过下列方式重新提交</span>
    </ttitle>
    
    <!-- 功能按钮 -->
    <left><span>已注册工业互联网二级节点<br>审核入口</span></left>
    <right><span>未注册工业互联网二级节点<br>审核入口</span></right>
  </div>
</template>

<script>
import Header from '@/components/Wb-Pc-Header/wb-pc-header-title'
import Title from './modules/pc-title'
import EntranceLeft from './modules/pc-entranceLeft'
import EntranceRight from './modules/pc-entranceRight'

export default {
  name: 'FailMain',
  components: {
    wbHeader: Header,
    ttitle: Title,
    left: EntranceLeft,
    right: EntranceRight
  },
  data () {
    return{
      username:'未登录',
      msg: '',
      isMsgShow: 0
    }
  },
  beforeMount () {
    // 获取用户昵称
    this.$http({
      url: '/api/v1/user/single/info',
      method: 'GET'
    }).then(res => {
      if(res.data.code === 200){
        this.username = res.data.data.nickname
      }
    }).catch(() => {
      this.$message.error('未知错误');
    })
    //查看企业审核状态
    this.$http({
      url: '/api/v1/business/status',
      method: 'GET'
    }).then(res => {
      if(res.data.code === 200){
        this.$router.push('/pc/bu/suc')
      }else if(res.data.code === 404){
        this.msg = res.data.msg
        this.isMsgShow = 0
      }else if(res.data.code === 201){
        if(res.data.data === 0){
          this.msg = res.data.msg
          this.isMsgShow = 1
        }else{
          this.msg = res.data.msg
          this.isMsgShow = 2
        }
      }
    }).catch(() => {
          this.$message.error('网络错误');
      })
  }
}
</script>

<style scoped>
#FailMain {
  width: 100%;
  margin: 0 auto;
}
</style>