<template>
<!-- 地图组件 -->
  <div id="address">
    <amap
    cache-key="map"
    ref="map"
    view-mode="3D"
    async
    :zoom.sync="zoom"
    :center.sync="center"
    :pitch.sync="pitch"
    :rotation.sync="rotation"
    :show-indoor-map="false"
    is-hotspot
    @hotspotclick="onHotspotClick">
      <amap-marker-cluster
        :data="data"
        :marker-options="getMarkerOptions"
        :cluster-options="getClusterOptions"
        title="aaa"
      />
    </amap>
  </div>
</template>

<script>
import Amap from '@amap/amap-vue/lib/amap';
import AmapMarkerCluster from '@amap/amap-vue/lib/marker-cluster';

export default {
  components: {
    Amap,
    AmapMarkerCluster,
  },
  data() {
    return {
      data: [],
      center: [109, 30],
      zoom: 5.1,
      pitch: 0,
      rotation: 0,
    };
  },
  methods: {
    onHotspotClick(e) {
      if (e && e.lnglat) {
        this.center = [e.lnglat.lng, e.lnglat.lat];
      }
    },
    getMarkerOptions() {
      return {
        // position: point.lnglat,
        offset: [-15, -15],
        // content: '<div class="custom-marker" />',
      };
    },
    getClusterOptions(context) {
      const size = Math.round(
        30 + Math.pow(context.count / this.data.length, 1 / 5) * 20
      );
      return {
        offset: [-size / 2, -size / 2],
      };
    },

    /**
     * 获取具体经纬度数据
     */
    queryAddress(products_type_id,batch,id_code){
      this.$http({
        url: this.Configs.adminUrl1 + '/api/v1/products/get/products/address',
        methods: 'GET',
        params:{
          products_type_id: products_type_id,
          batch: batch,
          id_code: id_code
        }
      }).then(res => {
        if(res.data.code === 200){
          this.data = []
          res.data.data?.forEach(element => {
            this.data.push({ "lnglat": [element.x,element.y], "weight": 1 })
          })
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误')
      })
    },

    /**
     * 清除地图坐标数据
     */
    clearAddress() {
      this.data = []
    }
  }
};
</script>

<style scoped>
#address{
    width: 100%;
    height: 100%;
}
</style>