<template>
  <!-- 图片展示 -->
  <div class="photoMain">
    <div class="oneF" v-for="(item,index) in listData" :key="item.name" @click="checkOne(index)">
      <img :src="httpUrl + '/' + item.name" class="imageF">
      <span id="img-name">{{item.name}}</span>
      <!-- 选中效果 -->
      <div v-if="isCheck[index]">
        <div class="fill"></div>
        <i class="el-icon-check"></i>
      </div>
    </div>
    <div class="oneFU" @click="$refs.uploadimg1.click()">
      <i class="el-icon-plus"></i>
      <input type="file" ref="uploadimg1" name="image1" style="display:none;" @change="updateChange($event)">
    </div>
    <div class="oneFS"></div>
    <div class="oneFS"></div>
    <div class="oneFS"></div>
    <div class="oneFS"></div>
    <div class="oneFS"></div>
    <div class="oneFS"></div>
    <div class="oneFS"></div>
    <div class="oneFS"></div>
    <div class="oneFS"></div>
    <div class="oneFS"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      listData:[],
      httpUrl: '',
      isCheck: []
    }
  },
  beforeMount () {
    this.queryImg()
  },
  methods: {
    /**
     * 查询图片列表
     */
    async queryImg () {
      this.$http({
        url: '/api/v1/cos/query/file/list',
        method: 'GET'
      }).then(res => {
        if(res.data.code === 200){
          this.httpUrl = res.data.data.url_prefix
          this.listData = res.data.data.data
          if(this.listData){
            this.listData.forEach((item,index) => {
              this.isCheck[index] =false
            })
          }
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
          this.$message.error('网络错误');
      })
    },

    /**
     * 上传图片
     */
    updateChange (file) {
      this.isImg1 = true
      let formData = new FormData()
      formData.append("image", file.target.files[0])
      
      this.$http({
        url: '/api/v1/cos/upload/bucket/image',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      }).then(res => {
        if(res.data.code == 200){
          this.$message.success(res.data.msg);
          this.queryImg()
          this.$parent.$children[0].queryImg()
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
        this.$message.error('网络错误');
      })
    },

    /**
     * 选中一张图片
     */
    checkOne (index) {
      this.$set(this.isCheck, index, !this.isCheck[index])
    },

    /**
     * 删除单项
     */
    deleteOne (name) {
      this.$http({
        url: '/api/v1/cos/delete/file',
        method: 'DELETE',
        data:{
          name: name
        }
      }).then(res => {
        if(res.data.code === 200){
          this.$message.success(res.data.msg)
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(() => {
        this.$message.error('网络错误')
      })
    },

    /**
     * 删除选中
     */
    async toDelete () {
      if(!this.listData){
        return 0;
      }
      this.listData.forEach((item,index) => {
        if(this.isCheck[index] == true){
          this.deleteOne(item.name)
        }
      })
     await location.reload();
    },

    toUpdate () {
      this.$refs.uploadimg1.click()
    }
  }
}
</script>

<style scoped>
.photoMain {
  padding: 5px;
  width: 100%;
  height: calc(100% - 50px);
  overflow: scroll;
  display: flex;
  align-content: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.oneF {
  width: 220px;
  height: 182px;
  text-align: center;
  margin: 5px;
  border: 1px dashed #DCDFE6;
  border-radius:10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.oneFS {
  width: 220px;
  height: 0;
  margin: 5px;
}
.imageF {
  display: block;
  height: 150px;
  width: 100%;
  border-radius:10px;
  object-fit: contain;
}
#img-name {
  width: 90%;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 30px;
  font-size: 10px;
  line-height: 30px;
  display: block;
  margin: 0 auto;
}
.oneFU {
  width: 220px;
  height: 182px;
  margin: 5px;
  border: 1px dashed #DCDFE6;
  border-radius:10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.oneF .fill{
  border-width: 0 20px 20px;
  border-style: solid;
  border-color: transparent transparent #13ce66;
  position: absolute;
  right: -13px;
  top: -3px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.oneF i {
  position: absolute;
  color: white;
  right: 3px;
  top: 3px;
}
</style>
