<template>
  <div  id="settledMerchants">
    <div class="hzFriend" v-for="(item,index) in dataFriend " :key="index">
      <el-button slot="reference">
        <img  :src="item.src" alt="" class="hzFriendLogo">
      </el-button>
      <div class="gongsi-name">
        <span style="font-weight: bold;">公司名称：</span>
        <span>{{item.bus_name}}</span>
      </div>
      <div>
        <span>国家工业互联网赋予唯一前缀：</span>
        <span>{{item.bus_prefix}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'settledMerchants',
  data(){
    return {
      dataFriend: [],
      imgFriends:[
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/1.png')},
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/2.png')},
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/3.png')},
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/4.png')},
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/5.png')},
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/6.png')},
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/7.png')},
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/8.png')},
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/9.png')},
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/10.png')},
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/11.png')},
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/12.png')},
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/13.png')},
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/14.png')},
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/15.png')},
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/16.png')},
      {imgsrc:require('../../../../assect/enterHomeImg/shangjia/17.png')},
      ]
    }
  },
  beforeMount(){
    this.$http({
      url: this.Configs.adminUrl2 + '/api/v2/show/our/friends',
      method: 'GET',
    }).then( res =>{
      if(res.data.code === 200){
        this.dataFriend = res.data.data
        this.dataFriend=this.dataFriend.splice(0,this.dataFriend.length-1)
        for(let i in this.dataFriend){
          let items = this.dataFriend[i]
          items.src = this.imgFriends[i]?.imgsrc
        }
      }
    })
  },
}
</script>

<style scoped>
#settledMerchants{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  flex-wrap: wrap;
}
.hzFriend{
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  width: 18%;
  white-space: nowrap;
}
.el-button{
  width: 100%;
  padding: 0;
}
.hzFriendLogo{
  width: 100%;
  vertical-align: middle;
}
.hzFriend > div{
  width: 100%;
  text-align: left;
  box-sizing: border-box;
  margin-top: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.hzFriend > div > span{
  text-align: left;
  margin-top: 5px;
}
.hzFriend:hover > div{
  overflow: visible;
  text-overflow: inherit;
}
</style>