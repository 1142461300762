<template>
  <div class="content">

      <!-- 头部标题 -->
      <div class="header">
        <wb-pc-header-title/>
      </div>
      <!-- 左部菜单栏 -->
      <div class="le-menu">
       <pc-left-menu/>
      </div>
      <!-- adminHome的切换内容 -->
      <div class="rg-content">
        <!-- 面包屑 -->
        <div class="mian-bao-xie">
          <wb-pc-mian-bao-xie @click.native="btnClick"/>
        </div>
        <!-- adminHome子路由 -->
        <div class="admin-children-Router">
          <transition>
            <router-view/>
          </transition>
        </div>
      </div>
  </div>
</template>

<script>
// 引入页面头文件
import WbPcHeaderTitle from '../../../components/Wb-Pc-Header/wb-pc-header-title';
// 引入ad模块的左部菜单栏
import PcLeftMenu from './modules/pc-left-menu';
// vuex辅助函数

import WbPcMianBaoXie from '../../../components/Wb-Pc-Rg-Content/wb-pc-mian-bao-xie.vue';
//导入vueX
import {mapGetters} from 'vuex';
export default {
  data(){
    return{

    }
  },
  computed:{
    ...mapGetters(["wb_mianBaoXieArray"])
  },
    components:{
        WbPcHeaderTitle,
        PcLeftMenu,
        WbPcMianBaoXie
    },
     methods: {
       /**
        * 面包屑实现回退
        */
       btnClick(){
        let fullPath = this.wb_mianBaoXieArray[0]
        switch (fullPath) {
          case '通知中心':
            this.$router.push('/pc/adminhome/sendnotice/sentnotice')
            break;
          case '用户管理':
            this.$router.push('/pc/adminhome/shangjiaguanli/ziliaoshengcha')
            break;
          case '防伪管理':
            this.$router.push('/pc/adminhome/daishenhe')
            break;
          default:
            this.$router.push('/pc/adminhome/sendnotice/sentnotice')
            break;
        }
      }
    },
    beforeMount(){
      
    }
}
</script>

<style lang='scss' scoped>
.content{
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: absolute;
  overflow: hidden;
  .le-menu{
    width: 12.1vw;
    height: 92.5vh;
    background-color: #009966;
  }
  .rg-content{
    width: 87.9vw;
    height: 92.5vh;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #f2f2f2;
    .mian-bao-xie{
      width: 100%;
      padding: 1.8rem 0;
      border-bottom: 1px solid slategrey;
      padding-left: 1.15rem;
      background-color: white;
    }
    .admin-children-Router{
      margin: 5rem 8.5rem 0rem 8.5rem;
      height: 75vh;
      background-color: white;
      overflow: hidden;
    }
  }
}



</style>