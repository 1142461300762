<!--
 * @Description: 
 * @Version: 1.0
 * @Author: AndBridge
 * @LastEditors: AndBridge
-->
<template>
  <div id="homePartner">
    <div class="partner-title">网标的伙伴们</div>
    <div class="my-partner" v-for="(item,index) in parnterList" :key="index">
      <el-button slot="reference">
        <img  :src="item.imgsrc" alt="" class="parnterLogo">
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name:'homePartner',
  data(){
    return {
      parnterList:[
        {imgsrc:require('../../../../assect/enterHomeImg/parnter/parnter1.png')},
        {imgsrc:require('../../../../assect/enterHomeImg/parnter/parnter2.png')},
        {imgsrc:require('../../../../assect/enterHomeImg/parnter/parnter3.png')},
        {imgsrc:require('../../../../assect/enterHomeImg/parnter/parnter4.png')},
        {imgsrc:require('../../../../assect/enterHomeImg/parnter/parnter5.png')},
      ]
    }
  }
}
</script>

<style scoped>
#homePartner{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 10rem;
}
.partner-title{
  width: 100%;
  font-weight: bold;
  font-size: 3rem;
  text-align: center;
}
.my-partner{
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
.el-button{
  margin-top: 5rem;
  width: 25rem;
  padding: 0;
  border: 0;
}
.parnterLogo{
  width: 100%;
  vertical-align: middle;
}
</style>