<template>
  <!-- 已读 -->
  <div style="height:100%">
    <div class="leftArea">
      <div class="leftAreaTop">
        <span>列表</span>
      </div>
      <div class="leftAreaMedium">
        <left-table ref="cTable" @getId="getId" statuss="1"></left-table>
      </div>
      <div class="leftAreaBottom">
        <el-button @click="allSelect">全选</el-button>
        <el-button @click="toCollection">收藏</el-button>
        <el-button @click="delSelect">删除</el-button>
      </div>
    </div>
    <rightContent v-if="data">
      <span slot="comeFrom">{{data.send_user_id}}</span>
      <span slot="time">{{data.time}}</span>
      <span slot="content">{{data.message}}</span>
    </rightContent>
  </div>
</template>

<script>
import RightContent from './pc-notification-content'
import LeftTable from './pc-notification-table'

export default {
  components: {
    RightContent,
    LeftTable
  },
  data() {
    return {
      data: null
    }
  },
  methods: {
    /**
     * 得到表格选中数据
     */
    getId (mesage) {
      this.$http({
        url: '/api/v1/notice/detail',
        method: 'GET',
        params: {
          id: mesage.id
        }
      }).then(res => {
        if(res.data.code ===200){
          this.data = res.data.data
        }
      }).catch(() => {
          this.$message.error('网络错误');
      })
    },

    /**
     * 全选
     */
    allSelect () {
      this.$refs.cTable.allSelect();
    },

    /**
     * 删除选中
     */
    delSelect () {
      this.$refs.cTable.delSelect();
    },

    /**
     * 收藏选中
     */
    toCollection () {
      this.$refs.cTable.toCollection();
    }
  }
}
</script>


<style scoped>
.leftArea {
  height: 100%;
  width: 35%;
  float: left;
  border-right: 2px solid #555;
  position: relative;
}
.leftAreaTop {
  height: 5rem;
  margin-left: 1rem;
  line-height: 5rem;
  font-size: 2.5rem;
}
.leftAreaMedium {
  height: calc(100% - 13rem);
  border-top: 1px solid #555;
  border-bottom: 1px solid #555;
}
.leftAreaBottom {
  position: absolute;
  bottom: 0;
  height: 8rem;
  width: 100%;
  line-height: 8rem;
  text-align: right;
}
.leftAreaBottom button {
  position: absolute;
  bottom: 2rem;
  right: 12rem;
  border: 1px solid #009966;
  color: #009966;
  margin: 0;
}
.leftAreaBottom button:hover {
  background-color: #e6e9ee;
}
.leftAreaBottom button:first-child {
  left: 2rem;
}
.leftAreaBottom button:last-child {
  right: 2rem;
}
</style>