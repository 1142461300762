<template>
  <!--Header视图 -->
  <div id="Header">
    <div class="title"><span>农产品质量追溯管理平台</span></div>
    <div class="user">
      <el-dropdown>
        <span class="username">
          {{username}}<i class="el-icon-caret-bottom"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="toExit">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      </div>
  </div>
</template>

<script>

export default {
  name: 'Header',
  data () {
    return{
      username:'未登录'
    }
  },
  beforeMount () {
    // 获取用户昵称
    this.$http({
      url: '/api/v1/user/single/info',
      method: 'GET'
    }).then(res => {
      if(res.data.code === 200){
        this.username = res.data.data.nickname
      }
    }).catch(() => {
      this.$message.error('未知错误');
    })
  },
  methods:{
    /**
     * 退出登录
     */
    toExit () {
      this.$http({
        url: '/api/v1/user/signout',
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        if(res.data.code === 200){
          this.$message.success(res.data.msg);
          // 清空会话存储
          sessionStorage.clear();
          // 清空头部授权token
          this.$http.defaults.headers.common['Authorization'] = '';
          // 跳转到登录界面
          this.$router.push('/pc/loading')
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
          this.$message.error('网络错误');
      })
    }
  }
}
</script>

<style lang='scss' scoped>
#Header {
  margin: 0;
  width: 100%;
  height: 7.5vh;
  background-color: #009966;
  color: white;
  .title {
    font-size: 3rem;
    line-height: 7.5vh;
    margin-left: 2rem;
    display: inline;
    font-weight: 600;
  }
  .user {
    line-height: 7.5vh;
    margin-right: 3rem;
    display: inline;
    float: right;
    &:hover{
      cursor: pointer;
    }
    .username {
      color: white;
      font-size: 2rem;
    }
  }
}

</style>