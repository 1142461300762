<template>
  <div class="send-notice-box">
      <div class="left-choice">
        <ad-pc-three-choice :choiceArray="choiceArray">
          <template #three-choice-A-icon-title>
            <i class="iconfont iconkehuqunfa_01"></i>
          <br>
          <span class="title">消费者群发</span>
          </template>
          <template #three-choice-B-icon-title>
            <i class="iconfont iconshangjiaqunfa_01"></i>
          <br>
          <span class="title">商家群发</span>
          </template>
          <template #three-choice-C-icon-title>
            <i class="iconfont iconzhidingfasong_01"></i>
          <br>
          <span slot="title" class="title">指定对象</span>
          </template>
        </ad-pc-three-choice>
      </div>
      <!-- 右边对应内容 -->
      <div class="rg-sendMsg">
        <transition>
        <router-view/>
        </transition>
      </div>
  </div>
</template>

<script>
import AdPcThreeChoice from '../components/Ad-Pc-Rg-Content-Left/ad-pc-three-choice'

export default {
  components: {AdPcThreeChoice  },
  data(){
    return{
      choiceArray:[
        {
        url:"/pc/adminhome/sendnotice/xiaofeizhe",
        slotName:"three-choice-A-icon-title"
        },
        {url:"/pc/adminhome/sendnotice/shangjiaqunfa",
        slotName:"three-choice-B-icon-title"},
        {
        url:"/pc/adminhome/sendnotice/zhidingduixiang",
        slotName:"three-choice-C-icon-title"
        }]
    }
  }

}
</script>

<style scoped>
.send-notice-box{
    width: 100%;
    height: 100%;
    position: relative;
}
.left-choice{
  width: 13.2%;
  height: 100%;
  position: absolute;
  left: 0;
}

.iconfont{
  font-family: "iconfont" !important;
  font-size: 7vw;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #9999;
}
.title{
  font-size: 3rem;
  font-weight: 600;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 3.8vh;
}
.rg-sendMsg{
  width: 86.8%;
  height: 100%;
  z-index: 99;
  position: absolute;
  right: 0;
}

</style>