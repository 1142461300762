<template>
  <!-- 表格信息展示 -->
  <div class="noTable">
    <el-table
      v-if="tableData"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      height="100%"
      style="width: 100%"
      :show-header="false"
      @row-click="clickOne">
      <el-table-column
        type="selection"
        width="30">
      </el-table-column>
      <el-table-column
        label="姓名"
        prop="send_user_id"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="title"
        label="内容"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        v-show="false"
        prop="time"
        label="日期"
        show-overflow-tooltip>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="number"
      :total="total"
      :current-page="page"
      @current-change="queryTableData">
    </el-pagination>
  </div>
  
</template>

<script>
export default {
  props:['statuss'],
  data() {
    return {
      total: 0,
      number: 20,
      page: 1,
      tableData: []
    }
  },
  beforeMount() {
    this.queryTableData(1);
  },
  methods: {
    /**
     * 获取信息
     */
    queryTableData (page) {
      this.$http({
        url: '/api/v1/notice/my/receive',
        method: 'GET',
        params:{
          number: this.number,
          page: page,
          status: Number(this.statuss)
        }
      }).then(res => {
        if(res.data.code === 200){
          this.tableData = res.data.data.data;
          this.total = res.data.data.total;
        }
      }).catch(() => {
          this.$message.error('网络错误');
      })
    },
    /**
     * 点击查看详情
     */
    clickOne (val) {
      this.queryTableData(this.page)
      this.$emit('getId',{id: val.id})
    },

    /**
     * 全选
     */
    allSelect () {
      this.$refs.multipleTable.toggleAllSelection();
    },

    /**
     * 删除选择项
     */
    delSelect () {
      let isSelect = document.getElementsByClassName('el-checkbox__original')
      let id_list = []
      isSelect.forEach((element,index) => {
        if(element.checked == true){
          id_list.push(this.tableData[index].id);
        }
      });
      this.delete(id_list);
    },

    /**
     * 删除
     */
    delete (id_list) {
      this.$http({
        url: '/api/v1/notice/delete',
        method: 'DELETE',
        data:{
          id_list: id_list
        }
      }).then(res => {
        if(res.data.code === 200){
          this.$message.success(res.data.msg);
          this.queryTableData(this.page)
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
          this.$message.error('网络错误')
      })
    },

    /**
     * 收藏选中项
     */
    toCollection () {
      let isSelect = document.getElementsByClassName('el-checkbox__original')
      let id_list = []
      isSelect.forEach((element,index) => {
        if(element.checked == true){
          id_list.push(this.tableData[index].id);
        }
      });
      this.collection(id_list);
    },

    /**
     * 收藏
     */
    collection (id_list) {
      this.$http({
        url: '/api/v1/notice/favorites',
        method: 'POST',
        data:{
          id_list: id_list
        }
      }).then(res => {
        if(res.data.code === 200){
          this.$message.success(res.data.msg);
          this.queryTableData(this.page)
        }else{
          this.$message.error(res.data.msg);
        }
      }).catch(() => {
          this.$message.error('网络错误')
      })
    }
  }
}
</script>

<style scoped>
.noTable {
  height: calc(100% - 4rem);
  text-align: center;
}
</style>