<template>
  <!-- 未注册二级节点-审核入口 -->
  <div class="buttonRight" @click="toRegister">
      <div class="words">
        <slot></slot>
      </div>
    </div>
</template>

<script>
export default {
  methods: {
    /**
     * 跳转到注册页面
     */
    toRegister () {
      this.$router.push({path:'/pc/bu/failRegister'})
    }
  }
}
</script>


<style scoped>
.buttonRight {
  width: 25%;
  height: 30rem;
  position: absolute;
  right: 15%;
  top: calc(25% + 30rem);
  /* border:1px solid; */
  border-radius:10px;
  text-align: center;
  font-size: 3rem;
  display:table;
  color: white;
  font-weight: bold;
  background-image: linear-gradient(135deg, #ABDCFF 10%, #0396FF 100%);
}
.buttonRight:hover {
  cursor: pointer;
  animation: example 0.3s;
  font-size: 3.7rem;
}
/* 动画代码 */
@keyframes example {
  0% {
    font-size: 3rem;
  }
  100% {
    font-size: 3.7rem;
  }
  /* 0% {
    font-size: 3rem;
  } */
}
.buttonRight .words {
  display:table-cell; vertical-align:middle;
  line-height: 5rem;
}
</style>