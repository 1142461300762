<template>
  <div id="MBSuggest">
      <wb-mb-tab-bar>
        <div slot="left" @click="backClick">返回</div>
        <div slot="center">我的建议</div>
        <div slot="right">管理</div>
      </wb-mb-tab-bar>
  </div>
</template>

<script>
import wbMbTabBar from '@/components/WB-Mb-Tabbar/wb-mb-tabBar.vue'
export default {
  name:'MBSuggest',
  components: { wbMbTabBar },
  props:{
    number:{
      type:Number
    }
  },
  methods:{
    backClick(){
      this.$emit('newNumber')
    }
  }
}
</script>

<style scoped>
</style>