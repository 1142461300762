<template>
    <tab-bar>
      <tab-bar-item path='/mb/index' activeColor='green' class="homeIcon">
        <img slot="item-img" src="../../assect/mb-icon/home_01.png" alt="" >
        <img slot="item-img-active" src="../../assect/mb-icon/home_02.png" alt="">
        <div slot="item-text">首页</div>
      </tab-bar-item>
      <tab-bar-item path='/mb/logo' activeColor='green' class="logoIcon">
        <img slot="item-img" src="../../assect/mb-icon/biaozhi_01.png" alt="">
        <img slot="item-img-active" src="../../assect/mb-icon/biaozhi_02.png" alt="">
        <div slot="item-text">标识</div>
      </tab-bar-item>
      <tab-bar-item path='/mb/profit' activeColor='green'>
        <img slot="item-img" src="../../assect/mb-icon/me_01.png" alt="">
        <img slot="item-img-active" src="../../assect/mb-icon/me_02.png" alt="">
        <div slot="item-text">我的</div>
      </tab-bar-item>
    </tab-bar>
</template>

<script>
import TabBar from './wb-mobile-navbar'
import TabBarItem from './wb-mobile-navbar-item'

export default {
  name: 'MainTabBar',
  components:{
      TabBar,
      TabBarItem
  }
  
}
</script>

<style scoped>
.homeIcon,.logoIcon{
  border-right: .2rem solid #fff;
}
</style>